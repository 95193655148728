<app-top-nav title="Registros"></app-top-nav>

<div class="registros">
    <div class="registros-groups">
        <span
            class="registros-group"
            [class.active]="showingLogTypes['worker']"
            (click)="toggleLogShow('worker')"
            >Empleados</span
        >
        <span
            class="registros-group"
            [class.active]="showingLogTypes['user']"
            (click)="toggleLogShow('user')"
            >Usuarios</span
        >
        <span
            class="registros-group"
            [class.active]="showingLogTypes['system']"
            (click)="toggleLogShow('system')"
            >Sistema</span
        >
    </div>

    <div class="col-12" *ngIf="updatingList">
        <app-loader></app-loader>
    </div>

    <div class="col-12 registros-container pt-2" *ngIf="!updatingList">
        <div class="registro" *ngFor="let registro of itemList">
            <div class="registro-header">
                <span
                    class="registro-author"
                    *ngIf="registro.level === 'system'">
                    <strong><i class="fas fa-server"></i> Sistema</strong>
                </span>
                <span
                    class="registro-author"
                    *ngIf="registro.level !== 'system'">
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-2">
                            <img
                                [src]="registro.user.avatar_public_url"
                                alt="avatar" />
                        </div>
                        <span
                            >{{ registro.user.first_name }}
                            {{ registro.user.last_name }}</span
                        >
                    </div>
                </span>
                <span class="registro-date">{{
                    registro.time | localDate: 'd/M/yy, h:mm a'
                }}</span>
            </div>
            <div
                class="registro-body"
                [innerHTML]="getRegistroBody(registro)"></div>
        </div>
    </div>
</div>
