<app-top-nav title="Gestión"></app-top-nav>

<ul class="nav-tabs">
    <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/gestion/usuarios">Usuarios</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/gestion/empleados">Empleados</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/gestion/ajustes-generales"
            >Ajustes Generales</a
        >
    </li>
</ul>

<div class="tab-content">
    <div class="tab-pane" [class.active]="tab === 'usuarios'">
        <app-usuarios-manager></app-usuarios-manager>
    </div>
    <div class="tab-pane" [class.active]="tab === 'empleados'">
        <app-empleados-manager></app-empleados-manager>
    </div>
    <div class="tab-pane" [class.active]="tab === 'ajustes-generales'">
        <app-general-settings></app-general-settings>
    </div>
</div>
