<div class="custom-table table-embed-top">
    <div class="table-header">
        <div class="table-title">
            <h3>Empleados</h3>
        </div>
        <div class="right-options">
            <button
                class="btn btn-primary"
                (click)="openEmpleadoCreationModal()">
                Crear Empleado
            </button>
        </div>
    </div>
    <div class="table-body" [class.table-loading]="empleadoListLoading">
        <div class="table-row table-cols">
            <div class="cell">
                <span>Empleado</span>
            </div>
            <div class="cell">
                <span>Último login</span>
            </div>
            <div class="cell"></div>
        </div>
        <div class="table-row" *ngFor="let empleado of empleadoList" [class.inactive]="!empleado.is_active">
            <div class="cell">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-2">
                        <img [src]="empleado.avatar_public_url" alt="avatar" />
                        <button
                            class="btn-upload-avatar no-btn"
                            (click)="uploadAvatar(empleado)">
                            <i class="fas fa-upload"></i> Subir
                        </button>
                    </div>
                    <span>
                        {{ empleado.first_name }}&nbsp;{{ empleado.last_name }}
                    </span>
                    <span
                        class="status-deactivated"
                        *ngIf="!empleado.is_active">
                        &nbsp;Desactivado
                    </span>
                </div>
            </div>
            <div class="cell date-container">
                <span class="date">{{
                    empleado.last_login | localDate: 'd MMM, y'
                }}</span>
                <br />
                <span class="hour">{{
                    empleado.last_login | localDate: 'HH:mm'
                }}</span>
            </div>
            <div class="cell">
                <div class="dropdown dropleft">
                    <button
                        class="no-btn empleado-settings"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <div class="dropdown-menu p-0">
                        <button
                            class="dropdown-item"
                            (click)="openEmpleadoEditionModal(empleado)"
                            type="button">
                            Editar
                        </button>
                        <button
                            class="dropdown-item"
                            (click)="openPasswordChangingModal(empleado)"
                            type="button">
                            Cambiar contraseña
                        </button>
                        <button
                            *ngIf="empleado.is_active"
                            class="dropdown-item bg-danger"
                            (click)="deactivateEmployee(empleado)"
                            type="button">
                            Desactivar
                        </button>
                        <button
                            *ngIf="!empleado.is_active"
                            class="dropdown-item bg-success"
                            (click)="activateEmployee(empleado)"
                            type="button">
                            Activar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-empleado-creating-modal
    #empleadoCreationModal
    (onEmpleadoCreated)="updateEmpleadoList()"></app-empleado-creating-modal>
<app-empleado-editing-modal
    #empleadoEditionModal
    (onEmpleadoUpdated)="updateEmpleadoList()"></app-empleado-editing-modal>
<app-password-changing-modal
    #passwordChangingModal
    [maxPasswordLength]="4"></app-password-changing-modal>
