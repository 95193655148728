<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg-3">
        <label for="serch-by-id">ID Artículo:</label>
        <input
            type="text"
            class="form-control"
            placeholder="Buscar por ID..."
            [(ngModel)]="seachFilter"
            (input)="onFiltersChange()"
            (change)="onFiltersChange()" />
    </div>
    <div class="col-12 col-md-6 col-lg"></div>
    <div class="col-12 col-md-6 col-lg-auto d-flex align-items-end">
        <button
            class="btn btn-export mr-1"
            (click)="exportToXlsx()"
            [disabled]="fetchingTmedios">
            <i class="fa-solid fa-download"></i>
        </button>
        <button
            class="btn btn-refresh"
            [class.i-spin]="fetchingTmedios"
            (click)="getTEstTReqPorArtFam()"
            [disabled]="fetchingTmedios">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12" *ngIf="fetchingTmedios">
        <app-loader></app-loader>
    </div>
    <div class="col-12" *ngIf="!fetchingTmedios && tmedios_filtered">
        <div class="card mb-2" *ngIf="!tmedios_filtered.length">
            <div class="card-body">Sin articúlos para estos filtros</div>
        </div>
        <div *ngIf="tmedios_filtered.length">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                Comp. T. est. - T. req por artículo/familia
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div
                                class="custom-table table-no-border"
                                [class.table-refreshing]="fetchingTmedios">
                                <div
                                    class="table-body"
                                    [class.table-loading]="fetchingTmedios">
                                    <div class="table-row table-cols">
                                        <div class="cell">
                                            <span>ID Artículo</span>
                                        </div>
                                        <div class="cell">
                                            <span>Desc. Artículo</span>
                                        </div>
                                        <div class="cell">
                                            <span>T. Estimado</span>
                                        </div>
                                        <div class="cell">
                                            <span>T. Requerido</span>
                                        </div>
                                    </div>
                                    <div
                                        class="table-row"
                                        *ngFor="
                                            let articulo of tmedios_filtered
                                        ">
                                        <div class="cell">
                                            <p>
                                                <strong>{{
                                                    articulo.id_articulo
                                                }}</strong>
                                            </p>
                                        </div>
                                        <div class="cell">
                                            <p>
                                                {{ articulo.descrip_articulo }}
                                            </p>
                                        </div>
                                        <div class="cell">
                                            <span class="datum time">{{
                                                prettyTimeSring(articulo.t_est)
                                            }}</span>
                                        </div>
                                        <div class="cell">
                                            <span class="datum time">{{
                                                prettyTimeSring(articulo.t_req)
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
