<div
    class="modal"
    id="empleado-edition-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="userEditionModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <form
                [formGroup]="empleadoEditionForm"
                (ngSubmit)="onSubmit()"
                ngNativeValidate>
                <div class="modal-header">
                    <h2 class="modal-title">Editar Empleado</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col-12 col-md-6 mb-3">
                            <label for="first_name">Nombre</label>
                            <input
                                type="text"
                                class="form-control"
                                id="first_name"
                                formControlName="first_name"
                                maxlength="150"
                                placeholder="Nombre"
                                required />
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="last_name">Apellidos</label>
                            <input
                                type="text"
                                class="form-control"
                                id="last_name"
                                formControlName="last_name"
                                maxlength="150"
                                placeholder="Apellidos"
                                required />
                        </div>
                    </div>
                    <div
                        *ngIf="editionError"
                        class="alert alert-danger my-1"
                        role="alert">
                        {{ editionError }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" type="submit">
                        Actualizar Empleado
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
