import { Injectable } from '@angular/core';
import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class RegistrosService {
    constructor(private api: APIService) {}

    getRegistros(logTypes: string[]): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/registros/list/', { types: logTypes.join(',') })
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[RegistrosService]', ...args);
    }
}

export default RegistrosService;
