<div class="card mt-4">
    <div class="card-header">
        <div class="card-title">OFs Exsitentes</div>
        <div class="card-header-actions small-card-filters">
            <input
                type="text"
                class="form-control"
                placeholder="Buscar por ID, cliente..."
                [(ngModel)]="seachFilter"
                (input)="changeTrigger()"
                (change)="refreshList()" />
        </div>
    </div>
    <div class="card-body p-0">
        <div
            class="custom-table table-no-border"
            [class.table-refreshing]="updatingList">
            <div class="table-body" [class.table-loading]="updatingList">
                <div class="table-row table-cols">
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['linea_composed_id'] === -1
                            "
                            [class.sorting_asc]="
                                sortings['linea_composed_id'] === 1
                            "
                            (click)="sortBy('linea_composed_id')"
                            >ID Línea</span
                        >
                    </div>
                    <div class="cell">
                        <span>Estaado Fases</span>
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['client__name'] === -1
                            "
                            [class.sorting_asc]="sortings['client__name'] === 1"
                            (click)="sortBy('client__name')"
                            >Cliente</span
                        >
                    </div>
                    <div class="cell">
                        <span>Correo Cliente</span>
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="sortings['plazo'] === -1"
                            [class.sorting_asc]="sortings['plazo'] === 1"
                            (click)="sortBy('plazo')"
                            >Plazo</span
                        >
                    </div>
                    <div class="cell"></div>
                </div>
                <div class="table-row" *ngFor="let _of of itemList">
                    <div class="cell">
                        <strong>{{ _of.linea_composed_id }}</strong>
                    </div>
                    <div class="cell">
                        <app-phase-container
                            [phaseList]="_of.phases"></app-phase-container>
                    </div>
                    <div class="cell">
                        <strong>{{ _of.client.name }}</strong>
                    </div>
                    <div class="cell">
                        <strong>{{ _of.email_cliente }}</strong>
                    </div>
                    <div class="cell date-container">
                        <span class="date">{{
                            _of.plazo | localDate: 'd MMM, y'
                        }}</span>
                        <!--<br>
                        <span class="hour">{{_of.plazo | localDate:'HH:mm'}}</span>-->
                    </div>
                    <div class="cell">
                        <a
                            [routerLink]="
                                '/ordenes-fabricacion/' + _of.uid + '/'
                            "
                            class="btn btn-small btn-primary"
                            title="Ver detalle">
                            <i class="fa-solid fa-eye"></i>
                        </a>
                        <button
                            (click)="startSeguimientoModal(_of.uid)"
                            class="btn btn-small btn-success ml-1"
                            title="Iniciar Seguimiento">
                            <span>Iniciar Seguimiento&nbsp;</span>
                            <i class="fas fa-share"></i>
                        </button>
                    </div>
                    <div class="cell-full-wide">
                        <p>
                            <strong>{{ _of.id_articulo }}</strong> |
                            {{ _of.descrip_articulo }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer clearfix">
        <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item">
                <a
                    class="page-link"
                    href="#"
                    (click)="setPage($event, activePage - 1)"
                    [class.disabled]="activePage === 1"
                    >«</a
                >
            </li>

            <li
                class="page-item"
                *ngFor="let page of displayedPages"
                [class.active]="page == activePage"
                [class.disabled]="page === -1">
                <a
                    class="page-link"
                    href="#"
                    (click)="
                        page > 0
                            ? setPage($event, page)
                            : $event.preventDefault()
                    ">
                    {{ page > 0 ? page : '...' }}
                </a>
            </li>

            <li class="page-item">
                <a
                    class="page-link"
                    href="#"
                    (click)="setPage($event, activePage + 1)"
                    [class.disabled]="activePage === this.pages.length"
                    >»</a
                >
            </li>
        </ul>
    </div>
</div>
