<div
    class="modal"
    #dateEditionModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="dateEditionModal"
    aria-hidden="true">
    <form (submit)="updateDates()" novalidate>
        <div
            *ngIf="showing"
            class="modal-dialog modal-dialog-centered"
            role="document">
            <div class="modal-content text-light bg-dark">
                <div class="modal-header">
                    <h2 class="modal-title">Editar fechas:</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-2">
                            <label for="date-from">Desde:</label>
                            <input
                                id="date-from"
                                step="1"
                                name="date-from"
                                type="datetime-local"
                                class="form-control"
                                [(ngModel)]="from_date"
                                required />
                        </div>
                        <div class="col-12 col-md-6 mb-2">
                            <label for="date-to">Hasta:</label>
                            <input
                                id="date-to"
                                step="1"
                                name="date-to"
                                type="datetime-local"
                                class="form-control"
                                [(ngModel)]="to_date" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-pause" type="submit">
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
