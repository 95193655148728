import { Component, AfterViewInit } from '@angular/core';

declare let $: any;

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements AfterViewInit {
    showing = false;

    modal_id: string = Math.random().toString(36).substring(2, 15);

    modal: any;
    ngAfterViewInit() {
        this.modal = $(`#${this.modal_id}`)
            .on('show.bs.modal', () => {
                this.showing = true;
            })
            .on('hidden.bs.modal', () => {
                this.showing = false;
                this.doOnCancel();
            });
    }

    title!: string;
    message!: string;

    onConfirm: () => void = () => {};
    onCancel: () => void = () => {};
    confirmationPrompt(
        title: string,
        message: string,
        onConfirm: () => void,
        onCancel?: () => void
    ) {
        this.confirmed = false;

        this.title = title;
        this.message = message;

        this.onConfirm = onConfirm;
        this.onCancel = onCancel || (() => {});

        this.modal.modal('show');
    }

    confirmed = false;
    doOnConfirm() {
        this.confirmed = true;
        this.modal.modal('hide');
        this.onConfirm();
    }

    doOnCancel() {
        if (this.confirmed) return;
        this.onCancel();
    }
}
