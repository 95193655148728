import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterContentInit,
    AfterViewInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/User';
import { UserManagerService } from 'src/app/services/admin/user-manager.service';

declare let $: any;

@Component({
    selector: 'app-password-changing-modal',
    templateUrl: './password-changing-modal.component.html',
    styleUrls: ['./password-changing-modal.component.scss'],
})
export class PasswordChangingModalComponent implements AfterViewInit {
    showing = false;

    passwordChangingForm = this.formBuilder.group({
        uid: '',
        password: '',
        password_repeat: '',
    });

    @ViewChild('passwordCreationModal') passwordCreationModal!: ElementRef;

    @Input()
    maxPasswordLength = 32;

    @Output()
    onPasswordUpdated: EventEmitter<void> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private userManagerService: UserManagerService
    ) {}

    passwordChangingModal: any;
    ngAfterViewInit() {
        this.passwordChangingModal = $(this.passwordCreationModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    passwordChangingError: string | null = null;
    onSubmit(): void {
        // Process checkout data here
        console.warn('Submited form:', this.passwordChangingForm.value);

        this.passwordChangingError = null;

        const fields = this.passwordChangingForm.value;
        if (fields.password != fields.password_repeat) {
            this.passwordChangingError = 'Las contraseñas no coinciden';
            return;
        }

        this.passwordChangingForm.disable();

        this.userManagerService
            .changePassword(fields as User)
            .then(([success, res]) => {
                this.passwordChangingForm.enable();
                if (!success) {
                    this.passwordChangingError = res as string;
                    return;
                }

                this.passwordChangingForm.reset();
                this.passwordChangingModal.modal('hide');
                this.passwordChangingError = null;

                this.onPasswordUpdated.emit();
            });
    }

    openPasswordChangingModal(obj: any) {
        this.passwordChangingModal.modal('show');
        this.passwordChangingForm.reset();

        this.passwordChangingForm.patchValue(obj);

        this.passwordChangingError = null;
    }
}
