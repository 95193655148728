import {
    AfterContentInit,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
} from '@angular/core';

import OFService from 'src/app/services/main/of.service';

import Sale from 'src/app/models/Sale';
import WebsocketService from 'src/app/services/websocket.service';

declare let Notiflix: any;

@Component({
    selector: 'app-listado-ventas',
    templateUrl: './listado-ventas.component.html',
    styleUrls: [
        '../ordenes-fabricacion.component.scss',
        './listado-ventas.component.scss',
    ],
})
export class ListadoVentasComponent implements AfterContentInit, OnDestroy {
    constructor(
        private ofService: OFService,
        private wsService: WebsocketService
    ) {}

    unbindWS: any;
    ngAfterContentInit() {
        this.refreshList();

        this.unbindWS = this.wsService.on('of:created', () => {
            this.refreshList();
        });
    }

    ngOnDestroy() {
        if (this.unbindWS) this.unbindWS();
    }

    seachFilter = '';
    updatingList = false;
    itemList: Sale[] = [];
    refreshList(): Promise<boolean> {
        this.updatingList = true;
        return new Promise(resolve => {
            this.ofService
                .getSales(this.seachFilter) // 100 limit
                .then((sales: Sale[] | null) => {
                    this.updatingList = false;
                    if (!sales) return resolve(false);
                    this.itemList = sales;
                    resolve(true);
                });
        });
    }

    creatingOfSaleIDs: string[] = [];
    createOF(sale: Sale) {
        if (this.creatingOfSaleIDs.includes(sale.id)) return;
        this.creatingOfSaleIDs.push(sale.id);

        this.ofService.createOf(sale).then(([of, error]) => {
            if (!of) {
                this.creatingOfSaleIDs = this.creatingOfSaleIDs.filter(
                    id => id !== sale.id
                );
                return Notiflix.Notify.failure(error);
            }

            this.refreshList().then(() => {
                this.creatingOfSaleIDs = this.creatingOfSaleIDs.filter(
                    id => id !== sale.id
                );
            });
        });
    }

    changeTriggerTimeout: any;
    changeTrigger() {
        if (this.changeTriggerTimeout) clearTimeout(this.changeTriggerTimeout);
        this.changeTriggerTimeout = setTimeout(() => this.refreshList(), 500);
    }
}
