import { Injectable } from '@angular/core';
import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class MetricsService {
    constructor(private api: APIService) {}

    getMetrics(): Promise<any | null> {
        return new Promise(resolve => {
            this.api.get('/metrics/').then((res: any) => {
                const { success } = res;
                if (!success) return resolve(null);

                const { data } = res;
                return resolve(data);
            });
        });
    }
}

export default MetricsService;
