<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg-3">
        <label for="worker">Empleado:</label>
        <ng-select
            id="worker"
            [items]="empleados | async"
            (change)="onFiltersChange()"
            bindLabel="full_name"
            autofocus
            bindValue="uid"
            [(ngModel)]="selectedWorkerUID">
        </ng-select>
    </div>
    <div class="col-12 col-md-6 col-lg-5">
        <label for="date">Mes a generar:</label>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text" id="year">Año</span>
            </div>
            <input
                type="number"
                class="form-control"
                id="year"
                aria-describedby="year"
                [(ngModel)]="year"
                (change)="onFiltersChange()" />
            <div class="input-group-prepend">
                <span class="input-group-text" id="month">Mes</span>
            </div>
            <input
                type="number"
                class="form-control"
                id="month"
                aria-describedby="month"
                [(ngModel)]="month"
                (change)="onFiltersChange()" />
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg"></div>
    <div
        class="col-12 col-md-6 col-lg-auto d-flex align-items-center justify-content-end">
        <button
            class="btn btn-primary"
            [disabled]="!validInputs || generatingReport"
            (click)="generateReport()">
            Generar informe
        </button>
    </div>
</div>
