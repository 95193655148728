import { Injectable } from '@angular/core';
import OF from 'src/app/models/OF';
import Work_Pause from 'src/app/models/Work_Pause';
import Sale from 'src/app/models/Sale';

import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class OFService {
    constructor(private api: APIService) {}

    getSales(filter = ''): Promise<Sale[] | null> {
        const params: any = { filter };

        return new Promise(resolve => {
            this.api
                .get(`/eremub_bdd/ventas_lineas_list/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data as Sale[]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    createOf(sale: Sale): Promise<[boolean, OF | string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/of/create/`, {
                    pedido_id: sale.pedido_id,
                    linea: sale.linea,
                    sublinea: sale.sublinea,
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, data as OF]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    getActiveOFs(
        filter = '',
        sort_by: string | false = false,
        sort_direction: number | false = false
    ): Promise<OF[] | null> {
        const params: any = { filter };

        if (sort_by !== false) params['sort_by'] = sort_by;
        if (sort_direction !== false) params['sort_direction'] = sort_direction;

        return new Promise(resolve => {
            this.api
                .get(`/of/list/active/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data as OF[]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    getCompletedsOFs(
        page: number | false = false,
        filter = '',
        sort_by: string | false = false,
        sort_direction: number | false = false
    ): Promise<[OF[] | null, number?]> {
        const params: any = { filter };

        if (page !== false) params['from'] = page;
        if (sort_by !== false) params['sort_by'] = sort_by;
        if (sort_direction !== false) params['sort_direction'] = sort_direction;

        return new Promise(resolve => {
            this.api
                .get(`/of/list/completeds/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([null]);

                    const { data, pages } = res;
                    return resolve([data as OF[], pages]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([null]);
                });
        });
    }

    getExistingOFs(
        page: number | false = false,
        filter = '',
        sort_by: string | false = false,
        sort_direction: number | false = false
    ): Promise<[OF[] | null, number?]> {
        const params: any = { filter };

        if (page !== false) params['from'] = page;
        if (sort_by !== false) params['sort_by'] = sort_by;
        if (sort_direction !== false) params['sort_direction'] = sort_direction;

        return new Promise(resolve => {
            this.api
                .get(`/of/list/without_seguimiento/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([null]);

                    const { data, pages } = res;
                    return resolve([data as OF[], pages]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([null]);
                });
        });
    }

    getActiveSeguimientos(
        filter = '',
        sort_by: string | false = false,
        sort_direction: number | false = false
    ): Promise<OF[] | null> {
        const params: any = { filter };

        if (sort_by !== false) params['sort_by'] = sort_by;
        if (sort_direction !== false) params['sort_direction'] = sort_direction;

        return new Promise(resolve => {
            this.api
                .get(`/of/list/with_seguimiento/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data as OF[]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    getOF(uid: string): Promise<[boolean, OF | string | null]> {
        return new Promise(resolve => {
            this.api
                .get(`/of/${uid}/get/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, data as OF]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    getActividad(uid: string) {
        return new Promise(resolve => {
            this.api
                .get(`/of/${uid}/actividad/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    getPauses(
        from: number | false = false,
        worker_uid: string | false = false
    ): Promise<[boolean, Work_Pause[] | string | null, number]> {
        return new Promise(resolve => {
            const query_params: string[] = [];

            if (from !== false) query_params.push(`from=${from}`);
            if (worker_uid !== false)
                query_params.push(`worker_uid=${worker_uid}`);

            this.api
                .get(`/workers/pauses/list/?${query_params.join('&')}`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error, 0]);

                    const { data, pages } = res;
                    return resolve([true, data as Work_Pause[], pages]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null, 0]);
                });
        });
    }

    updatePause(
        uid: string,
        pause_data: Work_Pause
    ): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/pause/${uid}/update/`, pause_data)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    startSeguimiento(
        of_uid: string,
        client_email: string
    ): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/of/${of_uid}/start_seguimiento/`, { client_email })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    stopSeguimiento(of_uid: string): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/of/${of_uid}/stop_seguimiento/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    updatePhaseState(
        uid: string,
        enabled: boolean
    ): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/of/phase/${uid}/${enabled ? 'enable/' : 'disable/'}`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    deleteOf(uid: string): Promise<[boolean, string | null]> {
        4;
        return new Promise(resolve => {
            this.api
                .post(`/of/${uid}/delete/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    markAsCompleted(uid: string): Promise<[boolean, string | null]> {
        4;
        return new Promise(resolve => {
            this.api
                .post(`/of/${uid}/complete/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    markAsUnCompleted(uid: string): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/of/${uid}/uncomplete/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    return resolve([true, null]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[OFService]', ...args);
    }
}

export default OFService;
