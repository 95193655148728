import { Component, AfterViewInit, Input } from '@angular/core';
import MeService from 'src/app/services/me.service';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements AfterViewInit {
    @Input()
    title = '';

    @Input()
    backURL!: string;

    @Input()
    backButton!: boolean;

    constructor(private meService: MeService) {}

    avatar_public_url!: string;
    full_name!: string;
    ngAfterViewInit() {
        this.meService.getBasic().then(user => {
            if (!user) return;
            this.avatar_public_url = user.avatar_public_url as string;
            this.full_name = `${user.first_name} ${user.last_name}`;
        });
    }

    backButtonAction() {
        if (!this.backURL) return window.history.back();

        window.location.replace(this.backURL);
    }
}
