import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent {
    @Input() public data: { value: number; date: string }[] | null = null;

    private width = 100;
    private height = 500;
    private margin = 50;

    public svg: any;
    public svgInner: any;
    public yScale: any;
    public xScale: any;
    public xAxis: any;
    public yAxis: any;
    public lineGroup: any;

    constructor(public chartElem: ElementRef) {}

    public ngOnChanges(changes: any): void {
        if (!this.data) return;
        if (!changes.hasOwnProperty('data')) return;

        this.drawChart();

        window.addEventListener('resize', () => this.drawChart());
    }

    private drawChart(): void {
        if (!this.data) return;

        this.width = this.chartElem.nativeElement.getBoundingClientRect().width;

        this.svg = d3
            .select(this.chartElem.nativeElement)
            .select('.linechart')
            .html('')
            .append('svg')
            .attr('width', this.width)
            .attr('height', this.height);

        this.svgInner = this.svg
            .append('g')
            .style(
                'transform',
                'translate(' + this.margin + 'px, ' + this.margin + 'px)'
            );

        const max = d3.max(this.data, d => d.value),
            min = d3.min(this.data, d => d.value);

        if (max === undefined || min === undefined) return;

        this.yScale = d3
            .scaleLinear()
            .domain([Math.round(max * 1.1), min])
            .range([0, this.height - 2 * this.margin]);

        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .style(
                'transform',
                'translate(' + (this.width - 2 * this.margin) + 'px, 0)'
            );

        const domain = d3.extent(this.data, d => new Date(d.date));
        if (domain[0] === undefined || domain[1] === undefined) return;

        this.xScale = d3.scaleTime().domain(domain);

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .style(
                'transform',
                'translate(0, ' + (this.height - 2 * this.margin) + 'px)'
            );

        this.lineGroup = this.svgInner
            .append('g')
            .append('path')
            .attr('id', 'line')
            .style('fill', 'none')
            .style('stroke', '#009fd2')
            .style('stroke-width', '2px');

        this.xScale.range([this.margin, this.width - 2 * this.margin]);

        const xAxis = d3
            .axisBottom(this.xScale)
            .tickFormat(d => d3.timeFormat('%d/%m')(d as Date));

        this.xAxis.call(xAxis);

        const yAxis = d3.axisRight(this.yScale);

        this.yAxis.call(yAxis);

        this.yAxis.selectAll('.domain, .tick line').remove();
        this.xAxis.selectAll('.domain, .tick line').remove();

        const line = d3
            .line()
            .x(d => d[0])
            .y(d => d[1])
            .curve(d3.curveBasis);

        const yAxisGridLines = d3
            .axisLeft(this.yScale)
            .tickSize(-(this.width - 2 * this.margin));

        const points: [number, number][] = this.data.map(d => [
            this.xScale(new Date(d.date)),
            this.yScale(d.value),
        ]);

        this.lineGroup.attr('d', line(points));
    }
}
