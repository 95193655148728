import {
    Component,
    ElementRef,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';

import OFService from '../../../../../services/main/of.service';
import utils from 'src/utils';

declare let $: any;

@Component({
    selector: 'app-date-edition-modal',
    templateUrl: './date-edition-modal.component.html',
    styleUrls: ['./date-edition-modal.component.scss'],
})
export class DateEditionModalComponent {
    showing = false;

    @ViewChild('dateEditionModal') dateEditionModal!: ElementRef;

    @Output()
    onModalClose = new EventEmitter<boolean>();

    constructor(private ofService: OFService) {}

    dateEditionModalQ: any;
    ngAfterViewInit() {
        this.dateEditionModalQ = $(this.dateEditionModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    session_type!: string;
    session_uid!: string;
    from_date!: string;
    to_date!: string;
    callback: Function = () => {};

    openModal(
        session_type: string,
        session_uid: string,
        from_date: Date,
        to_date: Date,
        callback: Function
    ) {
        this.session_type = session_type;
        this.session_uid = session_uid;
        this.from_date = utils.toDateString(new Date(from_date));
        if (to_date) this.to_date = utils.toDateString(new Date(to_date));
        else this.to_date = '';

        this.callback = callback;

        this.dateEditionModalQ.modal('show');
    }

    updateDates() {
        this.callback(
            this.session_type,
            this.session_uid,
            this.from_date,
            this.to_date
        );
        this.dateEditionModalQ.modal('hide');
    }
}
