<div
    class="modal"
    [id]="modal_id"
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <div class="modal-header">
                <h2 class="modal-title">{{ title }}</h2>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div>{{ message }}</div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">
                    Cancelar
                </button>
                <button class="btn btn-success" (click)="doOnConfirm()">
                    Confirmar
                </button>
            </div>
        </div>
    </div>
</div>
