import { Component, AfterViewInit } from '@angular/core';
import InformesService from 'src/app/services/admin/informes.service';

@Component({
    selector: 'app-informe-errores',
    templateUrl: './informe-errores.component.html',
    styleUrls: [
        './informe-errores.component.scss',
        '../seguimiento.component.scss',
    ],
})
export class InformeErroresComponent implements AfterViewInit {
    constructor(private informesService: InformesService) {}

    destroyTimer: any = null;

    fetchingInformeErrores = false;
    informeErrores: any = null;
    ngAfterViewInit() {
        this.getImportePedidosCliente();
    }

    lastRequestUID = '';
    getImportePedidosCliente() {
        this.fetchingInformeErrores = true;

        const lastRequestUID = (this.lastRequestUID = Math.random()
            .toString(36)
            .substring(2, 15));
        this.informesService.getInformeErrores().then((informeErrores: any) => {
            if (lastRequestUID != this.lastRequestUID) return;
            this.informeErrores = informeErrores;
            this.fetchingInformeErrores = false;
        });
    }
}
