import { Injectable } from '@angular/core';
import APIService from '../api.service';
import Client from 'src/app/models/Client';

@Injectable({
    providedIn: 'root',
})
export class InformesService {
    constructor(private api: APIService) {}

    getClientes(): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/clientes/list/')
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTMedioFabricacionArticulo(): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/tmedio-fabricacion-articulo/')
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getImportePedidosCliente(): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/importe-pedidos-cliente/')
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getImportePedidosExtendidoCliente(client: Client): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/importe-pedidos-extendido-cliente/', {
                    client_uid: client.uid,
                })
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTEstTReqPorArtFam(): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/treq-por-art-fam/')
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTEstTReqPorPedido(client: Client | null): Promise<any[] | null> {
        return new Promise(resolve => {
            let params = {};
            if (client) params = { client_uid: client.uid };
            this.api
                .get('/admin/informes/treq-por-pedido/', params)
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTEstTReqExtendidoPedido(pedido_id: string): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/treq-extendido-pedido/', { pedido_id })
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTEstTReqPorPedidoPeriodoDet(
        client: Client | null,
        from_date: string,
        to_date: string
    ): Promise<any[] | null> {
        return new Promise(resolve => {
            const params: any = { from_date, to_date };
            if (client) params['client_uid'] = client.uid;
            this.api
                .get('/admin/informes/treq-por-pedido-periodo-det/', params)
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getTEstTReqPorPedidosCliente(client_id: string): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/treq-por-pedidos-cliente/', { client_id })
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    getInformeErrores(): Promise<any[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/informes/errores/')
                .then((response: any) => resolve(response.data as any[]))
                .catch((error: any) => {
                    this.log('Error while requesting', error);
                    return resolve(null);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[InformesService]', ...args);
    }
}

export default InformesService;
