<app-top-nav title="Informes"></app-top-nav>

<div class="row">
    <div class="col-12 col-xl-auto">
        <ul class="nav-tabs mb-2">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/informes/comp-t-est-req-ped"
                    >Comp. T. est. - T. req por pedidos</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a
                    class="nav-link"
                    routerLink="/informes/comp-t-est-req-ped-per-det"
                    >Comp. T. est. - T. req por pedidos en periodo det.</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a
                    class="nav-link"
                    routerLink="/informes/comp-t-est-req-art-fam"
                    >Comp. T. est. - T. req por artículo/familia</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/informes/t-medio-fab-art"
                    >T. medio de fabricación de un artículo</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a
                    class="nav-link"
                    routerLink="/informes/impore-pedidos-cliente"
                    >Importe pedidos por cliente</a
                >
            </li>
        </ul>
    </div>
    <div class="col-12 col-xl right-content">
        <div class="tab-content">
            <div class="tab-pane" [class.active]="tab === 'comp-t-est-req-ped'">
                <app-comp-test-treq-por-pedido
                    *ngIf="
                        tab === 'comp-t-est-req-ped'
                    "></app-comp-test-treq-por-pedido>
            </div>
            <div
                class="tab-pane"
                [class.active]="tab === 'comp-t-est-req-ped-per-det'">
                <app-comp-test-treq-por-pedido-periodo-det
                    *ngIf="
                        tab == 'comp-t-est-req-ped-per-det'
                    "></app-comp-test-treq-por-pedido-periodo-det>
            </div>
            <div
                class="tab-pane"
                [class.active]="tab === 'comp-t-est-req-art-fam'">
                <app-comp-test-treq-por-art-fam
                    *ngIf="
                        tab == 'comp-t-est-req-art-fam'
                    "></app-comp-test-treq-por-art-fam>
            </div>
            <div class="tab-pane" [class.active]="tab === 't-medio-fab-art'">
                <app-tmedio-fabricacion-articulo
                    *ngIf="
                        tab === 't-medio-fab-art'
                    "></app-tmedio-fabricacion-articulo>
            </div>
            <div
                class="tab-pane"
                [class.active]="tab === 'impore-pedidos-cliente'">
                <app-importe-pedidos-cliente
                    *ngIf="
                        tab === 'impore-pedidos-cliente'
                    "></app-importe-pedidos-cliente>
            </div>
        </div>
    </div>
</div>
