<div
    class="modal"
    #passwordCreationModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="userPasswordChangingModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <form
                [formGroup]="passwordChangingForm"
                (ngSubmit)="onSubmit()"
                ngNativeValidate>
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar Contraseña</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row my-2">
                        <div class="col-12 col-md-6 mb-3">
                            <label for="password">Contraseña</label>
                            <input
                                type="password"
                                class="form-control"
                                #password
                                formControlName="password"
                                minlength="3"
                                [maxlength]="maxPasswordLength"
                                placeholder="Contraseña"
                                required />
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="password_repeat"
                                >Repetir Contraseña</label
                            >
                            <input
                                type="password"
                                class="form-control"
                                #password_repeat
                                formControlName="password_repeat"
                                minlength="3"
                                [maxlength]="maxPasswordLength"
                                placeholder="Repetir Contraseña"
                                required />
                        </div>
                    </div>
                    <div
                        *ngIf="passwordChangingError"
                        class="alert alert-danger my-1"
                        role="alert">
                        {{ passwordChangingError }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" type="submit">
                        Actualizar Contraseña
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
