import { Component, ViewChild } from '@angular/core';
import { Empleado } from 'src/app/models/Empleado';
import { EmpleadosCreatingModalComponent } from './empleado-creating-modal/empleado-creating-modal.component';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';
import { EmpleadosEditingModalComponent } from './empleado-editing-modal/empleado-editing-modal.component';
import { PasswordChangingModalComponent } from '../password-changing-modal/password-changing-modal.component';

declare let $: any;
declare let Notiflix: any;

@Component({
    selector: 'app-empleados-manager',
    templateUrl: './empleados-manager.component.html',
    styleUrls: [
        '../gestion.component.scss',
        './empleados-manager.component.scss',
    ],
})
export class EmpleadosManagerComponent {
    @ViewChild('empleadoCreationModal')
    empleadoCreationModal!: EmpleadosCreatingModalComponent;
    @ViewChild('empleadoEditionModal')
    empleadoEditionModal!: EmpleadosEditingModalComponent;
    @ViewChild('passwordChangingModal')
    passwordChangingModal!: PasswordChangingModalComponent;

    constructor(private empleadoManagerService: EmpleadoManagerService) {}

    ngAfterContentInit() {
        this.updateEmpleadoList();

        $('.dropdown-toggle').dropdown();
    }

    empleadoListLoading = false;
    empleadoList: Empleado[] = [];
    updateEmpleadoList() {
        this.empleadoListLoading = true;
        this.empleadoManagerService
            .listEmpleados()
            .then((empleados: Empleado[] | null) => {
                if (!empleados) return;
                this.empleadoListLoading = false;
                this.empleadoList = empleados.sort((a, b) =>
                    a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
                );
            });
    }

    openEmpleadoCreationModal() {
        this.empleadoCreationModal.openEmpleadoCreationModal();
    }

    openEmpleadoEditionModal(empleado: Empleado) {
        this.empleadoEditionModal.openEmpleadoEditionModal(empleado);
    }

    openPasswordChangingModal(empleado: Empleado) {
        this.passwordChangingModal.openPasswordChangingModal(empleado);
    }

    deleteEmpleado(empleado: Empleado) {
        this.empleadoManagerService
            .deleteEmpleado(empleado)
            .then(([success, error]) => {
                if (!success) {
                    console.error(error);
                    Notiflix.Notify.failure(error);
                    return;
                }

                this.updateEmpleadoList();
            });
    }

    deactivateEmployee(empleado: Empleado) {
        this.empleadoManagerService
            .deactivateEmpleado(empleado)
            .then(([success, error]) => {
                if (!success) {
                    console.error(error);
                    Notiflix.Notify.failure(error);
                    return;
                }

                this.updateEmpleadoList();
            });
    }

    activateEmployee(empleado: Empleado) {
        this.empleadoManagerService
            .activateEmpleado(empleado)
            .then(([success, error]) => {
                if (!success) {
                    console.error(error);
                    Notiflix.Notify.failure(error);
                    return;
                }

                this.updateEmpleadoList();
            });
    }

    uploadAvatar(empleado: Empleado) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            if (!file) return;
            this.empleadoManagerService
                .uploadAvatar(empleado, file)
                .then(([success, error]) => {
                    if (!success) {
                        Notiflix.Notify.failure(error);
                        return;
                    }

                    this.updateEmpleadoList();
                });
        };

        input.click();
    }
}
