import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Empleado } from 'src/app/models/Empleado';
import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class EmpleadoManagerService {
    constructor(
        private api: APIService,
        private router: Router
    ) {}

    listEmpleados(): Promise<Empleado[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/worker/list/')
                .then((response: any) => resolve(response.data as Empleado[]))
                .catch((error: any) => {
                    this.log('Error while listing empleados', error);
                    return resolve(null);
                });
        });
    }

    createEmpleado(
        empleado: Empleado
    ): Promise<[boolean, Empleado | string | null]> {
        return new Promise(resolve => {
            this.api
                .post('/admin/worker/create/', empleado)
                .then((response: any) => {
                    const { success, data, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, data as Empleado]);
                })
                .catch((error: any) => {
                    this.log('Error while creating empleado', error);
                    return resolve([false, null]);
                });
        });
    }

    updateEmpleado(
        empleado: Empleado
    ): Promise<[boolean, Empleado | string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/worker/${empleado.uid}/update/`, empleado)
                .then((response: any) => {
                    const { success, data, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, data as Empleado]);
                })
                .catch((error: any) => {
                    this.log('Error while updating empleado', error);
                    return resolve([false, null]);
                });
        });
    }

    uploadAvatar(
        empleado: Empleado,
        file: File
    ): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            const formData = new FormData();
            formData.append('avatar', file);

            this.api
                .post(`/admin/worker/${empleado.uid}/upload-avatar/`, formData)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while uploading avatar', error);
                    return resolve([false, null]);
                });
        });
    }

    deleteEmpleado(empleado: Empleado): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/worker/${empleado.uid}/delete/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while deleting empleado', error);
                    return resolve([false, null]);
                });
        });
    }

    deactivateEmpleado(empleado: Empleado): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/worker/${empleado.uid}/deactivate/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while archiving empleado', error);
                    return resolve([false, null]);
                });
        });
    }

    activateEmpleado(empleado: Empleado): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/worker/${empleado.uid}/activate/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while activating empleado', error);
                    return resolve([false, null]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[EmpleadoManagerService]', ...args);
    }
}

export default EmpleadoManagerService;
