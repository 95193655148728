import {
    Component,
    ElementRef,
    AfterViewInit,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';
import Work_Pause from 'src/app/models/Work_Pause';
import OFService from '../../../../../services/main/of.service';
import utils from '../../../../../../utils';

declare let $: any;

@Component({
    selector: 'app-of-pause-edit-modal',
    templateUrl: './of-pause-edit-modal.component.html',
    styleUrls: ['./of-pause-edit-modal.component.scss'],
})
export class OfPauseEditModalComponent implements AfterViewInit {
    showing = false;

    @ViewChild('ofPauseModal') ofPauseModal!: ElementRef;

    @Output()
    onModalClose = new EventEmitter<boolean>();

    constructor(private ofService: OFService) {}

    ofPauseModalQ: any;
    ngAfterViewInit() {
        this.ofPauseModalQ = $(this.ofPauseModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    pause_uid!: string;

    start_date!: string;
    end_date!: string;

    openModal(pause: Work_Pause) {
        this.ofPauseModalQ.modal('show');

        this.pause_uid = pause.uid;

        this.start_date = utils.toDateString(new Date(pause.start_date));

        this.end_date = '';
        if (pause.end_date) {
            this.end_date = utils.toDateString(new Date(pause.end_date));
        }

        this.selected_opt = pause.reason_code;
        this.selected_opt_extra = pause.reason_extended;
    }

    selected_opt!: string;
    selected_opt_extra!: string;
    setOpt(opt: string) {
        this.selected_opt = opt;
    }

    updatePause() {
        const pause_args: any = {
            start_date: new Date(this.start_date).toISOString(),
            end_date: this.end_date
                ? new Date(this.end_date).toISOString()
                : '',

            reason_code: this.selected_opt,
            reason_extended: this.selected_opt_extra,
        };

        this.ofService
            .updatePause(this.pause_uid, pause_args)
            .then(([success, error]) => {
                if (!success) return;

                this.ofPauseModalQ.modal('hide');
                this.onModalClose.emit(true);
            });
    }
}
