import { Component, AfterContentInit, OnDestroy } from '@angular/core';

import OFService from 'src/app/services/main/of.service';

import OF from 'src/app/models/OF';

import WebsocketService from 'src/app/services/websocket.service';

import { prettyTimeSring } from 'src/utils';

declare let Notiflix: any;

@Component({
    selector: 'app-ordenes-completadas',
    templateUrl: './ordenes-completadas.component.html',
    styleUrls: ['./ordenes-completadas.component.scss'],
})
export class OrdenesCompletadasComponent
    implements AfterContentInit, OnDestroy
{
    prettyTimeSring = prettyTimeSring;

    constructor(
        private ofService: OFService,
        private wsService: WebsocketService
    ) {}

    unbindWS: any;
    ngAfterContentInit() {
        this.refreshList();

        this.unbindWS = this.wsService.on(
            'global:updated',
            (data: any | null) => {
                this.refreshList();
            }
        );
    }

    ngOnDestroy() {
        if (this.unbindWS) this.unbindWS();
    }

    pages: number[] = [];
    activePage: number = 1;

    seachFilter = '';
    updatingList = false;
    itemList: OF[] = [];
    poastFinishRuns: any[] = [];
    refreshList(): Promise<boolean> {
        const sorting_keys = Object.keys(this.sortings);
        const sort_by = sorting_keys.length > 0 ? sorting_keys[0] : false;
        const sort_direction =
            sort_by !== false ? this.sortings[sort_by] : false;

        if (this.updatingList) {
            let resolve;
            const promise: Promise<boolean> = new Promise(
                _resolve => (resolve = _resolve)
            );
            this.poastFinishRuns.push(resolve);

            return promise;
        }
        this.updatingList = true;
        return new Promise(resolve => {
            this.ofService
                .getCompletedsOFs(
                    this.activePage,
                    this.seachFilter,
                    sort_by as string,
                    sort_direction
                ) // 100 limit
                .then(([ofs, pages]) => {
                    if (this.poastFinishRuns.length > 0) {
                        const runs = this.poastFinishRuns;
                        this.refreshList().then(() => {
                            runs.forEach(run => run(true));
                            this.poastFinishRuns = [];
                        });
                    }

                    this.updatingList = false;
                    if (!ofs) return resolve(false);
                    this.itemList = ofs;

                    if (pages != undefined) {
                        this.pages = Array(pages)
                            .fill(0)
                            .map((_, i) => i + 1);
                        if (this.activePage > pages) this.activePage = pages;
                    }

                    resolve(true);
                });
        });
    }

    setPage(e: Event, page: number) {
        e.stopPropagation();
        e.preventDefault();

        if (page < 1 || page > this.pages.length || page === this.activePage)
            return;
        this.activePage = page;
        this.refreshList();
    }

    get displayedPages(): number[] {
        const total = this.pages.length;
        if (total <= 9) return this.pages;

        let pagesToShow = [];

        if (this.activePage <= 5) {
            pagesToShow = [1, 2, 3, 4, 5, 6, -1, total];
        } else if (this.activePage > total - 5) {
            pagesToShow = [1, -1].concat(this.pages.slice(-6));
        } else {
            pagesToShow = [
                1,
                -1,
                this.activePage - 2,
                this.activePage - 1,
                this.activePage,
                this.activePage + 1,
                this.activePage + 2,
                -1,
                total,
            ];
        }

        return pagesToShow;
    }

    changeTriggerTimeout: any;
    changeTrigger() {
        if (this.changeTriggerTimeout) clearTimeout(this.changeTriggerTimeout);
        this.changeTriggerTimeout = setTimeout(() => this.refreshList(), 500);
    }

    uncompleting = false;
    markAsUnCompleted(of_uid: string) {
        if (this.uncompleting) return;
        this.uncompleting = true;
        this.ofService
            .markAsUnCompleted(of_uid)
            .then(() => (this.uncompleting = false))
            .catch(() => (this.uncompleting = false));
    }

    sortings: any = {
        plazo: -1,
    };
    sortBy(column: string) {
        if (column in this.sortings) {
            if (this.sortings[column] == 1) this.sortings[column] = -1;
            else this.sortings[column] = 1;
        } else {
            this.sortings = {};
            this.sortings[column] = 1;
        }

        this.refreshList();
    }
}
