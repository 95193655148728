import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-seguimiento',
    templateUrl: './seguimiento.component.html',
    styleUrls: ['./seguimiento.component.scss'],
})
export class SeguimientoComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    public tab = '';
    avalibleTabs: string[] = [
        'jornada-empleados',
        'informe-jornada',
        'informe-errores',
        'paradas',
    ];

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.tab = params['tab'];
            if (!this.avalibleTabs.includes(this.tab))
                this.router.navigate(['seguimiento']);
        });
    }
}
