import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicSeguiminetoService } from 'src/app/services/public/public-seguimineto.service';

@Component({
    selector: 'app-public-seguimiento',
    templateUrl: './public-seguimiento.component.html',
    styleUrls: ['./public-seguimiento.component.scss'],
})
export class PublicSeguimientoComponent {
    constructor(
        private publicSeguimiento: PublicSeguiminetoService,
        private activatedRoute: ActivatedRoute
    ) {}

    seguimiento_uid = '';
    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.seguimiento_uid = params['seguimiento_uid'];
        });
    }

    fetchingSeguimiento = true;
    seguimientoData: any = null;
    errorFetchingSeguimiento!: string | null;
    ngAfterViewInit() {
        this.publicSeguimiento
            .fetchOFState(this.seguimiento_uid)
            .then(([success, data]) => {
                this.fetchingSeguimiento = false;
                if (!success) {
                    this.errorFetchingSeguimiento = data;
                    return;
                }

                this.seguimientoData = data;
            });
    }
}
