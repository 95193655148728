import { Component } from '@angular/core';
import AuthService from './services/auth.service';
import WebsocketService from './services/websocket.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'eremu-panel';

    constructor(
        public authService: AuthService,
        private wsService: WebsocketService
    ) {
        this.authService.sessionCheck();
    }
}
