import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Empleado } from 'src/app/models/Empleado';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';

declare let $: any;

@Component({
    selector: 'app-empleado-creating-modal',
    templateUrl: './empleado-creating-modal.component.html',
    styleUrls: ['./empleado-creating-modal.component.scss'],
})
export class EmpleadosCreatingModalComponent {
    showing = false;

    empleadoCreationForm = this.formBuilder.group({
        first_name: '',
        last_name: '',
        password: '',
        password_repeat: '',
    });

    @Output()
    onEmpleadoCreated: EventEmitter<Empleado> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private empleadoManagerService: EmpleadoManagerService
    ) {}

    empleadoCreationModal: any;
    ngOnInit() {
        this.empleadoCreationModal = $('#empleado-creation-modal')
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    creationError: string | null = null;
    onSubmit(): void {
        // Process checkout data here
        console.warn('Submited form:', this.empleadoCreationForm.value);

        this.creationError = null;

        const fields = this.empleadoCreationForm.value;
        if (fields.password != fields.password_repeat) {
            this.creationError = 'Las contraseñas no coinciden';
            return;
        }

        this.empleadoCreationForm.disable();

        this.empleadoManagerService
            .createEmpleado(fields as Empleado)
            .then(([success, res]) => {
                this.empleadoCreationForm.enable();
                if (!success) {
                    this.creationError = res as string;
                    return;
                }

                this.empleadoCreationForm.reset();
                this.empleadoCreationModal.modal('hide');
                this.creationError = null;

                this.onEmpleadoCreated.emit(res as Empleado);
            });
    }

    openEmpleadoCreationModal() {
        this.empleadoCreationModal.modal('show');
        this.empleadoCreationForm.reset();
        this.creationError = null;
    }
}
