import { Injectable } from '@angular/core';
import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class PublicSeguiminetoService {
    constructor(private api: APIService) {}

    public fetchOFState(seguimiento_uid: string): Promise<any> {
        return new Promise(resolve => {
            this.api
                .get(`/public/seguimiento/${seguimiento_uid}/`, {}, false)
                .then((res: any) => {
                    const { success } = res;
                    if (!success)
                        return resolve([false, res.error || res.detail]);

                    const { data } = res;
                    return resolve([true, data]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, null]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[PublicSeguiminetoService]', ...args);
    }
}
