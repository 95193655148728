import { Component, AfterViewInit } from '@angular/core';
import InformesService from 'src/app/services/admin/informes.service';

import utils, { prettyTimeSring } from 'src/utils';

import { Papa } from 'ngx-papaparse';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-tmedio-fabricacion-articulo',
    templateUrl: './tmedio-fabricacion-articulo.component.html',
    styleUrls: [
        './tmedio-fabricacion-articulo.component.scss',
        '../informes.component.scss',
    ],
})
export class TMedioFabricacionArticuloComponent implements AfterViewInit {
    prettyTimeSring = prettyTimeSring;

    constructor(
        private informesService: InformesService,
        private papa: Papa
    ) {}

    fetchingTmedios = false;
    tmedios: any = null;
    tmedios_filtered: any = null;
    seachFilter = '';
    lastRequestUID = '';
    getTMedioFabricacionArticulo() {
        this.fetchingTmedios = true;
        const lastRequestUID = (this.lastRequestUID = Math.random()
            .toString(36)
            .substring(2, 15));
        this.informesService
            .getTMedioFabricacionArticulo()
            .then((data: any[] | null) => {
                if (lastRequestUID != this.lastRequestUID) return;
                this.fetchingTmedios = false;
                if (!data) return;
                this.tmedios = data;

                this.onFiltersChange();
            });
    }

    exportToXlsx() {
        const data = this.tmedios.map((tmedio: any) => {
            return {
                'ID Artículo': tmedio.id_articulo,
                'Desc. Artículo': tmedio.desc_articulo,
                'T. Medio': tmedio.t_medio / 1000 / 60
            };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'export.xlsx');
    }

    ngAfterViewInit() {
        this.getTMedioFabricacionArticulo();
    }

    onFiltersChange() {
        this.tmedios_filtered = this.tmedios.filter((tmedio: any) => {
            return tmedio.id_articulo
                .toLowerCase()
                .includes(this.seachFilter.toLowerCase());
        });
    }
}
