<app-top-nav title="Orden de fabricación" [backButton]="true"> </app-top-nav>

<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading && order_fetch_errored" class="col-12">
    <div class="alert alert-danger">
        <i class="fa-solid fa-exclamation-triangle"></i>
        <span class="ml-1">{{ order_fetch_error }}</span>
    </div>
</div>
<div class="row" *ngIf="!loading && !order_fetch_errored">
    <div class="col-12 col-xl-4 of-left-card">
        <div class="card">
            <div class="card-body">
                <table class="order-details-table">
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{{ order.linea_composed_id }}</td>
                        </tr>
                        <tr>
                            <td>Pedido</td>
                            <td>{{ order.pedido_id }}</td>
                        </tr>
                        <tr>
                            <td>Linea</td>
                            <td>{{ order.linea }}</td>
                        </tr>
                        <tr>
                            <td>Sublinea</td>
                            <td>{{ order.sublinea }}</td>
                        </tr>
                        <tr>
                            <td>Cliente</td>
                            <td>{{ order.client.name }}</td>
                        </tr>
                        <tr>
                            <td>Unidades</td>
                            <td>{{ order.ctd }}</td>
                        </tr>
                        <tr>
                            <td>Artículo</td>
                            <td>{{ order.id_articulo }}</td>
                        </tr>
                        <tr>
                            <td>Descripción</td>
                            <td>{{ order.descrip_articulo }}</td>
                        </tr>
                        <tr>
                            <td>Email Cliente</td>
                            <td>{{ order.email_cliente }}</td>
                        </tr>
                        <tr>
                            <td>Plazo</td>
                            <td>
                                {{
                                    order.plazo | localDate: 'HH:mm dd/MM/yyyy'
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button
                    *ngIf="!order.completed"
                    (click)="markAsCompleted(order.uid)"
                    class="btn btn-success w-100 mt-2"
                    [class.btn-loading]="completing"
                    [disabled]="completing">
                    <span class="ml-1">Marcar como completada</span>
                    <i class="fas fa-share ml-2"></i>
                </button>
                <button
                    *ngIf="order.completed"
                    (click)="markAsUnCompleted(order.uid)"
                    class="btn btn-warning w-100 mt-2"
                    [class.btn-loading]="completing"
                    [disabled]="completing">
                    <span class="ml-1">Marcar como incompleta</span>
                    <i class="fas fa-share ml-2"></i>
                </button>
                <button
                    (click)="deleteOf(order.uid)"
                    class="btn btn-danger w-100 mt-2"
                    [class.btn-loading]="completing"
                    [disabled]="completing">
                    <span class="ml-1">Eliminar OF</span>
                    <i class="fas fa-trash ml-2"></i>
                </button>
            </div>
        </div>
        <div
            class="alert alert-danger mt-1"
            *ngIf="order.last_completed_by_albaranado">
            <span class="ml-1">Completado automáticamente por albaranado</span>
        </div>
    </div>
    <div class="col-12 col-xl">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Fases</h3>
            </div>
            <div class="card-body">
                <app-phase-container
                    (togglePhaseState)="togglePhaseState($event)"
                    [height]="60"
                    [showTitles]="true"
                    [toggleMode]="true"
                    [phaseList]="order.phases">
                </app-phase-container>
            </div>
        </div>
        <div class="card mt-4">
            <div class="card-header">
                <h3 class="card-title">Actividad</h3>
            </div>
            <div class="card-body">
                <div class="custom-table table-no-border" *ngIf="actividad">
                    <div class="table-body">
                        <div class="table-row table-cols">
                            <div class="cell">
                                <span>Empleado</span>
                            </div>
                            <div class="cell">
                                <span>Fase</span>
                            </div>
                            <div class="cell">
                                <span>Inicio</span>
                            </div>
                            <div class="cell">
                                <span>Fin</span>
                            </div>
                            <div class="cell">
                                <span>T. dedicado</span>
                            </div>
                        </div>
                        <div
                            class="table-row"
                            *ngFor="let session of actividad.sessions"
                            [attr.data-nav-id]="session.nav_uid"
                            [class.session-active]="session.is_active">
                            <ng-container
                                *ngIf="
                                    session.type === 'multi_of_phase_session' ||
                                    session.type === 'of_phase_session'
                                ">
                                <div class="cell">
                                    <span>{{ session.user.full_name }}</span>
                                </div>
                                <div class="cell d-flex align-items-center">
                                    <div
                                        class="phase-circle d-flex mr-2"
                                        [attr.data-phase-state]="
                                            session.phase.state
                                        ">
                                        <svg
                                            [class.phase-disabled]="
                                                !session.phase.enabled
                                            "
                                            [style.height]="'35px'"
                                            viewBox="0 0 31 31"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                class="phase-circle"
                                                d="M29.9093 15.1934C29.9093 23.0398 23.6019 29.3867 15.8384 29.3867C8.07492 29.3867 1.76758 23.0398 1.76758 15.1934C1.76758 7.34694 8.07492 1 15.8384 1C23.6019 1 29.9093 7.34694 29.9093 15.1934Z"
                                                [attr.stroke]="
                                                    getPhaseColor(
                                                        session.phase.name
                                                    )
                                                "
                                                stroke-width="2" />
                                            <path
                                                *ngFor="
                                                    let index of getPhaseIconPaths(
                                                        session.phase.name
                                                    )
                                                "
                                                [attr.d]="
                                                    getPhaseIcon(
                                                        session.phase.name
                                                    )[index]
                                                "
                                                [attr.fill]="
                                                    getPhaseColor(
                                                        session.phase.name
                                                    )
                                                " />
                                        </svg>
                                    </div>
                                    <span
                                        [style.color]="
                                            getPhaseColor(session.phase.name)
                                        "
                                        >{{ session.phase.name }}</span
                                    >
                                </div>
                                <div class="cell date-container">
                                    <span class="main">{{
                                        session.start_date
                                            | localDate: 'HH:mm:ss'
                                    }}</span>
                                    <br />
                                    <span class="secondary">{{
                                        session.start_date
                                            | localDate: 'd MMM, y'
                                    }}</span>
                                </div>
                                <div class="cell date-container">
                                    <div *ngIf="!session.is_active">
                                        <span class="main">{{
                                            session.end_date
                                                | localDate: 'HH:mm:ss'
                                        }}</span>
                                        <br />
                                        <span class="secondary">{{
                                            session.end_date
                                                | localDate: 'd MMM, y'
                                        }}</span>
                                    </div>
                                    <div *ngIf="session.is_active">
                                        <span class="date">Actuallidad</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    {{ prettyTimeSring(session.total_time) }}
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="session.type === 'unlinked_session'">
                                <div class="cell pause-cell">
                                    <h4>
                                        <i class="fas fa-external-link"></i>
                                        Sesón desvinculada
                                    </h4>
                                </div>
                                <div class="cell">
                                    <strong>Tarea:</strong>
                                    {{ session.work_code }}
                                    <div *ngIf="session.work_code_extended">
                                        <strong>Observaciones:</strong>
                                        {{ session.work_code_extended }}
                                    </div>
                                </div>
                                <div class="cell"></div>
                                <div class="cell date-container">
                                    <span class="main">{{
                                        session.start_date
                                            | localDate: 'HH:mm:ss'
                                    }}</span>
                                    <br />
                                    <span class="secondary">{{
                                        session.start_date
                                            | localDate: 'd MMM, y'
                                    }}</span>
                                </div>
                                <div class="cell date-container">
                                    <div *ngIf="!session.is_active">
                                        <span class="main">{{
                                            session.end_date
                                                | localDate: 'HH:mm:ss'
                                        }}</span>
                                        <br />
                                        <span class="secondary">{{
                                            session.end_date
                                                | localDate: 'd MMM, y'
                                        }}</span>
                                    </div>
                                    <div *ngIf="session.is_active">
                                        <span class="date">Actuallidad</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    {{ prettyTimeSring(session.total_time) }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="session.type === 'work_pause'">
                                <div class="cell pause-cell">
                                    <h4>
                                        <i class="far fa-pause-circle"></i>
                                        Parada Registrada
                                    </h4>
                                </div>
                                <div class="cell pause-cell">
                                    <strong>Razón:</strong>
                                    {{ getReasonFromCode(session.reason_code) }}
                                    <div *ngIf="session.reason_extended">
                                        <strong>Observaciones:</strong>
                                        {{ session.reason_extended }}
                                    </div>
                                </div>
                                <div class="cell pause-cell"></div>
                                <div class="cell pause-cell date-container">
                                    <span class="main">{{
                                        session.start_date
                                            | localDate: 'HH:mm:ss'
                                    }}</span>
                                    <br />
                                    <span class="secondary">{{
                                        session.start_date
                                            | localDate: 'd MMM, y'
                                    }}</span>
                                </div>
                                <div class="cell pause-cell date-container">
                                    <div *ngIf="!session.is_active">
                                        <span class="main">{{
                                            session.end_date
                                                | localDate: 'HH:mm:ss'
                                        }}</span>
                                        <br />
                                        <span class="secondary">{{
                                            session.end_date
                                                | localDate: 'd MMM, y'
                                        }}</span>
                                    </div>
                                    <div *ngIf="session.is_active">
                                        <span class="date">Actuallidad</span>
                                    </div>
                                </div>
                                <div class="cell pause-cell">
                                    {{ prettyTimeSring(session.total_time) }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-confirmation-modal #confirmationModal></app-confirmation-modal>
