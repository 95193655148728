<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg-2">
        <label for="serch-by-id">ID Pedido:</label>
        <input
            type="text"
            id="serch-by-id"
            class="form-control"
            placeholder="Buscar por ID..."
            [(ngModel)]="seachFilter"
            (input)="onFiltersChange()"
            (change)="onFiltersChange()" />
    </div>
    <div class="col-12 col-md-6 col-lg-2">
        <label for="worker">Cliente:</label>
        <ng-select
            id="worker"
            [items]="clientes"
            (change)="getTEstTReqPorPedido()"
            bindLabel="name"
            [class.is-invalid]="!selectedClient"
            autofocus
            [(ngModel)]="selectedClient">
        </ng-select>
    </div>
    <div class="col-12 col-md-6 col-lg"></div>
    <div class="col-12 col-md-6 col-lg-auto d-flex align-items-end">
        <button
            class="btn btn-export mr-1"
            (click)="exportToXlsx()"
            [disabled]="fetchingTmedios">
            <i class="fa-solid fa-download"></i>
        </button>
        <button
            class="btn btn-refresh"
            [class.i-spin]="fetchingTmedios"
            (click)="getTEstTReqPorPedido()"
            [disabled]="fetchingTmedios">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
</div>
<div class="alert alert-warning" *ngIf="!selectedClient">
    Selecciona un cliente válido
</div>
<div class="row" *ngIf="selectedClient">
    <div class="col-12" *ngIf="fetchingTmedios">
        <app-loader></app-loader>
    </div>
    <div class="col-12" *ngIf="!fetchingTmedios && tmedios_filtered">
        <div class="card mb-2" *ngIf="!tmedios_filtered.length">
            <div class="card-body">Sin articúlos para estos filtros</div>
        </div>
        <div *ngIf="tmedios_filtered.length">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                Comp. T. est. - T. req por pedidos
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div
                                class="custom-table table-no-border"
                                [class.table-refreshing]="fetchingTmedios">
                                <div
                                    class="table-body"
                                    [class.table-loading]="fetchingTmedios">
                                    <div class="table-row table-cols">
                                        <div class="cell">
                                            <span>ID Pedido</span>
                                        </div>
                                        <div class="cell">
                                            <span>Nº Artículos</span>
                                        </div>
                                        <div class="cell">
                                            <span>Desfase</span>
                                        </div>
                                        <div class="cell">
                                            <span>T. Estimado</span>
                                        </div>
                                        <div class="cell">
                                            <span>T. Requerido</span>
                                        </div>
                                    </div>
                                    <div
                                        class="table-row pedido-row"
                                        (click)="onPedidoToggled(pedido)"
                                        [class.table-row-expanded]="
                                            pedido.expanded
                                        "
                                        *ngFor="let pedido of tmedios_filtered">
                                        <div class="cell">
                                            {{ pedido.pedido_id }}
                                        </div>
                                        <div class="cell">
                                            <span class="datum time">{{
                                                pedido.ctd
                                            }}</span>
                                        </div>
                                        <div class="cell">
                                            <span
                                                class="datum positive"
                                                [class.negative]="
                                                    pedido.offset > 0
                                                "
                                                >{{
                                                    prettyTimeSring(
                                                        pedido.offset
                                                    )
                                                }}</span
                                            >
                                        </div>
                                        <div class="cell">
                                            <span class="datum time">{{
                                                prettyTimeSring(pedido.t_est)
                                            }}</span>
                                        </div>
                                        <div class="cell">
                                            <span class="datum time">{{
                                                prettyTimeSring(pedido.t_req)
                                            }}</span>
                                        </div>
                                        <div
                                            class="cell-full-wide lineas-row-container"
                                            *ngIf="pedido.expanded">
                                            <app-loader
                                                [mini]="true"
                                                *ngIf="
                                                    pedido.lineas_venta === null
                                                "></app-loader>
                                            <div
                                                class="table-row lineas-row"
                                                (click)="
                                                    onPedidoToggled(pedido)
                                                "
                                                *ngFor="
                                                    let linea of pedido.lineas_venta
                                                ">
                                                <div class="cell">
                                                    {{ linea.linea_id }}
                                                </div>
                                                <div class="cell">
                                                    <span class="datum time">{{
                                                        linea.ctd
                                                    }}</span>
                                                </div>
                                                <div class="cell">
                                                    <span
                                                        class="datum positive"
                                                        [class.negative]="
                                                            linea.offset > 0
                                                        "
                                                        >{{
                                                            prettyTimeSring(
                                                                linea.offset
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="cell">
                                                    <span class="datum time">{{
                                                        prettyTimeSring(
                                                            linea.t_est
                                                        )
                                                    }}</span>
                                                </div>
                                                <div class="cell">
                                                    <span class="datum time">{{
                                                        prettyTimeSring(
                                                            linea.t_req
                                                        )
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
