import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import OFService from 'src/app/services/main/of.service';

declare let $: any;

@Component({
    selector: 'app-seguimiento-creating-modal',
    templateUrl: './seguimiento-creating-modal.component.html',
    styleUrls: ['./seguimiento-creating-modal.component.scss'],
})
export class SeguimientoCreatingModalComponent {
    showing = false;

    constructor(
        private ofService: OFService,
        private formBuilder: FormBuilder
    ) {}

    @ViewChild('seguimientoCreatingModal')
    seguimientoCreatingModal!: ElementRef;

    seguimientoCreatingForm = this.formBuilder.group({
        client_email: '',
    });

    seguimientoCreatingError: string | null = null;

    seguimientoCreationModal: any;
    ngAfterViewInit() {
        this.seguimientoCreationModal = $(
            this.seguimientoCreatingModal.nativeElement
        )
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    onSubmit() {
        if (!this.creating_seguimiento_of_uid) return;
        this.seguimientoCreatingError = null;

        const { client_email } = this.seguimientoCreatingForm.value;
        if (!client_email) return;
        this.ofService
            .startSeguimiento(this.creating_seguimiento_of_uid, client_email)
            .then(() => this.seguimientoCreationModal.modal('hide'))
            .catch((err: any) => {
                this.seguimientoCreatingError = err.error;
            });
    }

    creating_seguimiento_of_uid: string | null = null;
    openModal(of_uid: string, suggested_email: string) {
        this.creating_seguimiento_of_uid = of_uid;
        this.seguimientoCreationModal.modal('show');

        this.seguimientoCreatingForm.reset();
        this.seguimientoCreatingForm.patchValue({
            client_email: suggested_email,
        });
    }
}
