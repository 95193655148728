<app-loader *ngIf="fetchingSeguimiento"></app-loader>
<div *ngIf="!fetchingSeguimiento">
    <div *ngIf="errorFetchingSeguimiento" class="alert alert-danger mx-3 mt-2">
        <span>Ha ocurrido un error al obtener el seguimiento del pedido.</span>
        <p>{{ errorFetchingSeguimiento }}</p>
    </div>
    <div class="seguimiento-view" *ngIf="!errorFetchingSeguimiento">
        <div class="row">
            <div class="col-12 col-xl-4 of-left-card">
                <div class="card">
                    <div class="card-body">
                        <table class="seguimientoData-details-table">
                            <tbody>
                                <tr>
                                    <td>ID</td>
                                    <td>
                                        {{ seguimientoData.linea_composed_id }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email Notificado</td>
                                    <td>
                                        {{
                                            seguimientoData.seguimiento_sent_client_email
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pedido</td>
                                    <td>{{ seguimientoData.pedido_id }}</td>
                                </tr>
                                <tr>
                                    <td>Linea</td>
                                    <td>{{ seguimientoData.linea }}</td>
                                </tr>
                                <tr>
                                    <td>Sublinea</td>
                                    <td>{{ seguimientoData.sublinea }}</td>
                                </tr>
                                <tr>
                                    <td>Cliente</td>
                                    <td>{{ seguimientoData.client.name }}</td>
                                </tr>
                                <tr>
                                    <td>Unidades</td>
                                    <td>{{ seguimientoData.ctd }}</td>
                                </tr>
                                <tr>
                                    <td>Artículo</td>
                                    <td>{{ seguimientoData.id_articulo }}</td>
                                </tr>
                                <tr>
                                    <td>Descripción</td>
                                    <td>
                                        {{ seguimientoData.descrip_articulo }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl">
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="estado-flex mt">
                            <div class="estado-row">
                                <div class="estado-content-1">
                                    <div class="estado-content-2">
                                        <div class="estado-content-3">
                                            <div
                                                class="estado-content-3 estado-pending">
                                                <svg
                                                    width="12"
                                                    height="10"
                                                    viewBox="0 0 12 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M11.7366 1.6764L4.56108 9.48977L0.25415 4.66601L1.74603 3.33398L4.58196 6.51023L10.2636 0.323593L11.7366 1.6764Z"
                                                        fill="white"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="estado-linea"></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="estado-title">
                                        Pedido creado
                                    </div>
                                    <div class="estado-content">
                                        <div class="estado-fecha">
                                            {{
                                                seguimientoData.creation_date
                                                    | localDate
                                                        : 'dd/MM/yyyy HH:mm'
                                            }}
                                        </div>
                                        <div class="estado-desc">
                                            <span
                                                >El pedido se ha creado
                                                correctamente.</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="estado-row">
                                <div class="estado-content-1">
                                    <div class="estado-content-2">
                                        <div class="estado-content-3">
                                            <div
                                                class="estado-content-3 estado-pending"
                                                [class.estado-completed]="
                                                    !seguimientoData.completed
                                                ">
                                                <svg
                                                    width="12"
                                                    height="10"
                                                    viewBox="0 0 12 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M11.7366 1.6764L4.56108 9.48977L0.25415 4.66601L1.74603 3.33398L4.58196 6.51023L10.2636 0.323593L11.7366 1.6764Z"
                                                        fill="white"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            class="estado-linea"
                                            [class.estado-linea-incompleted]="
                                                !seguimientoData.completed
                                            "></div>
                                    </div>
                                </div>
                                <div>
                                    <div class="estado-title">
                                        Pedido en preparación
                                    </div>
                                    <div class="estado-content">
                                        <div class="estado-desc">
                                            <span
                                                >El pedido se encuentra en
                                                producción.</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="estado-row">
                                <div class="estado-content-1">
                                    <div class="estado-content-2">
                                        <div class="estado-content-3">
                                            <div
                                                class="estado-content-3"
                                                [class.estado-completed]="
                                                    seguimientoData.completed
                                                ">
                                                <svg
                                                    width="12"
                                                    height="10"
                                                    viewBox="0 0 12 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M11.7366 1.6764L4.56108 9.48977L0.25415 4.66601L1.74603 3.33398L4.58196 6.51023L10.2636 0.323593L11.7366 1.6764Z"
                                                        fill="white"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="estado-title"
                                        [class.estado-title-completed]="
                                            seguimientoData.completed
                                        ">
                                        Pedido completado
                                    </div>
                                    <div class="estado-content">
                                        <div
                                            class="estado-fecha"
                                            *ngIf="seguimientoData.completed">
                                            {{
                                                seguimientoData.completion_date
                                                    | localDate
                                                        : 'dd/MM/yyyy HH:mm'
                                            }}
                                        </div>
                                        <div class="estado-desc">
                                            <span
                                                >El pedido se ha completado
                                                correctamente y será enviado en
                                                breve.</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
