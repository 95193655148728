<div class="auth-form-container">
    <div class="auth-form auth-form-recover">
        <img src="assets/logos/eremu-auth.png" alt="logo" class="logo" />
        <form id="login-form" (ngSubmit)="doRcover()" ngNativeValidate>
            <h4 class="auth-title">Resetear Contraseña</h4>
            <p class="auth-sub">Ingrese su correo electrónico y contraseña</p>

            <div class="form-label form-label-spaced">
                <label for="password">Nueva Contraseña</label>
            </div>
            <div class="form-field-input">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    id="new-password"
                    name="new-password"
                    autocomplete="new-password"
                    placeholder="Nueva contraseña"
                    [(ngModel)]="password"
                    [disabled]="recovering"
                    required />
                <button
                    type="button"
                    class="show-password"
                    [class.showing-password]="showPassword"
                    (click)="toggleShowPassword()">
                    <i class="fa-solid fa-eye"></i>
                    <i class="fa-solid fa-eye-slash"></i>
                </button>
            </div>
            <div class="form-label form-label-spaced">
                <label for="password">Repetir Contraseña</label>
            </div>
            <div class="form-field-input">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    id="password-repeat"
                    name="password-repeat"
                    autocomplete="password-repeat"
                    placeholder="Repetir contraseña"
                    [(ngModel)]="passwordConfirm"
                    [disabled]="recovering"
                    required />
                <button
                    type="button"
                    class="show-password"
                    [class.showing-password]="showPassword"
                    (click)="toggleShowPassword()">
                    <i class="fa-solid fa-eye"></i>
                    <i class="fa-solid fa-eye-slash"></i>
                </button>
            </div>
            <button
                type="submit"
                class="btn-auth btn-auth-recover"
                [class.btn-loading]="recovering"
                [disabled]="recovering">
                Cambiar Contraseña
            </button>
        </form>
        <div class="alert alert-danger mt-2 mb-0" *ngIf="recoveringError">
            <i class="fa-solid fa-exclamation-triangle mr-2"></i>
            <span [innerHtml]="recoveringError"></span>
        </div>
    </div>
</div>
