import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Empleado } from 'src/app/models/Empleado';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';

declare let $: any;

@Component({
    selector: 'app-empleado-editing-modal',
    templateUrl: './empleado-editing-modal.component.html',
    styleUrls: ['./empleado-editing-modal.component.scss'],
})
export class EmpleadosEditingModalComponent {
    showing = false;

    empleadoEditionForm = this.formBuilder.group({
        uid: '',
        first_name: '',
        last_name: '',
    });

    @Output()
    onEmpleadoUpdated: EventEmitter<Empleado> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private empleadoManagerService: EmpleadoManagerService
    ) {}

    empleadoEditionModal: any;
    ngOnInit() {
        this.empleadoEditionModal = $('#empleado-edition-modal')
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    editionError: string | null = null;
    onSubmit(): void {
        // Process checkout data here
        console.warn('Submited form:', this.empleadoEditionForm.value);

        this.editionError = null;

        const fields = this.empleadoEditionForm.value;

        this.empleadoEditionForm.disable();

        this.empleadoManagerService
            .updateEmpleado(fields as Empleado)
            .then(([success, res]) => {
                this.empleadoEditionForm.enable();
                if (!success) {
                    this.editionError = res as string;
                    return;
                }

                this.empleadoEditionForm.reset();
                this.empleadoEditionModal.modal('hide');
                this.editionError = null;

                this.onEmpleadoUpdated.emit(res as Empleado);
            });
    }

    openEmpleadoEditionModal(empleado: Empleado) {
        this.empleadoEditionModal.modal('show');
        this.empleadoEditionForm.reset();

        this.empleadoEditionForm.patchValue(empleado);

        this.editionError = null;
    }
}
