<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg"></div>
    <div
        class="col-12 col-md-6 col-lg-auto d-flex align-items-center justify-content-end">
        <button
            class="btn btn-refresh"
            [class.i-spin]="fetchingInformeErrores"
            (click)="getImportePedidosCliente()"
            [disabled]="fetchingInformeErrores">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12" *ngIf="fetchingInformeErrores">
        <app-loader></app-loader>
    </div>
    <div class="col-12" *ngIf="!fetchingInformeErrores && informeErrores">
        <div class="card mb-2" *ngIf="!informeErrores.length">
            <div class="card-body">No se ha reportado ningún error.</div>
        </div>
        <div *ngIf="informeErrores.length">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">Informe de errores</div>
                        </div>
                        <div class="card-body">
                            <div
                                class="alert"
                                *ngFor="let error of informeErrores"
                                [class.alert-danger]="error.type === 'danger'"
                                [class.alert-warning]="
                                    error.type === 'warning'
                                ">
                                <span [innerHTML]="error.message"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
