import { Component, OnInit } from '@angular/core';

import RegistrosService from '../../../../services/admin/registros.service';
import Log from '../../../../models/Log';

@Component({
    selector: 'app-registros',
    templateUrl: './registros.component.html',
    styleUrls: ['./registros.component.scss'],
})
export class RegistrosViewComponent implements OnInit {
    constructor(private registrosService: RegistrosService) {}

    showingLogTypes: any = {
        worker: true,
        user: true,
        system: false,
    };

    toggleLogShow(group: string) {
        this.showingLogTypes[group] = !this.showingLogTypes[group];

        this.refreshList();
    }

    updatingList = false;
    itemList: Log[] = [];
    refreshList(): Promise<boolean> {
        this.updatingList = true;
        return new Promise(resolve => {
            const showingLogTypes = Object.keys(this.showingLogTypes).filter(
                (key: string) => this.showingLogTypes[key]
            );
            this.registrosService
                .getRegistros(showingLogTypes) // 100 limit
                .then((registros: Log[] | null) => {
                    this.updatingList = false;
                    if (!registros) return resolve(false);
                    this.itemList = registros;
                    resolve(true);
                });
        });
    }

    ngOnInit(): void {
        this.refreshList();
    }

    getRegistroBody(registro: Log) {
        const { code } = registro;

        let message = registro.message as any;

        try {
            message = JSON.parse(message.replaceAll("'", '"'));
        } catch (e) {}

        switch (code) {
            case 'of_created': {
                const { of_uid } = message;
                return `Ha creado la orden de fabricación <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small>`; // TODO: link
            }
            case 'of_phase_enabled': {
                const { of_uid } = message;
                return `Ha habilitado una fase de la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small>`;
            }
            case 'of_phase_disabled': {
                const { of_uid } = message;
                return `Ha deshabilitado una fase de la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small>`;
            }
            case 'of_phase_started': {
                const { of_uid } = message;
                return `Ha iniciado una fase de la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small> como iniciada`;
            }
            case 'of_phase_finished': {
                const { of_uid } = message;
                return `Ha finalizado una fase de la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small> como finalizada`;
            }
            case 'of_phase_paused': {
                const { of_uid } = message;
                return `Ha pausado una fase de la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small> como pausada`;
            }
            case 'of_completed': {
                const { of_uid } = message;
                return `Ha marcado la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small> como completa`;
            }
            case 'of_uncompleted': {
                const { of_uid } = message;
                return `Ha marcado la of <small><a target="_blank" href="/ordenes-fabricacion/${of_uid}">${of_uid}</a></small> como incompleta`;
            }
            default:
                break;
        }

        return registro.code;
    }
}
