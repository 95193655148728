<div class="card mt-4">
    <div class="card-header">
        <div class="card-title">Seguimientos Activos</div>
        <div class="card-header-actions small-card-filters">
            <input
                type="text"
                class="form-control"
                placeholder="Buscar por ID, cliente..."
                [(ngModel)]="seachFilter"
                (input)="changeTrigger()"
                (change)="refreshList()" />
        </div>
    </div>
    <div class="card-body p-0">
        <div
            class="custom-table table-no-border"
            [class.table-refreshing]="updatingList">
            <div class="table-body" [class.table-loading]="updatingList">
                <div class="table-row table-cols">
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['linea_composed_id'] === -1
                            "
                            [class.sorting_asc]="
                                sortings['linea_composed_id'] === 1
                            "
                            (click)="sortBy('linea_composed_id')"
                            >ID Línea</span
                        >
                    </div>
                    <div class="cell">
                        <span>Estaado Fases</span>
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['client__name'] === -1
                            "
                            [class.sorting_asc]="sortings['client__name'] === 1"
                            (click)="sortBy('client__name')"
                            >Cliente</span
                        >
                    </div>
                    <div class="cell">
                        <span>Correo Notificado</span>
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="sortings['plazo'] === -1"
                            [class.sorting_asc]="sortings['plazo'] === 1"
                            (click)="sortBy('plazo')"
                            >Plazo</span
                        >
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['seguimiento_started_at'] === -1
                            "
                            [class.sorting_asc]="
                                sortings['seguimiento_started_at'] === 1
                            "
                            (click)="sortBy('seguimiento_started_at')"
                            >Creación Seg.</span
                        >
                    </div>
                    <div class="cell"></div>
                </div>
                <div class="table-row" *ngFor="let _of of itemList">
                    <div class="cell">
                        <strong>{{ _of.linea_composed_id }}</strong>
                    </div>
                    <div class="cell">
                        <app-phase-container
                            [phaseList]="_of.phases"></app-phase-container>
                    </div>
                    <div class="cell">
                        <strong>{{ _of.client.name }}</strong>
                    </div>
                    <div class="cell">
                        <strong>{{ _of.seguimiento_sent_client_email }}</strong>
                    </div>
                    <div class="cell date-container">
                        <span class="date">{{
                            _of.plazo | localDate: 'd MMM, y'
                        }}</span>
                        <!--<br>
                        <span class="hour">{{_of.plazo | localDate:'HH:mm'}}</span>-->
                    </div>
                    <div class="cell date-container">
                        <span class="date">{{
                            _of.seguimiento_started_at | localDate: 'd MMM, y'
                        }}</span>
                        <br />
                        <span class="hour">{{
                            _of.plazo | localDate: 'HH:mm'
                        }}</span>
                    </div>
                    <div class="cell">
                        <a
                            [routerLink]="
                                '/ordenes-fabricacion/' + _of.uid + '/'
                            "
                            class="btn btn-small btn-primary"
                            title="Ver detalle">
                            <i class="fa-solid fa-eye"></i>
                        </a>
                        <a
                            target="_blank"
                            [routerLink]="
                                '/public/s/' + _of.seguimiento_public_uid + '/'
                            "
                            class="btn btn-small btn-success ml-2"
                            title="Abrir seguimiento">
                            <i class="fa-solid fa-link"></i>
                        </a>
                        <button
                            class="btn btn-small btn-danger ml-2"
                            title="Eliminar seguimiento"
                            (click)="deleteItem(_of.uid)">
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </div>
                    <div class="cell-full-wide">
                        <p>
                            <strong>{{ _of.id_articulo }}</strong> |
                            {{ _of.descrip_articulo }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-confirmation-modal #confirmationModal></app-confirmation-modal>
