import { clone, distanceBetween, getClosestFloorKey } from 'src/utils';
import { PHASE_COLORS } from 'src/consts';

import { AfterViewInit, Component, Input } from '@angular/core';
import * as d3 from 'd3';
import RazonesParada from 'src/app/constants/RazonesParada';

const moveToFront = (items: any) => {
    items.nodes().forEach((node: any) => node.parentNode.appendChild(node));
};

class GanttChart {
    width: any = 'fit';
    height: any = 500;
    of: any = '';
    startDate: Date = new Date();
    endDate: Date = new Date();
    shifts: any[] = [];
    tasksPadding = 8;
    enableHoverLines = false;
    __taskElements: any[] = [];
    __zoomBrushHeight = 20;
    __cachedDomain: any[] = [];
    __cachedRange: any[] = [];
    __eventListeners: any = {};
    hoverStrokeStyle: any = {
        stroke: 'black',
        'stroke-width': '2px',
        'stroke-dasharray': '5px',
    };
    __margin = {
        top: 5,
        right: 15,
    };
    clipId: any;
    xScale: any;
    yScale: any;

    data: any;

    shiftLimiters: any;
    __obj_container: any;
    __realWidth: any;
    __realHeight: any;
    __contentHeight: any;
    __taskHeight: any;
    bottomScaleHeight: any;
    __obj_machione_container: any;
    __obj_d3: any;
    __obj: any;

    __cachedYScaleRange: any;
    xGridScale: any;
    xScaleRef: any;

    __zoomBrush: any;
    __zoomBrushNode: any;

    __zoomBrushTicks: any;
    __zoomBrushSidesHandlers: any;

    __xTickIntervals: any;
    __xScaleAxis: any;

    __xGridAxis: any;

    __zoomBrushController: any;

    __xGridContainer: any;
    __yGridContainer: any;
    __yGridAxis: any;

    shiftContainers: any;

    __definitions: any;
    __taskContainer: any;
    __bottomAxis: any;

    brushEventException = false;

    constructor(elementID: string, config: any = {}) {
        if (!elementID) return;

        for (const k in config) {
            if (k == 'data') this.data = clone(config[k]);
            else {
                // @ts-ignore
                this[k] = config[k];
            }
        }

        this.__obj_container = document.getElementById(elementID);
        this.__obj_container.style.display = 'flex';
        this.__obj_container.style.overflow = 'hidden';
        //this.__obj_container.style.paddingRight = '20px';
        this.__obj_container.style.paddingBottom = '12px';

        if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
        this.__realWidth = this.width - this.__margin.right;
        this.__realHeight = this.height - this.__margin.top;

        this.__contentHeight =
            this.__realHeight -
            (this.__zoomBrushHeight + 20) /*bottom axis height*/;

        this.__taskHeight = this.__contentHeight / this.data.OFs.length;

        this.bottomScaleHeight = 20;

        this.__obj_machione_container = document.createElement('div');
        this.__obj_machione_container.style.overflow = 'hidden';
        this.__obj_machione_container.classList.add('ofs-container');
        let OFsHTML = '';
        this.data.OFs.forEach((value: any) => {
            OFsHTML += `<div class="of" style="height: ${this.__taskHeight}px;"><div><span>${value.name}</span></div></div>`;
        });
        this.__obj_machione_container.innerHTML = OFsHTML;
        this.__obj_machione_container.style.marginTop =
            this.__margin.top + this.__zoomBrushHeight + 'px';
        this.__obj_machione_container.style.height =
            'calc( 100% - ' +
            (this.__margin.top +
                this.__zoomBrushHeight +
                this.bottomScaleHeight) +
            'px )';
        this.__obj_container.append(this.__obj_machione_container);
        this.__realWidth -= this.__obj_machione_container.clientWidth;

        this.__obj = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'svg'
        );
        this.__obj_container.append(this.__obj);
        this.__obj_d3 = d3.select(this.__obj);

        let selectRect: any;
        let hoveringContent: any = false;
        this.__obj_d3
            .attr('width', this.__realWidth + 'px')
            .attr('height', this.__obj_container.clientHeight + 'px')
            .attr('class', 'gantt-chart')
            .style('margin-top', this.__margin.top + 'px')
            .on('contextmenu', (event: any) => event.preventDefault())

            // use Xgrid as mouse events handling over chart
            .on('mouseenter', (event: any) => {
                // if ! is top rect
                if (!event.composedPath().includes(this.__zoomBrushNode)) {
                    hoveringContent = true;
                    this.__callEvent(
                        'mouseenter',
                        this.xScale.invert(
                            this.getSVGPosFromScreenPos(event.x) -
                                this.__obj_machione_container.clientWidth -
                                7
                        )
                    );
                } else if (hoveringContent) {
                    hoveringContent = false;
                    this.__callEvent('mouseleave');
                }
            })
            .on('mousemove', (event: any) => {
                // if ! is top rect
                if (!event.composedPath().includes(this.__zoomBrushNode)) {
                    hoveringContent = true;
                    this.__callEvent(
                        'mousemove',
                        this.xScale.invert(
                            this.getSVGPosFromScreenPos(event.x) -
                                this.__obj_machione_container.clientWidth -
                                7
                        )
                    );
                } else if (hoveringContent) {
                    hoveringContent = false;
                    this.__callEvent('mouseleave');
                }
            })
            .on('mouseleave', () => {
                if (hoveringContent) {
                    this.__callEvent('mouseleave');
                    hoveringContent = false;
                }
            })
            .on('mousedown', (event: any) => {
                if (event.button == 2) {
                    this.lockHoverLine = true;
                    this.hideHoverLine();
                    let origin_x = event.clientX - this.getLeftOffset();
                    selectRect.attr('width', 0).attr('display', '');
                    d3.select('body').classed('noselect', true);
                    origin_x = Math.max(
                        0,
                        Math.min(this.__realWidth, origin_x)
                    );
                    d3.select(window)
                        .on(
                            'mousemove.zoomRect',
                            (event: any) => {
                                let x = event.clientX - this.getLeftOffset();
                                if (x > this.__realWidth) x = this.__realWidth;
                                x = Math.max(0, Math.min(this.__realWidth, x));
                                selectRect
                                    .attr('x', Math.min(origin_x, x))
                                    .attr('y', this.__zoomBrushHeight)
                                    .attr('width', Math.abs(x - origin_x))
                                    .attr('height', this.__contentHeight);
                            },
                            true
                        )
                        .on(
                            'mouseup.zoomRect',
                            (event: any) => {
                                this.lockHoverLine = false;
                                event.preventDefault();
                                d3.select(window)
                                    .on('mousemove.zoomRect', null)
                                    .on('mouseup.zoomRect', null);
                                d3.select('body').classed('noselect', false);
                                let x = event.clientX - this.getLeftOffset();
                                x = Math.max(0, Math.min(this.__realWidth, x));
                                if (x !== origin_x) {
                                    let newDomain;
                                    if (origin_x < x) {
                                        newDomain = [
                                            this.xScale.invert(origin_x),
                                            this.xScale.invert(x),
                                        ];
                                    } else {
                                        newDomain = [
                                            this.xScale.invert(x),
                                            this.xScale.invert(origin_x),
                                        ];
                                    }

                                    this.__updateTopDomain();
                                    this.__zoomBrush.call(
                                        this.__zoomBrushController.move,
                                        [
                                            this.xScaleRef(newDomain[0]),
                                            this.xScaleRef(newDomain[1]),
                                        ]
                                    );
                                    this.setDomain(newDomain);
                                }
                                selectRect.attr('display', 'none');
                            },
                            true
                        );
                }
                event.stopPropagation();
            });
        /*.on('wheel', (event: any) => this.onWheel(event));

        d3.select(this.__obj_machione_container)
            .on('wheel', (event: any) => this.onWheel(event))*/

        this.xScale = d3
            .scaleTime()
            .domain([this.startDate, this.endDate])
            .range([0, this.__realWidth]);
        this.__cachedRange = this.xScale.range();
        this.__cachedDomain = this.xScale.domain();

        this.yScale = d3
            .scaleOrdinal()
            .domain(this.data.OFs.map((d: any) => d.id))
            .range(
                this.data.OFs.map((_: any, k: any) => k * this.__taskHeight)
            );
        this.__cachedYScaleRange = this.yScale.range();

        this.__taskHeight = this.__taskHeight - this.tasksPadding * 2; // apply padding after setting yDomain

        this.xScaleRef = d3
            .scaleTime()
            .domain(this.xScale.domain())
            .range([0, this.__realWidth]);

        this.xGridScale = d3
            .scaleTime()
            .domain(this.xScale.domain())
            .range(this.xScale.range());

        this.__zoomBrush = this.__obj_d3
            .append('g')
            .attr('class', 'zoom-brush')
            .attr('transform', `translate(0,0)`);
        this.__zoomBrushNode = this.__zoomBrush.node();

        this.__zoomBrush
            .append('rect')
            .attr('class', 'bg')
            .attr('width', this.__realWidth)
            .attr('height', this.__zoomBrushHeight);

        this.__xScaleAxis = d3.axisBottom(this.xScale).ticks(this.getXTicks(1));

        const xScalOffsetX = this.__zoomBrushHeight + this.__contentHeight;

        this.__zoomBrushController = d3
            .brushX()
            .extent([
                [1, 1],
                [this.__realWidth - 2, this.__zoomBrushHeight - 1],
            ])
            .on('start', () =>
                this.__zoomBrush.node().classList.add('grabbing')
            )
            .on('end', () =>
                this.__zoomBrush.node().classList.remove('grabbing')
            )
            .on('brush end', (event: any) => {
                if (
                    (event.selection && event.sourceEvent) ||
                    this.brushEventException
                )
                    this.setDomain(
                        event.selection.map(this.xScaleRef.invert, this.xScale)
                    );
            });

        this.__xGridAxis = d3
            .axisTop(this.xGridScale)
            .ticks(this.getXTicks(0))
            .tickSize(this.__contentHeight)
            .tickFormat(() => '');

        this.__xGridContainer = this.__obj_d3
            .append('g')
            .attr('class', 'x grid')
            .attr('transform', `translate(0,${xScalOffsetX})`)
            .call(this.__xGridAxis);

        this.__yGridAxis = d3
            .axisRight(this.yScale)
            .tickSize(this.__realWidth)
            .tickFormat(() => '');

        this.__yGridContainer = this.__obj_d3
            .append('g')
            .attr('class', 'y grid')
            .attr('transform', `translate(0,${this.__zoomBrushHeight})`)
            .call(this.__yGridAxis)
            .call((el: any) =>
                el.selectAll('path, g.tick:first-of-type').remove()
            );

        this.clipId = `taskClip${Math.random().toString(36).substr(2, 9)}`;
        this.shiftContainers = this.__obj_d3
            .append('g')
            .attr('transform', `translate(0, ${this.__zoomBrushHeight})`)
            .attr('clip-path', `url(#${this.clipId})`);

        this.shifts = [];
        this.data.OFs.forEach((mach: any) => {
            if ('shifts' in mach)
                mach.shifts.forEach((shift: any) => {
                    this.shifts.push({
                        ...shift,
                        of: mach.id,
                        h: this.__taskHeight + this.tasksPadding * 2,
                    });
                });
        });

        this.shiftLimiters = this.shiftContainers
            .selectAll('.delimiter')
            .data(this.shifts)
            .enter()
            .append('g')
            .attr('class', 'delimiter')
            .attr('transform', (d: any) => {
                d.x = this.xScale(d.min);
                d.y = this.yScale(d.of) - this.scrollTop;
                return `translate(${d.x}, ${d.y})`;
            });

        this.shiftLimiters
            .append('rect')
            .attr('width', (d: any) => {
                d.wide = this.xScale(d.max) - d.x;
                return d.wide;
            })
            .attr('height', (d: any) => d.h);

        this.shiftLimiters
            .append('line')
            .attr('class', 'end-stroke')
            .attr('x1', (d: any) => d.wide)
            .attr('x2', (d: any) => d.wide)
            .attr('y2', (d: any) => d.h);

        this.shiftLimiters.append('line').attr('y2', (d: any) => d.h);

        this.__taskContainer = this.__obj_d3
            .append('g')
            .attr('transform', `translate(0, ${this.__zoomBrushHeight})`)
            .attr('class', 'task-container')
            .attr('clip-path', `url(#${this.clipId})`);

        this.__bottomAxis = this.__obj_d3
            .append('g')
            .attr('class', 'axis-bottom')
            .attr(
                'transform',
                'translate(0, ' +
                    (xScalOffsetX -
                        (this.__obj_machione_container.scrollHeight -
                            this.__obj_machione_container.clientHeight)) +
                    ')'
            );

        this.__bottomAxis
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', '101%')
            .attr('height', this.bottomScaleHeight + 1)
            .attr('fill', 'white');

        this.__bottomAxis.call(this.__xScaleAxis);
        //this.__bottomAxis.selectAll('.domain, line').remove(); descomentar para hacer aparcer líneas de dominio

        this.__definitions = this.__taskContainer.append('defs').html(`
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                            </linearGradient>`);

        const showAllButtonWidth = 110,
            showAllButtonHeight = 35;
        this.__obj_d3
            .append('g')
            .attr('class', 'show-all-button')
            .attr(
                'transform',
                `translate(${this.__realWidth - showAllButtonWidth},${
                    this.__zoomBrushHeight
                })`
            );
        /*.html(
                `<rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${
                        showAllButtonHeight / 2 - 10
                    }" width="20" height="20" xlink:href="/assets/images/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${
                        showAllButtonHeight / 2 + 4
                    }" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">Mostrar todo</text>`
            )
            .on('click', () => {
                this.__updateTopDomain();
                this.__zoomBrush.call(this.__zoomBrushController.move, [
                    this.xScaleRef(this.startDate),
                    this.xScaleRef(this.endDate),
                ]);
                this.setDomain([this.startDate.getTime(), this.endDate.getTime()]);
            });*/

        selectRect = this.__obj_d3
            .append('rect')
            .attr('class', 'zoom-rect')
            .attr('display', 'none');

        this.__zoomBrushSidesHandlers = this.__zoomBrush
            .selectAll('.handle--custom')
            .data([{ type: 'w' }, { type: 'e' }])
            .enter()
            .append('image')
            .attr('class', (d: any) => `handle--custom handle--${d.id}`)
            .attr('width', '30px')
            .attr('height', '30px')
            .attr(
                'x',
                (d: any) =>
                    this.xScaleRef(
                        (d.type == 'w' && this.__cachedDomain[0]) ||
                            this.__cachedDomain[1]
                    ) - 15
            )
            .attr('y', '-6px')
            .attr('xlink:href', 'assets/images/dragIconRoundBig.svg');

        this.__zoomBrush.call(this.__zoomBrushController);

        this.__zoomBrush.call(
            this.__zoomBrushController.move,
            this.xScale.range()
        ); //we set the brush with the default domain

        this.__zoomBrushTicks = this.__zoomBrush
            .append('g')
            .attr('class', 'x-top-axis')
            .attr('clip-path', `url(#${this.clipId})`)
            .append('g')
            .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
            .call(
                d3
                    .axisTop(this.xScaleRef)
                    .ticks(Math.floor(this.__realWidth / 70))
            )
            .call((d: any) => this.tickSizeFix(d));

        this.__zoomBrushTicks.selectAll('.domain, line').remove();

        this.__zoomBrushTicks
            .selectAll('g.tick text')
            .attr('style', (d: any) => {
                const checkDate = new Date(d.getTime());
                checkDate.setHours(0);
                checkDate.setMinutes(0);
                checkDate.setSeconds(0);
                return (
                    (checkDate.getTime() == d.getTime() &&
                        'font-weight: bold!important;') ||
                    null
                );
            });

        moveToFront(this.__zoomBrush);
        moveToFront(this.__zoomBrushSidesHandlers);

        this.__obj_d3
            .append('clipPath')
            .attr('id', this.clipId)
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.__realWidth)
            .attr('height', this.__contentHeight);

        if (this.enableHoverLines) {
            this.on('mouseenter', (hoverDate: any) =>
                this.showHoverLine(hoverDate)
            )
                .on('mousemove', (hoverDate: any) =>
                    this.showHoverLine(hoverDate)
                )
                .on('mouseleave', () => this.hideHoverLine());
        }

        //Base loaded, load tasks
        this.__loadDataTasks();
    }

    getLeftOffset() {
        return this.__obj_d3.node().getBoundingClientRect().left;
    }

    setDateRange(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
        const oldDom = this.xScale.domain();
        oldDom[0] = startDate < oldDom[0] ? oldDom[0] : startDate;
        oldDom[1] = endDate > oldDom[1] ? oldDom[1] : endDate;
        this.xScaleRef.domain([startDate, endDate]);
        this.__updateTopDomain();
        this.__zoomBrush.call(this.__zoomBrushController.move, [
            this.xScaleRef(oldDom[0]),
            this.xScaleRef(oldDom[1]),
        ]);
        this.setDomain(oldDom);
    }

    __updateTopDomain() {
        this.__zoomBrushTicks
            .call(
                d3
                    .axisTop(this.xScaleRef)
                    .ticks(Math.floor(this.__realWidth / 70))
            )
            .call((d: any) => this.tickSizeFix(d));

        this.__zoomBrushTicks.selectAll('.domain, line').remove();
    }
    setDomain(domain: number[]) {
        this.__cachedDomain = domain;
        this.xScale.domain(domain);
        this.xGridScale.domain(domain);

        this.__zoomBrushSidesHandlers.attr(
            'x',
            (d: any) =>
                this.xScaleRef(
                    (d.type == 'w' && this.__cachedDomain[0]) ||
                        this.__cachedDomain[1]
                ) - 15
        );

        //Checking x grid ticks to be updated or not
        const targetTickIntervals = this.calcXTickInterval();
        if (
            !this.__xTickIntervals ||
            this.__xTickIntervals[0] != targetTickIntervals[0]
        ) {
            this.__xTickIntervals = targetTickIntervals;
            this.__xScaleAxis.ticks(this.getXTicks(1));
            this.__xGridAxis.ticks(this.getXTicks(0));
        }
        this.__bottomAxis.call(this.__xScaleAxis);
        this.__xGridContainer.call(this.__xGridAxis);
        this.__updateTasks();
    }
    calcXTickInterval() {
        const localDomain = this.__cachedDomain,
            timerXpixelGroup =
                (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
        if (timerXpixelGroup < 4000) {
            return ['2.5m', '5m'];
        } else if (timerXpixelGroup < 9000) {
            return ['5m', '10m'];
        } else if (timerXpixelGroup < 35192) {
            return ['15m', '30m'];
        } else if (timerXpixelGroup < 61314) {
            return ['30m', '1h'];
        } else if (timerXpixelGroup < 132061) {
            return ['1h', '2h'];
        } else if (timerXpixelGroup < 207524) {
            return ['2h', '4h'];
        } else if (timerXpixelGroup < 731053) {
            return ['4h', '8h'];
        } else if (timerXpixelGroup < 339586) {
            return ['8h', '12h'];
        } else if (timerXpixelGroup < 1419658) {
            return ['12h', '1d'];
        } else {
            return ['1d', '2d'];
        } // no soporta más de 2 meses, pero se puede adaptar
    }
    __d3Times: any = {
        '2.5m': d3.timeMinute.every(2.5),
        '5m': d3.timeMinute.every(5),
        '10m': d3.timeMinute.every(10),
        '15m': d3.timeMinute.every(15),
        '30m': d3.timeMinute.every(30),
        '1h': d3.timeHour.every(1),
        '2h': d3.timeHour.every(2),
        '4h': d3.timeHour.every(4),
        '8h': d3.timeHour.every(8),
        '1d': d3.timeDay.every(1),
        '2d': d3.timeDay.every(2),
    };
    getXTicks(type: any) {
        if (!this.__xTickIntervals)
            this.__xTickIntervals = this.calcXTickInterval();
        return this.__d3Times[this.__xTickIntervals[type]];
    }
    tickSizeFix(d: any, checkStart = false) {
        d.selectAll('g').each((d: any, i: any, nodes: any) => {
            const el = nodes[i];
            const x = parseFloat(
                el
                    .getAttribute('transform')
                    .replace('translate(', '')
                    .replace(')', '')
                    .split(',')[0]
            );
            if (
                (checkStart && x < 0) ||
                this.__realWidth < x + el.getBBox().width
            )
                el.remove();
        });
    }
    on(event: any, callback: any) {
        if (!this.__eventListeners[event]) this.__eventListeners[event] = [];
        this.__eventListeners[event].push(callback);
        return this;
    }
    __callEvent(event: any, ...args: any) {
        if (this.__eventListeners[event])
            this.__eventListeners[event].forEach((func: any) =>
                func.apply(this, args)
            );
    }
    scrollTop = 0;
    async onWheel(event: any) {
        event.preventDefault();
        this.__obj_machione_container.scrollTop += event.deltaY;
        this.scrollTop = this.__obj_machione_container.scrollTop;

        const xScalOffsetX = this.__zoomBrushHeight + this.__contentHeight;
        this.__taskContainer.attr(
            'transform',
            'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')'
        );
        this.__yGridContainer.attr(
            'transform',
            'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')'
        );
        this.__bottomAxis.attr(
            'transform',
            'translate(0, ' +
                (xScalOffsetX -
                    (this.__obj_machione_container.scrollHeight -
                        this.__obj_machione_container.clientHeight)) +
                ')'
        );
        this.shiftLimiters.attr('transform', (d: any) => {
            d.x = this.xScale(d.min);
            d.y = this.yScale(d.of) - this.scrollTop;
            return `translate(${d.x}, ${d.y})`;
        });
    }
    __imageFiltersCache: any = { length: 0 };
    __getFillFilter(d: any) {
        if (d.filter) {
            const iFilter =
                d.filter.src + ((d.filter.animated && 'animated') || '');
            if (!this.__imageFiltersCache[iFilter]) {
                const uid = 'task-filter-' + this.__imageFiltersCache.length;
                this.__imageFiltersCache.length++;
                this.__imageFiltersCache[d.filter.src] = {
                    uid: '#' + uid,
                    element: this.__definitions
                        .append('pattern')
                        .attr('id', uid)
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr(
                            'class',
                            (d.filter.animated && 'filter-animated') ||
                                undefined
                        )
                        .attr(
                            'width',
                            (this.__taskHeight / d.filter.size[1]) *
                                d.filter.size[0] +
                                'px'
                        )
                        .attr('height', this.__taskHeight + 'px')
                        .attr('patternUnits', 'userSpaceOnUse')
                        .html(
                            `<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`
                        ),
                };
            }
            return this.__imageFiltersCache[d.filter.src].uid;
        }
        return '#gradient';
    }
    __displayTasks: any;
    updateTasks(newData: any) {
        this.data.tasks = clone(newData);
        this.__displayTasks.remove();
        this.__loadDataTasks();
    }
    updateTask(taskId: any, newData: any) {
        for (const taskKey in this.data.tasks)
            if (taskId == this.data.tasks[taskKey].id)
                this.data.tasks[taskKey] = clone(newData);
        this.__displayTasks.remove();
        this.__loadDataTasks();
    }

    __dragInterval: any;
    __dragIntervalFunc: any;

    __displayTasksRects: any;
    __displayTasksRectsGradient: any;
    async __loadDataTasks() {
        for (const k in this.data.tasks) {
            this.data.tasks[k].startDate = new Date(
                this.data.tasks[k].startDate
            );
            this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
        }

        this.__displayTasks = this.__taskContainer
            .selectAll('rect')
            .data(this.data.tasks)
            .enter()
            .append('g')
            .attr('transform', (d: any) => {
                d.y = this.yScale(d.of) + this.tasksPadding;
                return this.__getTaskTransform(d);
            })
            .each((d: any, i: any, nodes: any) => (d.element = nodes[i]));
        this.__displayTasksRects = this.__displayTasks
            .append('rect')
            .attr('fill', (d: any) => d.backgroundColor)
            .attr('rx', 2)
            .attr('width', (d: any) => this.__getTaskWide(d))
            .attr('height', this.__taskHeight);

        const automaticScrollMargin = this.__realWidth * 0.1;
        let mousePressed: boolean, draggingElements: any;
        this.__displayTasksRectsGradient = this.__displayTasks
            .append('rect')
            .attr('fill', (d: any) => `url(${this.__getFillFilter(d)})`)
            .attr('rx', 2)
            .attr('x', (d: any) => (d.wide > 0.3 && 0.4) || 0)
            .attr('y', '.2')
            .attr('width', (d: any) => (d.wide > 0.5 && d.wide - 0.4) || d.wide)
            .attr('height', this.__taskHeight - 0.4)
            .on('click', (event: any, d: any) => {
                if (event.ctrlKey) this.__callEvent('task-ctrl-clicked', d.id);
                else if (d.click) d.click(d);
            });

        this.__displayTasks.each((d: any, i: any, nodes: any) => {
            const task = nodes[i];
            const textWide = d.phase.length * 10;
            if (d.wide > textWide && d.isOfVisible != true)
                this.__showTaskOf(task, d);
            if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true)
                this.__hideTaskOp(task, d);
        });
        let filterAnimated = d3.selectAll('pattern.filter-animated'),
            x = 0;
        setInterval(() => {
            x += 0.5;
            filterAnimated.each((d: any, i: any, nodes: any) =>
                nodes[i].setAttribute('x', x)
            );
        }, 50);
    }
    getChartSVGOffset() {
        return this.__obj.getBoundingClientRect();
    }
    getSVGPosFromScreenPos(x: number, y: number | null = null): any {
        const svgBCR = this.getChartSVGOffset();
        return !isNaN(x)
            ? y && !isNaN(y)
                ? {
                      top: x - svgBCR.top,
                      left: y - svgBCR.left,
                  }
                : x
            : y && !isNaN(y)
            ? y
            : false;
    }
    getPriorizedTasks(taskList: any[]) {
        let targetTask,
            targetTaskMachiIndex = -1;
        for (const task of taskList) {
            const i = this.data.OFs.findIndex((m: any) => m.id == task.of);
            if (!targetTask || i < targetTaskMachiIndex) {
                targetTaskMachiIndex = i;
                targetTask = task;
            }
        }
        return targetTask;
    }
    __getTaskTransform(d: any) {
        d.x = this.xScale(d.startDate);
        d.isVisible = this.__isVisible(d);
        return this.__getTranslation(d);
    }
    __getTranslation = (d: any) => 'translate(' + d.x + ', ' + d.y + ')';
    __getTaskWide(d: any) {
        if (d.isVisible) {
            d.wide = this.xScale(d.endDate) - d.x;
        } else {
            d.wide = 0;
        }
        return d.wide;
    }
    __isVisible(d: any) {
        return (
            d.startDate.getTime() < this.__cachedDomain[1].getTime() &&
            d.endDate.getTime() > this.__cachedDomain[0].getTime()
        );
    }
    __showTaskOf(taskElement: any, d: any) {
        d.isOfVisible = true;
        d3.select(taskElement)
            .append('text')
            .attr('x', 5)
            .attr('y', 15)
            .text((d: any) => d.phase);
    }
    __hideTaskOp(taskElement: any, d: any) {
        delete d.isOfVisible;
        taskElement.children[taskElement.children.length - 1].remove();
    }
    __hoverLine: any;
    __setupHoverLine() {
        this.__hoverLine = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'line'
        );
        this.__hoverLine.classList.add('hover-line');
        this.__hoverLine.setAttribute('y0', '0');
        this.__hoverLine.setAttribute(
            'y1',
            this.__obj_machione_container.scrollHeight
        );
        for (const k in this.hoverStrokeStyle)
            this.__hoverLine.style[k] = this.hoverStrokeStyle[k];
        this.__hoverLine.style.pointerEvents = 'none';
        this.__yGridContainer.node().appendChild(this.__hoverLine);
    }
    lockHoverLine = false;
    showHoverLine(dateTime: any) {
        if (this.lockHoverLine) return;
        if (!this.__hoverLine) this.__setupHoverLine();
        if (this.__hoverLine.style.display == 'none')
            this.__hoverLine.style.display = '';
        const x = this.xScale(dateTime);
        this.__hoverLine.setAttribute('x1', x);
        this.__hoverLine.setAttribute('x2', x);
    }
    hideHoverLine() {
        if (this.__hoverLine && this.__hoverLine.style.display != 'none')
            this.__hoverLine.style.display = 'none';
    }
    async __updateTasks() {
        //check if scale was modifyed or not (to cahnge only the transforms)
        this.__displayTasks.each((d: any, i: any, nodes: any) => {
            const el = nodes[i];
            if (!d.dragging) {
                el.setAttribute('transform', this.__getTaskTransform(d));
                el.children[0].setAttribute('width', this.__getTaskWide(d));
                el.children[1].setAttribute(
                    'width',
                    (d.wide > 0.5 && d.wide - 0.4) || d.wide
                );

                const textWide = d.phase.length * 10;
                if (d.wide > textWide && d.isOfVisible != true)
                    this.__showTaskOf(el, d);
                if (
                    (!d.isVisible || d.wide <= textWide) &&
                    d.isOfVisible == true
                )
                    this.__hideTaskOp(el, d);
            }
        });

        this.shiftLimiters.attr('transform', (d: any) => {
            d.x = this.xScale(d.min);
            d.y = this.yScale(d.of) - this.scrollTop;
            return `translate(${d.x}, ${d.y})`;
        });

        this.shiftLimiters.selectAll('rect').attr('width', (d: any) => {
            d.wide = this.xScale(d.max) - d.x;
            return d.wide;
        });

        this.shiftLimiters
            .selectAll('line.end-stroke')
            .attr('x1', (d: any) => d.wide)
            .attr('x2', (d: any) => d.wide);
    }
}

@Component({
    selector: 'app-gantt-chart',
    templateUrl: './gantt-chart.component.html',
    styleUrls: ['./gantt-chart.component.scss'],
})
export class GanttChartComponent implements AfterViewInit {
    @Input()
    jornada!: any;

    @Input()
    selectedFromDate!: string;

    @Input()
    selectedToDate!: string;

    ngAfterViewInit(): void {
        if (!this.selectedFromDate) return;
        if (!this.selectedToDate) return;

        /* PARA TRADUCIR FECHAS */
        d3.timeFormatDefaultLocale({
            dateTime: '%a %b %e %X %Y',
            date: '%m/%d/%Y',
            time: '%H:%M:%S',
            periods: ['AM', 'PM'],
            days: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
            ],
            shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            shortMonths: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ],
        });

        const startDate = new Date(this.selectedFromDate),
            endDate = new Date(this.selectedToDate);

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        const preStartDate = new Date(startDate.getTime()),
            postEndDate = new Date(endDate.getTime());

        preStartDate.setDate(preStartDate.getDate() - 2);
        postEndDate.setDate(postEndDate.getDate() + 1);

        const sifts: any = [];
        // add every day from 06:00 to 22:00
        const days = d3.timeDay.range(preStartDate, postEndDate);
        days.forEach(d => {
            const start = new Date(d);
            start.setHours(6);
            const end = new Date(d);
            end.setHours(22);
            sifts.push({ min: start, max: end });
        });

        const OFs: any = [];
        const sessions = this.jornada.sessions;
        sessions.forEach((session: any) => {
            if (session.type == 'unlinked_session') return;
            if (session.type == 'work_pause') return;
            const ofs = session.ofs;
            ofs.forEach((of: any) => {
                const of_uid = of.uid;
                if (!OFs.find((d: any) => d.id == of_uid))
                    OFs.push({
                        id: of_uid,
                        name: of.linea_composed_id,
                    });
            });
        });

        OFs.push({
            id: 'unlinked_session',
            name: 'Tareas Desvinculadas',
        });

        OFs.push({
            id: 'parada',
            name: 'Parada',
        });

        const data: any = {
            OFs: OFs,
            tasks: [],
        };

        data.OFs.forEach((of: any) => (of.shifts = sifts));

        const gChart = document.getElementById('gantt-chart');
        if (gChart) gChart.innerHTML = '';

        const clickBind = (d: any) => {
            document
                .querySelectorAll('.selected-session-style')
                .forEach((el: any) =>
                    el.classList.remove('selected-session-style')
                );
            const el = document.querySelector(`[data-nav-id="${d.nav_uid}"]`);
            if (!el) return;
            el.scrollIntoView({
                behavior: 'smooth',
            });
            el.classList.add('selected-session-style');
        };

        sessions.forEach((session: any) => {
            if (session.type == 'work_pause') {
                if (session.reason_code == 'fin_jornada') return;

                data.tasks.push({
                    id: session.uid + '-pause',
                    nav_uid: session.nav_uid,
                    phase:
                        RazonesParada[session.reason_code] ||
                        session.reason_code ||
                        'Parada',
                    startDate: session.start_date,
                    endDate: session.end_date || new Date(),
                    of: 'parada',
                    backgroundColor: '#000000',
                    click: clickBind,
                });
            } else if (session.type == 'unlinked_session') {
                data.tasks.push({
                    id: session.uid + '-unlinked',
                    nav_uid: session.nav_uid,
                    phase: session.work_code || 'Tarea Desvinculada',
                    startDate: session.start_date,
                    endDate: session.end_date || new Date(),
                    of: 'unlinked_session',
                    backgroundColor: '#000000',
                    click: clickBind,
                });
            } else {
                const ofs = session.ofs;

                ofs.forEach((of: any) => {
                    data.tasks.push({
                        id: of.uid,
                        nav_uid: session.nav_uid,
                        phase: session.phase.name,
                        startDate: session.start_date,
                        endDate: session.end_date || new Date(),
                        of: of.linea_composed_id,
                        backgroundColor: PHASE_COLORS[session.phase.name],
                        click: clickBind,
                    });
                });
            }
        });

        const gantt = new GanttChart('gantt-chart', {
            height: 50 + data.OFs.length * 40,
            width: gChart?.clientWidth, //introductir un tamaño o poner 'fit' para que se adapte a su padre
            startDate: startDate,
            endDate: endDate,
            data: data,
        }); /*on('task-dropped', (id, newStartDate, newof) => {
            if (!newof) console.log(`[${id}] New Date! ${startDate}`);
            else console.log(`[${id}] New Date and of! ${newStartDate} || ${newof}`);
        }).on('task-dropped', function() {
            //this.updateTasks(data.tasks);

            //let taskId = data.tasks[0].id,
            //    taskData = data.tasks[0];
            //this.updateTask(taskId, taskData);
        }).on('task-ctrl-clicked', (id) => console.log('Ctr Clicked!') )
        .on('task-ctrl-clicked', function(id) {
            console.log('Test!', this);
        } )*/
    }
}
