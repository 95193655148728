<div
    class="modal"
    #ofPauseModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="ofPauseModal"
    aria-hidden="true">
    <form (submit)="updatePause()" novalidate>
        <div
            *ngIf="showing"
            class="modal-dialog modal-dialog-centered"
            role="document">
            <div class="modal-content text-light bg-dark">
                <div class="modal-header">
                    <h2 class="modal-title">Editar pausa:</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-2">
                            <label for="date-from">Desde:</label>
                            <input
                                id="date-from"
                                step="1"
                                name="date-from"
                                type="datetime-local"
                                class="form-control"
                                [(ngModel)]="start_date"
                                required />
                        </div>
                        <div class="col-12 col-md-6 mb-2">
                            <label for="date-to">Hasta:</label>
                            <input
                                id="date-to"
                                step="1"
                                name="date-to"
                                type="datetime-local"
                                class="form-control"
                                [(ngModel)]="end_date" />
                        </div>
                        <div class="col-12">
                            <button
                                class="btn btn-pause-opt"
                                [class.active]="selected_opt === 'fin_jornada'"
                                type="button"
                                (click)="setOpt('fin_jornada')">
                                Fin de Jornada
                            </button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button
                                class="btn btn-pause-opt"
                                [class.active]="selected_opt === 'comida'"
                                type="button"
                                (click)="setOpt('comida')">
                                Comida
                            </button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button
                                class="btn btn-pause-opt"
                                [class.active]="selected_opt === 'medico'"
                                type="button"
                                (click)="setOpt('medico')">
                                Médico
                            </button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button
                                class="btn btn-pause-opt"
                                [class.active]="
                                    selected_opt === 'descanso_almuerzo'
                                "
                                type="button"
                                (click)="setOpt('descanso_almuerzo')">
                                Descanso/Almuerzo
                            </button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button
                                class="btn btn-pause-opt"
                                [class.active]="selected_opt === 'otra'"
                                type="button"
                                (click)="setOpt('otra')">
                                Otra
                            </button>
                        </div>
                    </div>
                    <hr />
                    <textarea
                        id="selected_opt_extra"
                        name="selected_opt_extra"
                        class="form-control"
                        rows="3"
                        [(ngModel)]="selected_opt_extra"
                        placeholder="Observaciones..."></textarea>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-pause" type="submit">
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
