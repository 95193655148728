<div class="card">
    <div class="card-header">
        <div class="card-title">Órdenes activas</div>
        <div class="card-header-actions small-card-filters">
            <input
                type="text"
                class="form-control"
                placeholder="Buscar por ID..."
                [(ngModel)]="seachFilter"
                (input)="changeTrigger()"
                (change)="refreshList()" />
            <button
                class="btn btn-refresh"
                [class.active]="updatingList"
                (click)="refreshList()">
                <i class="fa-solid fa-arrows-rotate"></i>
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="custom-table sales-table table-no-border">
            <div class="table-body" [class.table-loading]="updatingList">
                <div class="table-row table-cols">
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['linea_composed_id'] === -1
                            "
                            [class.sorting_asc]="
                                sortings['linea_composed_id'] === 1
                            "
                            (click)="sortBy('linea_composed_id')"
                            >ID Línea</span
                        >
                    </div>
                    <div class="cell">
                        <span>Fases</span>
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="sortings['plazo'] === -1"
                            [class.sorting_asc]="sortings['plazo'] === 1"
                            (click)="sortBy('plazo')"
                            >Plazo</span
                        >
                    </div>
                    <div class="cell">
                        <span
                            class="sorting"
                            [class.sorting_desc]="
                                sortings['time_spent_n'] === -1
                            "
                            [class.sorting_asc]="sortings['time_spent_n'] === 1"
                            (click)="sortBy('time_spent_n')"
                            >T. dedicado</span
                        >
                    </div>
                    <div class="cell"></div>
                </div>
                <div class="table-row" *ngFor="let _of of itemList">
                    <div class="cell">
                        <strong>{{ _of.linea_composed_id }}</strong>
                    </div>
                    <div class="cell">
                        <app-phase-container
                            [phaseList]="_of.phases"></app-phase-container>
                    </div>
                    <div class="cell date-container">
                        <span class="date">{{
                            _of.plazo | localDate: 'd MMM, y'
                        }}</span>
                        <!--<br>
                        <span class="hour">{{_of.plazo | localDate:'HH:mm'}}</span>-->
                    </div>
                    <div class="cell">
                        <span>{{ prettyTimeSring(_of.time_spent) }}</span>
                    </div>
                    <div class="cell">
                        <a
                            [routerLink]="
                                '/ordenes-fabricacion/' + _of.uid + '/'
                            "
                            class="btn btn-small btn-primary"
                            title="Ver detalle">
                            <i class="fa-solid fa-eye"></i>
                        </a>
                        <button
                            (click)="markAsCompleted(_of.uid, $event)"
                            class="btn btn-small btn-success ml-1"
                            title="Marcar como completada">
                            <i class="fas fa-share"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
