<app-top-nav title="Seguimiento"></app-top-nav>

<div class="row">
    <div class="col-12 col-xl-auto">
        <ul class="nav-tabs mb-2">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/seguimiento/jornada-empleados"
                    >Jornada Empleados</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/seguimiento/informe-jornada"
                    >Informe Jornada</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/seguimiento/informe-errores"
                    >Informe de Errores</a
                >
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/seguimiento/paradas"
                    >Paradas</a
                >
            </li>
        </ul>
    </div>
    <div class="col-12 col-xl right-content">
        <div class="tab-content">
            <div class="tab-pane" [class.active]="tab === 'jornada-empleados'">
                <app-jornada-empleados></app-jornada-empleados>
            </div>
            <div class="tab-pane" [class.active]="tab === 'informe-jornada'">
                <app-informe-jornada></app-informe-jornada>
            </div>
            <div class="tab-pane" [class.active]="tab === 'informe-errores'">
                <app-informe-errores
                    *ngIf="tab === 'informe-errores'"></app-informe-errores>
            </div>
            <div class="tab-pane" [class.active]="tab === 'paradas'">
                <app-paradas></app-paradas>
            </div>
        </div>
    </div>
</div>
