import { Component, ViewChild, AfterContentInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { UserManagerService } from 'src/app/services/admin/user-manager.service';
import { PasswordChangingModalComponent } from '../password-changing-modal/password-changing-modal.component';
import { UsuariosEditingModalComponent } from './usuarios-editing-modal/usuarios-editing-modal.component';

import { UsusarioCreationModalComponent } from './ususario-creation-modal/ususario-creation-modal.component';
import Empleado from 'src/app/models/Empleado';
import EmpleadoManagerService from 'src/app/services/admin/empleado-manager.service';

declare let $: any;
declare let Notiflix: any;

@Component({
    selector: 'app-usuarios-manager',
    templateUrl: './usuarios-manager.component.html',
    styleUrls: [
        '../gestion.component.scss',
        './usuarios-manager.component.scss',
    ],
})
export class UsuariosManagerComponent implements AfterContentInit {
    @ViewChild('userCreationModal')
    userCreationModal!: UsusarioCreationModalComponent;
    @ViewChild('userEditionModal')
    userEditionModal!: UsuariosEditingModalComponent;
    @ViewChild('passwordChangingModal')
    passwordChangingModal!: PasswordChangingModalComponent;

    constructor(
        private empleadoManagerService: EmpleadoManagerService,
        private userManagerService: UserManagerService
    ) {}

    ngAfterContentInit() {
        this.updateUserList();

        $('.dropdown-toggle').dropdown();
    }

    userListLoading = false;
    userList: User[] = [];
    updateUserList() {
        this.userListLoading = true;
        this.userManagerService.listUsers().then((users: User[] | null) => {
            if (!users) return;
            this.userListLoading = false;
            this.userList = users.sort((a, b) =>
                a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
            );
        });
    }

    openUserCreationModal() {
        this.userCreationModal.openUserCreationModal();
    }

    openUserEditionModal(user: User) {
        this.userEditionModal.openUserEditionModal(user);
    }

    openPasswordChangingModal(user: User) {
        this.passwordChangingModal.openPasswordChangingModal(user);
    }

    deleteUser(user: User) {
        this.userManagerService.deleteUser(user).then(([success, error]) => {
            if (!success) {
                console.error(error);
                Notiflix.Notify.failure(error);
                return;
            }

            this.updateUserList();
        });
    }

    deactivateUser(user: User) {
        this.userManagerService
            .deactivateUser(user)
            .then(([success, error]) => {
                if (!success) {
                    console.error(error);
                    Notiflix.Notify.failure(error);
                    return;
                }

                this.updateUserList();
            });
    }

    activateUser(user: User) {
        this.userManagerService.activateUser(user).then(([success, error]) => {
            if (!success) {
                console.error(error);
                Notiflix.Notify.failure(error);
                return;
            }

            this.updateUserList();
        });
    }

    uploadAvatar(user: User) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            if (!file) return;
            this.userManagerService
                .uploadAvatar(user, file)
                .then(([success, error]) => {
                    if (!success) {
                        Notiflix.Notify.failure(error);
                        return;
                    }

                    this.updateUserList();
                });
        };

        input.click();
    }
}
