import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-informes',
    templateUrl: './informes.component.html',
    styleUrls: ['./informes.component.scss'],
})
export class InformesComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    public tab = '';
    avalibleTabs: string[] = [
        'comp-t-est-req-ped',
        'comp-t-est-req-ped-per-det',
        'comp-t-est-req-art-fam',
        't-medio-fab-art',
        'impore-pedidos-cliente',
    ];

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.tab = params['tab'];
            if (!this.avalibleTabs.includes(this.tab))
                this.router.navigate(['informes']);
        });
    }
}
