import { Injectable } from '@angular/core';
import APIService from '../api.service';
import OF_Phase_Session from 'src/app/models/OF_Phase_Session';

@Injectable({
    providedIn: 'root',
})
export class SeguimientoService {
    constructor(private api: APIService) {}

    getJornadaEmpleado(worker_uid: string, from_date: string, to_date: string) {
        return new Promise((resolve, reject) => {
            this.api
                .get(`/seguimiento/jornada-empleado/`, {
                    worker_uid,
                    from_date,
                    to_date,
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data);
                });
        });
    }

    generarInformeJornada(
        year: number,
        month: number,
        worker_uid: string | undefined
    ) {
        const params: any = { year, month };
        if (worker_uid) params['worker_uid'] = worker_uid;
        return new Promise((resolve, reject) => {
            this.api
                .get(`/seguimiento/informe-jornada/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;

                    return resolve(data);
                });
        });
    }

    editSession(
        session_type: string,
        session_uid: string,
        start_date: Date,
        end_date: Date
    ) {
        const params: any = {
            session_type: session_type,
            session_uid: session_uid,

            start_date,
            end_date,
        };

        return new Promise((resolve, reject) => {
            this.api
                .post(`/seguimiento/edit-session/`, params)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;

                    return resolve(data);
                });
        });
    }
}

export default SeguimientoService;
