import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/User';
import { UserManagerService } from 'src/app/services/admin/user-manager.service';

declare let $: any;

@Component({
    selector: 'app-usuarios-editing-modal',
    templateUrl: './usuarios-editing-modal.component.html',
    styleUrls: ['./usuarios-editing-modal.component.scss'],
})
export class UsuariosEditingModalComponent {
    showing = false;

    userEditionForm = this.formBuilder.group({
        uid: '',
        first_name: '',
        last_name: '',
        email: '',
        user_group: '',
    });

    @Output()
    onUserUpdated: EventEmitter<User> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private userManagerService: UserManagerService
    ) {}

    userEditionModal: any;
    ngOnInit() {
        this.userEditionModal = $('#user-edition-modal')
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    editionError: string | null = null;
    onSubmit(): void {
        // Process checkout data here
        console.warn('Submited form:', this.userEditionForm.value);

        this.editionError = null;

        const fields = this.userEditionForm.value;

        this.userEditionForm.disable();

        this.userManagerService
            .updateUser(fields as User)
            .then(([success, res]) => {
                this.userEditionForm.enable();
                if (!success) {
                    this.editionError = res as string;
                    return;
                }

                this.userEditionForm.reset();
                this.userEditionModal.modal('hide');
                this.editionError = null;

                this.onUserUpdated.emit(res as User);
            });
    }

    openUserEditionModal(user: User) {
        this.userEditionModal.modal('show');
        this.userEditionForm.reset();

        this.userEditionForm.patchValue(user);

        this.editionError = null;
    }
}
