<app-top-nav title="Órdenes de fabricación"></app-top-nav>

<div class="row">
    <div class="col-12 col-xl-6 mb-4">
        <app-listado-ventas></app-listado-ventas>
    </div>
    <div class="col-12 col-xl-6 mb-4">
        <app-ordenes-activas #appOrdenesActivas></app-ordenes-activas>
    </div>
    <div class="col-12">
        <app-ordenes-completadas></app-ordenes-completadas>
    </div>
</div>
