import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const ConnectionStates = {
    connecting: 'connecting',
    connected: 'connected',
    disconnected: 'disconnected',
};

@Injectable({
    providedIn: 'root',
})
export class WebsocketService extends EventTarget {
    socket!: WebSocket;

    status = ConnectionStates.disconnected;

    constructor(private authService: AuthService) {
        super();

        if (this.authService.authed) this.connect();

        this.authService.addEventListener('authed', () => this.connect());
        this.authService.addEventListener('unauthed', () => this.disconnect());
    }

    connect() {
        const accessToken = this.authService.getAccessToken();
        if (!accessToken) return;
        if (this.status != ConnectionStates.disconnected) return;

        const wsStart = location.protocol == 'https:' ? 'wss://' : 'ws://';
        // set auth cookies
        console.debug('[Socket]', 'Connecting...');
        if (this.socket) {
            this.socket.onclose = null;
            this.socket.close();
        }
        this.socket = new WebSocket(
            `${wsStart}${environment.WS_URL}/ws/?${accessToken}`
        );
        this.socket.onopen = (e: any) => this.onScoketOpened(e);
        this.socket.onmessage = (e: any) => this.onSocketMessage(e);
        this.socket.onclose = (e: any) => this.onSocketClosed(e);
        this.socket.onerror = (e: any) => this.onSocketErrored(e);
        this.shouldReconnect = true;
    }

    disconnect() {
        if (this.socket) this.socket.close();
        this.shouldReconnect = false;
    }

    reconnecting = false;
    reconnectSocket(t = 1000) {
        return new Promise(resolve => {
            if (this.reconnecting) return resolve(false);
            this.reconnecting = true;
            console.debug('[Socket]', `Reconnecting in ${t}ms`);
            setTimeout(() => {
                console.debug('[Socket]', 'Reconnecting...');
                this.reconnecting = false;
                this.connect();
            }, t);
        });
    }

    sendSocketMessage(message: any) {
        this.socket.send(JSON.stringify(message));
    }

    onSocketMessage(e: any) {
        let { data } = e;
        data = JSON.parse(data);
        console.debug('[Socket]', 'Message received');

        //console.debug('[Socket]', data);
        switch (data.type) {
            case 'metrics_update':
                this.triggerWSEvent('metrics', JSON.parse(data.data));
                break;
            case 'global:resync':
            case 'global:state_update':
                this.triggerWSEvent('global:updated', JSON.parse(data.data));
                break;
            case 'of:created':
                this.triggerWSEvent('of:created', JSON.parse(data.data));
                break;
        }
    }

    onScoketOpened(e: any) {
        console.debug('[Socket]', 'Connected');
        this.triggerEvent('connect');
        this.stateChange(ConnectionStates.connected);
    }

    shouldReconnect = true;
    onSocketClosed(e: any) {
        console.debug('[Socket]', 'Socket connection closed');
        this.triggerEvent('disconnect');
        this.stateChange(ConnectionStates.disconnected);
        if (this.shouldReconnect) this.reconnectSocket();
    }

    onSocketErrored(e: any) {
        console.error('[Socket]', 'Socket connection errored');
        this.triggerEvent('disconnect');
        this.stateChange(ConnectionStates.disconnected);
        this.socket.close();
    }

    stateChange(state: string) {
        this.status = state;
        this.triggerEvent('state-change', state);
    }

    triggerEvent(event: any, data: any = null) {
        const eventObject = new CustomEvent(event, { detail: data });
        this.dispatchEvent(eventObject);
    }

    eventHandlers: any = {};
    on(events: string, callback: Function) {
        for (const event of events.split(',')) {
            if (!this.eventHandlers[event]) this.eventHandlers[event] = [];
            if (this.eventHandlers[event].includes(callback)) return;

            this.eventHandlers[event].push(callback);
        }

        return () => this.off(events, callback);
    }

    off(events: string, callback: Function) {
        for (const event of events.split(',')) {
            if (!this.eventHandlers[event]) return;
            if (!this.eventHandlers[event].includes(callback)) return;

            this.eventHandlers[event].splice(
                this.eventHandlers[event].indexOf(callback),
                1
            );
        }
    }

    triggerWSEvent(event: string, data: any = null) {
        if (!this.eventHandlers[event]) return;
        for (const callback of this.eventHandlers[event]) callback(data);
    }

    log(...args: any[]) {
        console.debug('[WS]', ...args);
    }
}

export default WebsocketService;
