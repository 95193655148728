<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg-3">
        <label for="worker">Empleado:</label>
        <ng-select
            id="worker"
            [items]="empleados | async"
            (change)="onFiltersChange()"
            (input)="onFiltersChange()"
            bindLabel="full_name"
            autofocus
            bindValue="uid"
            [(ngModel)]="selectedWorkerUID">
        </ng-select>
    </div>
    <div class="col-12 col-md-6 col-lg"></div>
    <div
        class="col-12 col-md-6 col-lg-auto d-flex align-items-center justify-content-end">
        <button
            class="btn btn-refresh"
            [class.i-spin]="fetchingParadas"
            (click)="onFiltersChange()"
            [disabled]="fetchingParadas">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12" *ngIf="fetchingParadas">
        <app-loader></app-loader>
    </div>
    <div class="col-12" *ngIf="!fetchingParadas">
        <div class="card">
            <div class="card-header">
                <div class="card-title">Paradas registradas</div>
            </div>
            <div class="card-body p-0">
                <div class="custom-table pauses-table table-no-border">
                    <div
                        class="table-body"
                        [class.table-loading]="fetchingParadas">
                        <div class="table-row table-cols">
                            <div class="cell">
                                <span>ID</span>
                            </div>
                            <div class="cell">
                                <span>Empleado</span>
                            </div>
                            <div class="cell">
                                <span>Desde</span>
                            </div>
                            <div class="cell">
                                <span>Hasta</span>
                            </div>
                            <div class="cell">
                                <span>Razón</span>
                            </div>
                            <div class="cell">
                                <span>Obsevaciones</span>
                            </div>
                            <div class="cell text-right">
                                <span>Acciones</span>
                            </div>
                        </div>
                        <div class="table-row" *ngFor="let pause of itemList">
                            <div class="cell">
                                {{ pause.id }}
                            </div>
                            <div class="cell">
                                <div
                                    class="d-flex align-items-center"
                                    *ngIf="pause.user">
                                    <div class="avatar mr-2">
                                        <img
                                            [src]="pause.user.avatar_public_url"
                                            alt="avatar" />
                                    </div>
                                    <span
                                        >{{ pause.user.first_name }}
                                        {{ pause.user.last_name }}</span
                                    >
                                </div>
                            </div>
                            <div class="cell date-container">
                                <span class="main">{{
                                    pause.start_date | localDate: 'HH:mm:ss'
                                }}</span>
                                <br />
                                <span class="secondary">{{
                                    pause.start_date | localDate: 'd MMM, y'
                                }}</span>
                            </div>
                            <div class="cell date-container">
                                <span class="main">{{
                                    pause.end_date | localDate: 'HH:mm:ss'
                                }}</span>
                                <br />
                                <span class="secondary">{{
                                    pause.end_date | localDate: 'd MMM, y'
                                }}</span>
                            </div>
                            <div
                                class="cell"
                                [title]="getReasonFromCode(pause.reason_code)">
                                {{ getReasonFromCode(pause.reason_code) }}
                            </div>
                            <div class="cell" [title]="pause.reason_extended">
                                {{
                                    pause.reason_extended
                                        ? pause.reason_extended.substr(0, 20)
                                        : ''
                                }}...
                            </div>
                            <div class="cell text-right">
                                <button
                                    class="btn btn-primary btn-sm"
                                    (click)="openPauseEditModal(pause)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            (click)="setPage($event, activePage - 1)"
                            >«</a
                        >
                    </li>
                    <li
                        class="page-item"
                        *ngFor="let page of pages"
                        [class.active]="page == activePage">
                        <a
                            class="page-link"
                            href="#"
                            (click)="setPage($event, page)"
                            >{{ page }}</a
                        >
                    </li>
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            (click)="setPage($event, activePage + 1)"
                            >»</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-of-pause-edit-modal
    #pauseEditModal
    (onModalClose)="onParadaUpdated()"></app-of-pause-edit-modal>
