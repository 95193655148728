import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-admin',
    templateUrl: './gestion.component.html',
    styleUrls: ['./gestion.component.scss'],
})
export class GestionViewComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    public tab = '';
    avalibleTabs: string[] = ['usuarios', 'empleados', 'ajustes-generales'];

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.tab = params['tab'];
            if (!this.avalibleTabs.includes(this.tab))
                this.router.navigate(['gestion']);
        });
    }
}
