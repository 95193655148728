<div class="row mb-2">
    <div class="col-12 col-md-6 col-lg-3">
        <label for="serch-by-id">ID Cliente:</label>
        <input
            type="text"
            class="form-control"
            placeholder="Buscar por ID..."
            [(ngModel)]="seachFilter"
            (input)="onFiltersChange()"
            (change)="onFiltersChange()" />
    </div>
    <div class="col-12 col-md-6 col-lg"></div>
    <div class="col-12 col-md-6 col-lg-auto d-flex align-items-end">
        <button
            class="btn btn-export mr-1"
            (click)="exportToXlsx()"
            [disabled]="fetchingImportesClientes">
            <i class="fa-solid fa-download"></i>
        </button>
        <button
            class="btn btn-refresh"
            [class.i-spin]="fetchingImportesClientes"
            (click)="getImportePedidosCliente()"
            [disabled]="fetchingImportesClientes">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12" *ngIf="fetchingImportesClientes">
        <app-loader></app-loader>
    </div>
    <div
        class="col-12"
        *ngIf="!fetchingImportesClientes && importesclientes_filtered">
        <div class="card mb-2" *ngIf="!importesclientes_filtered.length">
            <div class="card-body">Sin clientes para estos filtros</div>
        </div>
        <div *ngIf="importesclientes_filtered.length">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                Importe pedidos por cliente
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div
                                class="custom-table table-no-border"
                                [class.table-refreshing]="
                                    fetchingImportesClientes
                                ">
                                <div
                                    class="table-body"
                                    [class.table-loading]="
                                        fetchingImportesClientes
                                    ">
                                    <div class="table-row table-cols">
                                        <div class="cell">
                                            <span>Cliente</span>
                                        </div>
                                        <div class="cell">
                                            <span>Importe total pedidos</span>
                                        </div>
                                    </div>
                                    <div
                                        class="table-row cliente-row"
                                        (click)="onClienteToggled(cliente)"
                                        [class.table-row-expanded]="
                                            cliente.expanded
                                        "
                                        *ngFor="
                                            let cliente of importesclientes_filtered
                                        ">
                                        <div class="cell">
                                            <span class="datum client">{{
                                                cliente.name
                                            }}</span>
                                        </div>
                                        <div class="cell">
                                            <span class="datum cash">{{
                                                eurFormat.format(
                                                    cliente.importe
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="cell-full-wide lineas-row-container"
                                            *ngIf="cliente.expanded">
                                            <app-loader
                                                [mini]="true"
                                                *ngIf="
                                                    cliente.lineas_cliente ===
                                                    null
                                                "></app-loader>
                                            <div
                                                class="table-row lineas-row"
                                                (click)="
                                                    onClienteToggled(cliente)
                                                "
                                                *ngFor="
                                                    let linea of cliente.lineas_cliente
                                                ">
                                                <div class="cell">
                                                    {{
                                                        linea.linea_composed_id
                                                    }}
                                                </div>
                                                <div class="cell">
                                                    <span
                                                        class="datum positive"
                                                        [class.negative]="
                                                            linea.offset > 0
                                                        "
                                                        >{{
                                                            getLineaPercent(
                                                                linea
                                                            )
                                                        }}%</span
                                                    >
                                                </div>
                                                <div class="cell">
                                                    <span class="datum time">{{
                                                        prettyTimeSring(
                                                            linea.t_est
                                                        )
                                                    }}</span>
                                                </div>
                                                <div class="cell">
                                                    <span class="datum time">{{
                                                        prettyTimeSring(
                                                            linea.t_req
                                                        )
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
