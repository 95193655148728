<div
    class="modal"
    #seguimientoCreatingModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="seguimientoCreatingModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <form
                [formGroup]="seguimientoCreatingForm"
                (ngSubmit)="onSubmit()"
                ngNativeValidate>
                <div class="modal-header">
                    <h2 class="modal-title">Crear Seguimiento</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row my-2">
                        <div class="col-12">
                            <label for="client_email"
                                >Correo Electrónico del cliente</label
                            >
                            <input
                                type="email"
                                class="form-control"
                                #email
                                id="client_email"
                                formControlName="client_email"
                                minlength="3"
                                placeholder="Correo Electrónico del cliente"
                                required />
                            <p class="text-muted">
                                <small
                                    >El cliente recibirá un correo con un enlace
                                    a través del cual podrá acceder al
                                    seguimiento de su orden de
                                    fabricación.</small
                                >
                            </p>
                        </div>
                    </div>
                    <div
                        *ngIf="seguimientoCreatingError"
                        class="alert alert-danger my-1"
                        role="alert">
                        {{ seguimientoCreatingError }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" type="submit">
                        Crear Seguimiento
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
