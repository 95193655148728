import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { prettyTimeSring } from 'src/utils';

import WebsocketService from 'src/app/services/websocket.service';
import OFService from 'src/app/services/main/of.service';
import OF from 'src/app/models/OF';

@Component({
    selector: 'app-seguimientos-list',
    templateUrl: './seguimientos-list.component.html',
    styleUrls: ['./seguimientos-list.component.scss'],
})
export class SeguimientosListComponent {
    prettyTimeSring = prettyTimeSring;

    @Output()
    public openStartSeguimientoModal = new EventEmitter();

    constructor(
        private ofService: OFService,
        private wsService: WebsocketService
    ) {}

    unbindWS: any;
    ngAfterContentInit() {
        this.refreshList();

        this.unbindWS = this.wsService.on(
            'global:updated',
            (data: any | null) => {
                this.refreshList();
            }
        );
    }

    ngOnDestroy() {
        if (this.unbindWS) this.unbindWS();
    }

    seachFilter = '';
    updatingList = false;
    itemList: OF[] = [];
    refreshList(): Promise<boolean> {
        const sorting_keys = Object.keys(this.sortings);
        const sort_by = sorting_keys.length > 0 ? sorting_keys[0] : false;
        const sort_direction =
            sort_by !== false ? this.sortings[sort_by] : false;

        this.updatingList = true;
        return new Promise(resolve => {
            this.ofService
                .getActiveSeguimientos(
                    this.seachFilter,
                    sort_by as string,
                    sort_direction
                ) // 100 limit
                .then((ofs: OF[] | null) => {
                    this.updatingList = false;
                    if (!ofs) return resolve(false);
                    this.itemList = ofs;
                    resolve(true);
                });
        });
    }

    startSeguimientoModal(of_uid: string) {
        this.openStartSeguimientoModal.emit(of_uid);
    }

    @ViewChild('confirmationModal')
    confirmationModal!: any;

    deleteItem(of_uid: string) {
        this.confirmationModal.confirmationPrompt(
            'Eliminar seguimiento',
            '¿Estás seguro de que quieres eliminar este seguimiento?',
            () => {
                this.ofService
                    .stopSeguimiento(of_uid)
                    .then(([success, data]) => {
                        if (!success) return;
                    });
            }
        );
    }

    changeTriggerTimeout: any;
    changeTrigger() {
        if (this.changeTriggerTimeout) clearTimeout(this.changeTriggerTimeout);
        this.changeTriggerTimeout = setTimeout(() => this.refreshList(), 500);
    }

    sortings: any = {
        seguimiento_started_at: -1,
    };
    sortBy(column: string) {
        if (column in this.sortings) {
            if (this.sortings[column] == 1) this.sortings[column] = -1;
            else this.sortings[column] = 1;
        } else {
            this.sortings = {};
            this.sortings[column] = 1;
        }

        this.refreshList();
    }
}
