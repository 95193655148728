import { Component, AfterViewInit } from '@angular/core';
import InformesService from 'src/app/services/admin/informes.service';

import utils, { prettyTimeSring, eurFormat } from 'src/utils';

import { Papa } from 'ngx-papaparse';

import * as XLSX from 'xlsx'; 

@Component({
    selector: 'app-importe-pedidos-cliente',
    templateUrl: './importe-pedidos-cliente.component.html',
    styleUrls: [
        './importe-pedidos-cliente.component.scss',
        '../informes.component.scss',
    ],
})
export class ImportePedidosClienteComponent implements AfterViewInit {
    prettyTimeSring = prettyTimeSring;
    eurFormat = eurFormat;

    constructor(
        private informesService: InformesService,
        private papa: Papa
    ) {}

    fetchingImportesClientes = false;
    importesclientes: any = null;
    importesclientes_filtered: any = null;
    seachFilter = '';
    lastRequestUID = '';
    getImportePedidosCliente() {
        this.fetchingImportesClientes = true;
        const lastRequestUID = (this.lastRequestUID = Math.random()
            .toString(36)
            .substring(2, 15));
        this.informesService
            .getImportePedidosCliente()
            .then((data: any[] | null) => {
                if (lastRequestUID != this.lastRequestUID) return;
                this.fetchingImportesClientes = false;
                if (!data) return;
                this.importesclientes = data;

                this.onFiltersChange();
            });
    }

    exportToXlsx() {
        const data = this.importesclientes_filtered.map((client: any) => {
            return {
                'Cliente': client.name,
                'Importe total pedidos': eurFormat.format(client.importe)
            }
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'export.xlsx');
    }

    ngAfterViewInit() {
        this.getImportePedidosCliente();
    }

    onFiltersChange() {
        this.importesclientes_filtered = this.importesclientes.filter(
            (tmedio: any) => {
                return tmedio.name
                    .toLowerCase()
                    .includes(this.seachFilter.toLowerCase());
            }
        );
    }

    onClienteToggled(cliente: any) {
        cliente.expanded = !cliente.expanded;

        cliente.lineas_cliente = null;
        if (!cliente.expanded) return;
        this.informesService
            .getImportePedidosExtendidoCliente(cliente)
            .then((data: any[] | null) => {
                if (!data) return;

                cliente.lineas_cliente = data;
            });
    }

    getLineaPercent(linea: any) {
        const t_req = linea.t_req,
            t_est = linea.t_est || 0;

        let p = t_req / t_est;
        if (Infinity == p) p = 0;
        if (isNaN(p)) p = 0;

        return Math.round(p * 100 * 100) / 100;
    }
}
