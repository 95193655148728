<div
    class="modal"
    id="user-creation-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="userCreationModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered modal-lg"
        role="document">
        <div class="modal-content text-light bg-dark">
            <form
                [formGroup]="userCreationForm"
                (ngSubmit)="onSubmit()"
                ngNativeValidate>
                <div class="modal-header">
                    <h2 class="modal-title">Crear Usuario</h2>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col-12 col-md-4 mb-3">
                            <label for="first_name">Nombre</label>
                            <input
                                type="text"
                                class="form-control"
                                id="first_name"
                                formControlName="first_name"
                                maxlength="150"
                                placeholder="Nombre"
                                required />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="last_name">Apellidos</label>
                            <input
                                type="text"
                                class="form-control"
                                id="last_name"
                                formControlName="last_name"
                                maxlength="150"
                                placeholder="Apellidos"
                                required />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="email">Correo Electrónico</label>
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                formControlName="email"
                                maxlength="64"
                                placeholder="Correo Electrónico"
                                required />
                        </div>
                    </div>
                    <div class="form-row my-2">
                        <div class="col-12 col-md-4 mb-3">
                            <label for="password">Contraseña</label>
                            <input
                                type="password"
                                class="form-control"
                                id="password"
                                formControlName="password"
                                minlength="3"
                                maxlength="32"
                                placeholder="Contraseña"
                                required />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="password_repeat"
                                >Repetir Contraseña</label
                            >
                            <input
                                type="password"
                                class="form-control"
                                id="password_repeat"
                                formControlName="password_repeat"
                                minlength="3"
                                maxlength="32"
                                placeholder="Repetir Contraseña"
                                required />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <label for="user_group"
                                >Selecciona el rango del usuario:</label
                            >
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="user_group"
                                    formControlName="user_group"
                                    id="user_group-admin"
                                    value="admin"
                                    checked
                                    required />
                                <label
                                    class="form-check-label"
                                    for="user_group-admin">
                                    <span class="rank-span rank-admin"
                                        >ADMINISTADOR</span
                                    >
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="user_group"
                                    formControlName="user_group"
                                    id="user_group-engineer"
                                    value="ingeniero"
                                    required />
                                <label
                                    class="form-check-label"
                                    for="user_group-ingeniero">
                                    <span class="rank-span rank-ingeniero"
                                        >INGENIERO</span
                                    >
                                </label>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="creationError"
                        class="alert alert-danger my-1"
                        role="alert">
                        {{ creationError }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" type="submit">
                        Crear Usuario
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
