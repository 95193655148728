import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/User';
import { UserManagerService } from 'src/app/services/admin/user-manager.service';

declare let $: any;

@Component({
    selector: 'app-ususario-creation-modal',
    templateUrl: './ususario-creation-modal.component.html',
    styleUrls: ['./ususario-creation-modal.component.scss'],
})
export class UsusarioCreationModalComponent {
    showing = false;

    userCreationForm = this.formBuilder.group({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_repeat: '',
        user_group: '',
    });

    @Output()
    onUserCreated: EventEmitter<User> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private userManagerService: UserManagerService
    ) {}

    userCreationModal: any;
    ngOnInit() {
        this.userCreationModal = $('#user-creation-modal')
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    creationError: string | null = null;
    onSubmit(): void {
        // Process checkout data here
        console.warn('Submited form:', this.userCreationForm.value);

        this.creationError = null;

        const fields = this.userCreationForm.value;
        if (fields.password != fields.password_repeat) {
            this.creationError = 'Las contraseñas no coinciden';
            return;
        }

        if (fields.user_group === null) {
            this.creationError = 'Debe seleccionar un grupo de usuario';
            return;
        }

        this.userCreationForm.disable();

        this.userManagerService
            .createUser(fields as User)
            .then(([success, res]) => {
                this.userCreationForm.enable();
                if (!success) {
                    this.creationError = res as string;
                    return;
                }

                this.userCreationForm.reset();
                this.userCreationModal.modal('hide');
                this.creationError = null;

                this.onUserCreated.emit(res as User);
            });
    }

    openUserCreationModal() {
        this.userCreationModal.modal('show');
        this.userCreationForm.reset();
        this.creationError = null;
    }
}
