<app-top-nav title="Métricas"></app-top-nav>

<div class="row">
    <div class="col-12 col-xl-9 col-xxl-6">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="card datum-card">
                    <h3>OFs Activas</h3>
                    <h1>{{ metrics?.active_ofs }}</h1>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="card datum-card">
                    <h3>OFs En Ejecución</h3>
                    <h1>{{ metrics?.running_ofs }}</h1>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="card datum-card">
                    <h3>OFs Completadas</h3>
                    <h1>{{ metrics?.completed_ofs }}</h1>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="card datum-card">
                    <h3>OFs completadas / dia</h3>
                    <app-line-chart [data]="lineChartData"></app-line-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-xl-3 col-xxl-6">
        <div class="card datum-card warnings-card">
            <h3>Informe de Errores</h3>
            <app-loader *ngIf="fetchingInformeErrores"></app-loader>
            <div *ngIf="!fetchingInformeErrores" class="error-list">
                <div
                    *ngIf="!informeErrores.length"
                    class="alert alert-warning mx-3 mt-2">
                    <span>No hay errores a mostrar</span>
                </div>
                <div
                    class="alert mx-3 mt-2"
                    *ngFor="let error of informeErrores"
                    [class.alert-danger]="error.type === 'danger'"
                    [class.alert-warning]="error.type === 'warning'">
                    <span [innerHTML]="error.message"></span>
                </div>
            </div>
        </div>
    </div>
</div>
