import { Component, OnDestroy } from '@angular/core';

import MetricsService from 'src/app/services/main/metrics.service';
import WebsocketService from 'src/app/services/websocket.service';
import InformesService from 'src/app/services/admin/informes.service';

@Component({
    selector: 'app-metrics',
    templateUrl: './metrics.component.html',
    styleUrls: ['./metrics.component.scss'],
})
export class MetricsViewComponent implements OnDestroy {
    constructor(
        private metricsService: MetricsService,
        private informesService: InformesService,
        private wsService: WebsocketService
    ) {}

    metrics: any;
    lineChartData: any[] | null = null;
    updateMetrics() {
        this.metricsService.getMetrics().then((metrics: any) => {
            this.metrics = null;
            if (!metrics) return;

            this.metrics = metrics;

            this.lineChartData = metrics.completions_timeline.map(
                (item: any) => {
                    const date = new Date(item.date);
                    date.setHours(0, 0, 0, 0);
                    return {
                        value: item.count,
                        date,
                    };
                }
            );
        });

        this.getImportePedidosCliente();
    }

    destroyTimeout: any = null;
    destryoed = false;
    ngOnDestroy() {
        this.destryoed = true;
        if (this.destroyTimeout) clearTimeout(this.destroyTimeout);
        if (this.unbindWS) this.unbindWS();
    }

    prepareDestroyTimer() {
        this.destroyTimeout = setTimeout(() => {
            if (this.destryoed) return;
            this.getImportePedidosCliente(true);
        }, 1000);
    }

    fetchingInformeErrores = false;
    informeErrores: any[] = [];

    lastRequestUID = '';
    getImportePedidosCliente(hiddenLoad = false) {
        if (!hiddenLoad) this.fetchingInformeErrores = true;

        const lastRequestUID = (this.lastRequestUID = Math.random()
            .toString(36)
            .substring(2, 15));
        this.informesService.getInformeErrores().then((informeErrores: any) => {
            if (lastRequestUID != this.lastRequestUID) return;
            this.informeErrores = informeErrores;
            if (!hiddenLoad) this.fetchingInformeErrores = false;

            this.prepareDestroyTimer();
        });
    }

    unbindWS: any;
    ngAfterContentInit() {
        this.unbindWS = this.wsService.on('metrics', (metrics: any | null) => {
            if (metrics) this.metrics = metrics;
            else this.updateMetrics();
        });
        this.updateMetrics();
    }
}
