export const JWT = {
    getExpireTime(token: string) {
        const payload = JSON.parse(window.atob(token.split('.')[1]));
        return payload.exp * 1000;
    },
    isExpired(token: string) {
        const expireTime = this.getExpireTime(token);
        return expireTime < Date.now();
    },
};

export const abs = Math.abs;

export const prettyTimeSring = (time: number): string => {
    const symbol = time < 0 ? '- ' : '';

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.round(time - hours * 3600 - minutes * 60);

    const out: string[] = [];
    if (hours != 0) out.push(`${abs(hours)}h`);
    if (minutes != 0) out.push(`${abs(minutes)}m`);
    if (seconds != 0) out.push(`${abs(seconds)}s`);

    if (out.length == 0) return `${symbol}0s`;

    return `${symbol}${out.join(' ')}`;
};

export const eurFormat = new Intl.NumberFormat('es-es', {
    style: 'currency',
    currency: 'EUR',
});

export const clone: any = (obj: any) => {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const getClosestFloorKey = (num: number, arr: any[]): any => {
    let k = -1,
        nearestKey,
        nearestVal;

    while (k++ < arr.length - 1) {
        const distance = Math.abs(num - arr[k]);
        if (nearestVal == undefined || nearestVal > distance) {
            nearestKey = k;
            nearestVal = distance;
        }
    }

    return nearestKey;
};

export const toDateString = (date: Date): string => {
    return (
        date.getFullYear().toString() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2) +
        'T' +
        date.toTimeString().slice(0, 8)
    );
};

export const distanceBetween = (a: number, b: number): number =>
    Math.abs(a - b);

export const removeAccents = (str: any) => {
    if (typeof str !== 'string') return str;
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export default {
    abs,
    prettyTimeSring,
    eurFormat,
    clone,
    getClosestFloorKey,
    toDateString,
    distanceBetween,
    removeAccents,
};
