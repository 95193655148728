import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import AuthService from 'src/app/services/auth.service';
import MeService from 'src/app/services/me.service';

@Component({
    selector: 'app-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private meService: MeService
    ) {}

    is_admin = false;
    is_manager = false;

    ngOnInit(): void {
        this.meService.getBasic().then((data: any) => {
            if (!data) return;
            const group = data.user_group;
            this.is_admin = group == 'admin';
            this.is_manager = group == 'gerente' || group == 'admin';
        });
    }

    logout() {
        this.authService
            .logout()
            .then(() => this.router.navigate(['/auth/login']));
    }
}
