import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthedGuard {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(): boolean {
        if (this.authService.authed) return true;

        this.router.navigate(['/auth/login']);
        return false;
    }
}

@Injectable({
    providedIn: 'root',
})
export class NotAuthedGuard {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(): boolean {
        if (!this.authService.authed) return true;

        this.router.navigate(['/metricas']);
        return false;
    }
}
