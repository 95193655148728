import { Component, AfterViewInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import InformesService from 'src/app/services/admin/informes.service';

import utils, { prettyTimeSring } from 'src/utils';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-comp-test-treq-por-art-fam',
    templateUrl: './comp-test-treq-por-art-fam.component.html',
    styleUrls: [
        './comp-test-treq-por-art-fam.component.scss',
        '../informes.component.scss',
    ],
})
export class CompTEstTReqPorArtFamComponent implements AfterViewInit {
    prettyTimeSring = prettyTimeSring;

    constructor(
        private informesService: InformesService,
        private papa: Papa
    ) {}

    fetchingTmedios = false;
    tmedios: any = null;
    tmedios_filtered: any = null;
    seachFilter = '';
    lastRequestUID = '';
    getTEstTReqPorArtFam() {
        this.fetchingTmedios = true;
        const lastRequestUID = (this.lastRequestUID = Math.random()
            .toString(36)
            .substring(2, 15));
        this.informesService
            .getTEstTReqPorArtFam()
            .then((data: any[] | null) => {
                if (lastRequestUID != this.lastRequestUID) return;
                this.fetchingTmedios = false;
                if (!data) return;
                this.tmedios = data;

                this.onFiltersChange();
            });
    }

    exportToXlsx() {
        const data = this.tmedios_filtered.map((articulo: any) => {
            return {
                'ID Pedido': articulo.id_articulo,
                'Desc. Artículo': articulo.descrip_articulo,
                'T. Estimado': prettyTimeSring(articulo.t_est),
                'T. Requerido': prettyTimeSring(articulo.t_req),
            };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'export.xlsx');
    }

    ngAfterViewInit() {
        this.getTEstTReqPorArtFam();
    }

    onFiltersChange() {
        this.tmedios_filtered = this.tmedios.filter((tmedio: any) => {
            return tmedio.id_articulo
                .toLowerCase()
                .includes(this.seachFilter.toLowerCase());
        });
    }
}
