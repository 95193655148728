import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './components/status-pages/page-not-found/page-not-found.component';

import { LoginComponent } from './components/auth/login/login.component';
import { LostPasswordComponent } from './components/auth/lost-password/lost-password.component';

import { AuthedGuard, NotAuthedGuard } from './guards/auth.guard';
import { IsAdminGuard } from './guards/permissions.guard';
import { MetricsViewComponent } from './components/main/metrics/metrics.component';

import { GestionViewComponent } from './components/main/admin/gestion/gestion.component';
import { RegistrosViewComponent } from './components/main/admin/registros/registros.component';
import { OrdenesFabricacionComponent } from './components/main/ordenes-fabricacion/ordenes-fabricacion.component';
import { SeguimientoComponent } from './components/main/seguimiento/seguimiento.component';
import { OrderDetailComponent } from './components/main/ordenes-fabricacion/order-detail/order-detail.component';
import { InformesComponent } from './components/main/informes/informes.component';
import { SeguimientosComponent } from './components/main/seguimientos/seguimientos.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { PublicSeguimientoComponent } from './components/public/public-seguimiento/public-seguimiento.component';

const routes: Routes = [
    {
        path: 'auth/login',
        component: LoginComponent,
        canActivate: [NotAuthedGuard],
    },
    {
        path: 'auth/lost-password',
        component: LostPasswordComponent,
        canActivate: [NotAuthedGuard],
    },
    {
        path: 'auth/password-reset/:token',
        component: PasswordResetComponent,
        canActivate: [NotAuthedGuard],
    },
    {
        path: 'metricas',
        component: MetricsViewComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'ordenes-fabricacion',
        component: OrdenesFabricacionComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'ordenes-fabricacion/:uid',
        component: OrderDetailComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'seguimiento/:tab',
        component: SeguimientoComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'seguimiento',
        redirectTo: '/seguimiento/jornada-empleados',
        pathMatch: 'full',
    },
    {
        path: 'seguimientos',
        component: SeguimientosComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'informes/:tab',
        component: InformesComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'informes',
        redirectTo: '/informes/comp-t-est-req-ped',
        pathMatch: 'full',
    },
    {
        path: 'registros',
        component: RegistrosViewComponent,
        canActivate: [AuthedGuard, IsAdminGuard],
    },
    {
        path: 'gestion/:tab',
        component: GestionViewComponent,
        canActivate: [AuthedGuard, IsAdminGuard],
    },
    {
        path: 'gestion',
        redirectTo: '/gestion/usuarios',
        pathMatch: 'full',
    },

    {
        path: 'public/s/:seguimiento_uid',
        component: PublicSeguimientoComponent,
        canActivate: [],
    },

    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [AuthedGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
