import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import AuthService from 'src/app/services/auth.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../auth.component.scss', './password-reset.component.scss'],
})
export class PasswordResetComponent {
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    recovering = false;
    recoveringError = '';

    recoveryToken = '';
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.recoveryToken = params['token'];
        });
    }

    password = '';
    passwordConfirm = '';

    doRcover() {
        if (this.password !== this.passwordConfirm) {
            this.recoveringError = 'Las contraseñas no coinciden';
            return;
        }

        this.recovering = true;
        this.recoveringError = '';

        this.authService
            .recoverPasswordComplete(this.recoveryToken, this.password)
            .then((res: any) => {
                this.recovering = false;
                this.router.navigate(['/auth/login']);
            })
            .catch((err: any) => {
                this.recoveringError = err;
                this.recovering = false;
            });
    }

    showPassword = false;
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
