<aside>
    <div class="aside-top">
        <div class="eremu-logo">
            <img
                src="assets/logos/eremu-auth.png"
                alt="Eremu Logo"
                class="logo" />
        </div>
        <a routerLink="/metricas" routerLinkActive="active" class="nav-btn">
            <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M31.0465 17.8823H17.0882L26.3841 27.1782C26.7394 27.5335 27.3241 27.5623 27.6894 27.2182C29.9659 25.0735 31.5318 22.1823 31.9912 18.9323C32.07 18.3759 31.6082 17.8823 31.0465 17.8823ZM30.1153 14.0706C29.6306 7.04352 24.0153 1.42824 16.9882 0.943533C16.4518 0.906475 16 1.35883 16 1.89647V15.0588H29.1629C29.7006 15.0588 30.1524 14.607 30.1153 14.0706ZM13.1765 17.8823V3.92412C13.1765 3.36235 12.6829 2.90059 12.1271 2.97941C5.11706 3.97 -0.24117 10.0941 0.00824179 17.4335C0.264712 24.9711 6.75471 31.1523 14.2959 31.0576C17.2606 31.0205 20 30.0652 22.2524 28.4676C22.7171 28.1382 22.7477 27.4541 22.3447 27.0511L13.1765 17.8823Z"
                    fill="#DDE2FF" />
            </svg>
            <span>Métricas</span>
        </a>
        <a
            routerLink="/ordenes-fabricacion"
            routerLinkActive="active"
            class="nav-btn">
            <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 3H20.5C20.775 3 21 3.225 21 3.5V6H11V3.5C11 3.225 11.225 3 11.5 3ZM8 3.5V6H4C1.79375 6 0 7.79375 0 10V16H12H20H32V10C32 7.79375 30.2062 6 28 6H24V3.5C24 1.56875 22.4312 0 20.5 0H11.5C9.56875 0 8 1.56875 8 3.5ZM32 18H20V20C20 21.1063 19.1063 22 18 22H14C12.8938 22 12 21.1063 12 20V18H0V26C0 28.2062 1.79375 30 4 30H28C30.2062 30 32 28.2062 32 26V18Z"
                    fill="#DDE2FF" />
            </svg>
            <span>Órdenes de fabricación</span>
        </a>
        <!--<a routerLink="/paradas" routerLinkActive="active" class="nav-btn" *ngIf="is_manager">
            <svg viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.1986 9.25052C27.5969 9.21952 26.2857 10.5768 26.2857 12.1977V18.5H25.7143V5.76609C25.7143 4.14517 24.4031 2.78788 22.8014 2.81888C21.2491 2.84894 20 4.13137 20 5.709V18.5H19.4286V2.94773C19.4286 1.32681 18.1174 -0.0304811 16.5156 0.000520804C14.9634 0.0305833 13.7143 1.31301 13.7143 2.89064V18.5H13.1429V5.83835C13.1429 4.21744 11.8317 2.86014 10.2299 2.89115C8.67772 2.92121 7.42857 4.20363 7.42857 5.78126V22.8354L5.168 19.6904C4.23993 18.3993 2.45307 18.1138 1.17679 19.0528C-0.0992832 19.9919 -0.381426 21.7997 0.546645 23.0908L9.51815 35.5715C9.83622 36.0139 10.2532 36.374 10.7351 36.6223C11.217 36.8706 11.7501 37 12.2909 37H26.4045C27.9955 37 29.3775 35.8926 29.7419 34.3258L31.6354 26.1843C31.8776 25.1428 31.9999 24.0764 31.9999 23.0064V12.1406C32 10.563 30.7509 9.28058 29.1986 9.25052V9.25052Z" fill="#DDE2FF"/>
            </svg>
            <span>Paradas</span>
        </a>-->
        <a routerLink="/seguimientos" routerLinkActive="active" class="nav-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                <path
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
            </svg>
            <span>Seguimientos Clientes</span>
        </a>
        <a routerLink="/seguimiento" routerLinkActive="active" class="nav-btn">
            <svg
                viewBox="0 0 33 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M31.8214 22.5625C31.8214 31.0902 24.9636 38 16.5 38C8.0364 38 1.17859 31.0902 1.17859 22.5625C1.17859 14.8438 6.7989 8.44609 14.1429 7.31055V4.75H12.0804C11.5942 4.75 11.1964 4.34922 11.1964 3.85938V0.890625C11.1964 0.400781 11.5942 0 12.0804 0H20.9197C21.4058 0 21.8036 0.400781 21.8036 0.890625V3.85938C21.8036 4.34922 21.4058 4.75 20.9197 4.75H18.8572V7.31055C21.6194 7.74102 24.1386 8.91367 26.2011 10.6207L28.2268 8.57969C28.573 8.23086 29.1328 8.23086 29.479 8.57969L31.5636 10.6801C31.9098 11.0289 31.9098 11.593 31.5636 11.9418L29.398 14.1238L29.3538 14.1684C30.9154 16.573 31.8214 19.4602 31.8214 22.5625ZM18.8572 25.2344V13.9902C18.8572 13.5004 18.4594 13.0996 17.9732 13.0996H15.0268C14.5406 13.0996 14.1429 13.5004 14.1429 13.9902V25.2344C14.1429 25.7242 14.5406 26.125 15.0268 26.125H17.9732C18.4594 26.125 18.8572 25.7242 18.8572 25.2344Z"
                    fill="#DDE2FF" />
            </svg>
            <span>Seguimiento</span>
        </a>
        <a routerLink="/informes" routerLinkActive="active" class="nav-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                    d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            <span>Informes</span>
        </a>
    </div>
    <div class="aside-bottom">
        <a
            class="nav-btn"
            routerLink="/registros"
            routerLinkActive="active"
            *ngIf="is_manager">
            <svg
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.89062 3.46875C1.92949 3.46875 1.15625 4.24199 1.15625 5.20312V8.67188C1.15625 9.63301 1.92949 10.4062 2.89062 10.4062H6.35938C7.32051 10.4062 8.09375 9.63301 8.09375 8.67188V5.20312C8.09375 4.24199 7.32051 3.46875 6.35938 3.46875H2.89062ZM13.875 4.625C12.5959 4.625 11.5625 5.6584 11.5625 6.9375C11.5625 8.2166 12.5959 9.25 13.875 9.25H34.6875C35.9666 9.25 37 8.2166 37 6.9375C37 5.6584 35.9666 4.625 34.6875 4.625H13.875ZM13.875 16.1875C12.5959 16.1875 11.5625 17.2209 11.5625 18.5C11.5625 19.7791 12.5959 20.8125 13.875 20.8125H34.6875C35.9666 20.8125 37 19.7791 37 18.5C37 17.2209 35.9666 16.1875 34.6875 16.1875H13.875ZM13.875 27.75C12.5959 27.75 11.5625 28.7834 11.5625 30.0625C11.5625 31.3416 12.5959 32.375 13.875 32.375H34.6875C35.9666 32.375 37 31.3416 37 30.0625C37 28.7834 35.9666 27.75 34.6875 27.75H13.875ZM1.15625 16.7656V20.2344C1.15625 21.1955 1.92949 21.9688 2.89062 21.9688H6.35938C7.32051 21.9688 8.09375 21.1955 8.09375 20.2344V16.7656C8.09375 15.8045 7.32051 15.0312 6.35938 15.0312H2.89062C1.92949 15.0312 1.15625 15.8045 1.15625 16.7656ZM2.89062 26.5938C1.92949 26.5938 1.15625 27.367 1.15625 28.3281V31.7969C1.15625 32.758 1.92949 33.5312 2.89062 33.5312H6.35938C7.32051 33.5312 8.09375 32.758 8.09375 31.7969V28.3281C8.09375 27.367 7.32051 26.5938 6.35938 26.5938H2.89062Z"
                    fill="#DDE2FF" />
            </svg>
            <span>Registros</span>
        </a>
        <a
            class="nav-btn"
            routerLink="/gestion"
            routerLinkActive="active"
            *ngIf="is_manager">
            <svg
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.06577 0.322125C4.45346 -0.154828 3.58335 -0.0968204 3.02905 0.451031L0.450923 3.02916C-0.0969284 3.57701 -0.154936 4.44712 0.315572 5.06588L5.47182 11.769C5.76186 12.1493 6.21948 12.3749 6.69643 12.3749H10.1833L17.2087 19.4002C16.2613 21.2694 16.5642 23.6155 18.1304 25.1753L25.3492 32.394C26.1548 33.1997 27.4632 33.1997 28.2689 32.394L32.3939 28.269C33.1996 27.4633 33.1996 26.1549 32.3939 25.3493L25.1751 18.1305C23.6154 16.5708 21.2693 16.2614 19.4001 17.2088L12.3748 10.1835V6.70298C12.3748 6.21959 12.1492 5.76841 11.7689 5.47837L5.06577 0.322125ZM1.28237 25.5297C0.463814 26.3483 -0.000248728 27.4633 -0.000248728 28.6235C-0.000248728 31.0405 1.95913 32.9999 4.37612 32.9999C5.53627 32.9999 6.65131 32.5358 7.46987 31.7172L15.0624 24.1247C14.5597 22.7776 14.4824 21.3145 14.8304 19.9288L10.8537 15.952L1.28237 25.5297ZM32.9997 9.28111C32.9997 8.60435 32.9289 7.94693 32.7935 7.31529C32.6388 6.59341 31.7558 6.4065 31.2337 6.92857L27.1152 11.0471C26.9218 11.2405 26.6576 11.3501 26.3869 11.3501H22.6873C22.1201 11.3501 21.656 10.886 21.656 10.3188V6.61275C21.656 6.34205 21.7656 6.07779 21.9589 5.88443L26.0775 1.76587C26.5996 1.2438 26.4126 0.360797 25.6908 0.206109C25.0527 0.0707577 24.3953 -0.000140727 23.7185 -0.000140727C18.5945 -0.000140727 14.4373 4.15709 14.4373 9.28111V9.33267L19.9351 14.8305C22.2554 14.244 24.8207 14.8627 26.6382 16.6803L27.6501 17.6922C30.8083 16.2098 32.9997 13.0001 32.9997 9.28111ZM6.70288 27.8436C6.70288 28.7008 6.01323 29.3905 5.156 29.3905C4.29877 29.3905 3.60913 28.7008 3.60913 27.8436C3.60913 26.9864 4.29877 26.2967 5.156 26.2967C6.01323 26.2967 6.70288 26.9864 6.70288 27.8436Z"
                    fill="#DDE2FF" />
            </svg>
            <span>Gestión</span>
        </a>
        <a (click)="logout()" class="nav-btn logout-btn">
            <div class="logout-container">
                <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M29.7 17.4125C30.3944 16.6313 30.3944 15.3625 29.7 14.5813L22.5889 6.58125C21.8944 5.8 20.7667 5.8 20.0722 6.58125C19.3778 7.3625 19.3778 8.63125 20.0722 9.4125L24.15 14H12.4444C11.4611 14 10.6667 14.8938 10.6667 16C10.6667 17.1062 11.4611 18 12.4444 18H24.15L20.0722 22.5875C19.3778 23.3687 19.3778 24.6375 20.0722 25.4188C20.7667 26.2 21.8944 26.2 22.5889 25.4188L29.7 17.4188V17.4125ZM10.6667 6C11.65 6 12.4444 5.10625 12.4444 4C12.4444 2.89375 11.65 2 10.6667 2H7.1111C4.16666 2 1.77777 4.6875 1.77777 8V24C1.77777 27.3125 4.16666 30 7.1111 30H10.6667C11.65 30 12.4444 29.1063 12.4444 28C12.4444 26.8937 11.65 26 10.6667 26H7.1111C6.12777 26 5.33333 25.1063 5.33333 24V8C5.33333 6.89375 6.12777 6 7.1111 6H10.6667Z"
                        fill="#9FA2B4" />
                </svg>
                <span>Cerrar Sesión</span>
            </div>
        </a>
    </div>
</aside>
