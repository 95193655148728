import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class UserManagerService {
    constructor(private api: APIService) {}

    listUsers(): Promise<User[] | null> {
        return new Promise(resolve => {
            this.api
                .get('/admin/user/list/')
                .then((response: any) => resolve(response.data as User[]))
                .catch((error: any) => {
                    this.log('Error while listing users', error);
                    return resolve(null);
                });
        });
    }

    createUser(user: User): Promise<[boolean, User | string | null]> {
        return new Promise(resolve => {
            this.api
                .post('/admin/user/create/', user)
                .then((response: any) => {
                    const { success, data, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, data as User]);
                })
                .catch((error: any) => {
                    this.log('Error while creating user', error);
                    return resolve([false, null]);
                });
        });
    }

    updateUser(user: User): Promise<[boolean, User | string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/user/${user.uid}/update/`, user)
                .then((response: any) => {
                    const { success, data, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, data as User]);
                })
                .catch((error: any) => {
                    this.log('Error while updating user', error);
                    return resolve([false, null]);
                });
        });
    }

    uploadAvatar(user: User, file: File): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            const formData = new FormData();
            formData.append('avatar', file);

            this.api
                .post(`/admin/user/${user.uid}/upload-avatar/`, formData)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while uploading avatar', error);
                    return resolve([false, null]);
                });
        });
    }

    changePassword(user: User): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/user/${user.uid}/change-password/`, user)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while changing password', error);
                    return resolve([false, null]);
                });
        });
    }

    deleteUser(user: User): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/user/${user.uid}/delete/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while deleting user', error);
                    return resolve([false, null]);
                });
        });
    }

    deactivateUser(user: User): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/user/${user.uid}/deactivate/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while archiving user', error);
                    return resolve([false, null]);
                });
        });
    }

    activateUser(user: User): Promise<[boolean, string | null]> {
        return new Promise(resolve => {
            this.api
                .post(`/admin/user/${user.uid}/activate/`)
                .then((response: any) => {
                    const { success, error } = response;
                    if (!success) return resolve([false, error]);

                    return resolve([true, null]);
                })
                .catch((error: any) => {
                    this.log('Error while activating user', error);
                    return resolve([false, null]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[UserManagerService]', ...args);
    }
}

export default UserManagerService;
