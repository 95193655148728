import { Component, ViewChild } from '@angular/core';
import { SeguimientoCreatingModalComponent } from './seguimiento-creating-modal/seguimiento-creating-modal.component';

@Component({
    selector: 'app-seguimientos',
    templateUrl: './seguimientos.component.html',
    styleUrls: ['./seguimientos.component.scss'],
})
export class SeguimientosComponent {
    @ViewChild('seguimientoCreationModal')
    seguimientoCreationModal!: SeguimientoCreatingModalComponent;

    openStartSeguimientoModal(event: any) {
        const { of_uid, suggested_email } = event;
        this.seguimientoCreationModal.openModal(of_uid, suggested_email);
    }
}
