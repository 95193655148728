import { Component, OnInit } from '@angular/core';
import RazonesParada from 'src/app/constants/RazonesParada';
import Empleado from 'src/app/models/Empleado';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';
import { SeguimientoService } from 'src/app/services/main/seguimiento.service';

import { prettyTimeSring } from 'src/utils';

@Component({
    selector: 'app-informe-jornada',
    templateUrl: './informe-jornada.component.html',
    styleUrls: ['./informe-jornada.component.scss'],
})
export class InformeJornadaComponent implements OnInit {
    prettyTimeSring = prettyTimeSring;

    empleados!: Promise<Empleado[] | null>;
    empleadosList: Empleado[] = [];

    selectedWorkerUID!: string;
    selectedWorker: Empleado | undefined;
    workerSelected!: boolean;

    isValidYear!: boolean;
    isValidMonth!: boolean;

    validInputs = false;

    year: number = new Date().getFullYear();
    month: number = new Date().getMonth() + 1;

    constructor(
        private empleadoManagerService: EmpleadoManagerService,
        private seguimientoService: SeguimientoService
    ) {}

    ngOnInit() {
        this.empleados = this.empleadoManagerService.listEmpleados();
        this.empleados.then(
            (empleados: any) => (this.empleadosList = empleados)
        );

        this.onFiltersChange();
    }

    fechJornadaTimeout: any = null;
    onFiltersChange() {
        const selectedWorkerUID = this.selectedWorkerUID;
        this.workerSelected = selectedWorkerUID != undefined;
        this.selectedWorker = this.empleadosList.find(
            (empleado: any) => empleado.uid == selectedWorkerUID
        );

        this.isValidYear =
            !isNaN(this.year) && this.year > 2020 && this.year < 3000;

        this.isValidMonth =
            !isNaN(this.month) && this.month > 0 && this.month < 13;

        this.validInputs = false;

        if (!this.isValidYear) return;
        if (!this.isValidMonth) return;

        this.validInputs = true;
    }

    getReasonFromCode(reason_code: string) {
        return RazonesParada[reason_code];
    }

    reportURL!: string;

    generatingReport = false;
    generateReport() {
        this.generatingReport = true;
        this.seguimientoService
            .generarInformeJornada(
                this.year,
                this.month,
                this.selectedWorkerUID
            )
            .then(data => {
                if (!data) return;
                const { url } = data as any;

                window.open(url, '_blank');
                this.reportURL = url;

                this.generatingReport = false;
            })
            .catch(() => {
                this.generatingReport = false;
            });
    }
}
