import { Component, OnDestroy } from '@angular/core';

import OFService from 'src/app/services/main/of.service';

import OF from 'src/app/models/OF';

import WebsocketService from 'src/app/services/websocket.service';

import { prettyTimeSring } from 'src/utils';

declare let Notiflix: any;

@Component({
    selector: 'app-ordenes-activas',
    templateUrl: './ordenes-activas.component.html',
    styleUrls: [
        '../ordenes-fabricacion.component.scss',
        './ordenes-activas.component.scss',
    ],
})
export class OrdenesActivasComponent implements OnDestroy {
    prettyTimeSring = prettyTimeSring;

    constructor(
        private ofService: OFService,
        private wsService: WebsocketService
    ) {}

    unbindWS: any;
    ngAfterContentInit() {
        this.refreshList();

        this.unbindWS = this.wsService.on(
            'global:updated,of:created',
            (data: any | null) => {
                /*if (ofs) this.itemList = ofs;
            else this.refreshList();*/
                this.refreshList();
            }
        );
    }

    ngOnDestroy() {
        if (this.unbindWS) this.unbindWS();
    }

    seachFilter = '';
    updatingList = false;
    itemList: OF[] = [];
    poastFinishRuns: any[] = [];
    refreshList(): Promise<boolean> {
        const sorting_keys = Object.keys(this.sortings);
        const sort_by = sorting_keys.length > 0 ? sorting_keys[0] : false;
        const sort_direction =
            sort_by !== false ? this.sortings[sort_by] : false;

        if (this.updatingList) {
            let resolve;
            const promise: Promise<boolean> = new Promise(
                _resolve => (resolve = _resolve)
            );
            this.poastFinishRuns.push(resolve);

            return promise;
        }
        this.updatingList = true;
        return new Promise(resolve => {
            this.ofService
                .getActiveOFs(
                    this.seachFilter,
                    sort_by as string,
                    sort_direction
                ) // 100 limit
                .then((ofs: OF[] | null) => {
                    if (this.poastFinishRuns.length > 0) {
                        const runs = this.poastFinishRuns;
                        this.refreshList().then(() => {
                            runs.forEach(run => run(true));
                            this.poastFinishRuns = [];
                        });
                    }

                    this.updatingList = false;
                    if (!ofs) return resolve(false);
                    this.itemList = ofs;

                    resolve(true);
                });
        });
    }

    changeTriggerTimeout: any;
    changeTrigger() {
        if (this.changeTriggerTimeout) clearTimeout(this.changeTriggerTimeout);
        this.changeTriggerTimeout = setTimeout(() => this.refreshList(), 500);
    }

    completing = false;
    markAsCompleted(of_uid: string, event: any) {
        event.target.disabled = true;
        event.target.parentElement.disabled = true;
        this.completing = true;
        this.ofService
            .markAsCompleted(of_uid)
            .then(() => {
                this.completing = false;
                this.refreshList();
            })
            .catch(() => {
                this.completing = false;
            });
    }

    sortings: any = {
        plazo: -1,
    };
    sortBy(column: string) {
        if (column in this.sortings) {
            if (this.sortings[column] == 1) this.sortings[column] = -1;
            else this.sortings[column] = 1;
        } else {
            this.sortings = {};
            this.sortings[column] = 1;
        }

        this.refreshList();
    }
}
