import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './components/auth/login/login.component';
import { LostPasswordComponent } from './components/auth/lost-password/lost-password.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MetricsViewComponent } from './components/main/metrics/metrics.component';

import { GestionViewComponent } from './components/main/admin/gestion/gestion.component';
import { RegistrosViewComponent } from './components/main/admin/registros/registros.component';
import { OrdenesFabricacionComponent } from './components/main/ordenes-fabricacion/ordenes-fabricacion.component';

import { ParadasComponent } from './components/main/seguimiento/paradas/paradas.component';
import { OfPauseEditModalComponent } from './components/main/seguimiento/paradas/of-pause-modal/of-pause-edit-modal.component';
import { JornadaEmpleadosComponent } from './components/main/seguimiento/jornada-empleados/jornada-empleados.component';

import { SeguimientoComponent } from './components/main/seguimiento/seguimiento.component';

import { TopNavComponent } from './components/main/shared/top-nav/top-nav.component';
import { AsideMenuComponent } from './components/main/shared/aside-menu/aside-menu.component';

import { UsuariosManagerComponent } from './components/main/admin/gestion/usuarios-manager/usuarios-manager.component';
import { UsusarioCreationModalComponent } from './components/main/admin/gestion/usuarios-manager/ususario-creation-modal/ususario-creation-modal.component';
import { UsuariosEditingModalComponent } from './components/main/admin/gestion/usuarios-manager/usuarios-editing-modal/usuarios-editing-modal.component';

import { EmpleadosManagerComponent } from './components/main/admin/gestion/empleados-manager/empleados-manager.component';
import { EmpleadosCreatingModalComponent } from './components/main/admin/gestion/empleados-manager/empleado-creating-modal/empleado-creating-modal.component';
import { EmpleadosEditingModalComponent } from './components/main/admin/gestion/empleados-manager/empleado-editing-modal/empleado-editing-modal.component';

import { PasswordChangingModalComponent } from './components/main/admin/gestion/password-changing-modal/password-changing-modal.component';

import { GeneralSettingsComponent } from './components/main/admin/gestion/general-settings/general-settings.component';

import { ListadoVentasComponent } from './components/main/ordenes-fabricacion/listado-ventas/listado-ventas.component';
import { OrdenesActivasComponent } from './components/main/ordenes-fabricacion/ordenes-activas/ordenes-activas.component';
import { OrdenesCompletadasComponent } from './components/main/ordenes-fabricacion/ordenes-completadas/ordenes-completadas.component';
import { PhaseContainerComponent } from './components/main/shared/phase-container/phase-container.component';
import { OrderDetailComponent } from './components/main/ordenes-fabricacion/order-detail/order-detail.component';
import { LoaderComponent } from './components/main/shared/loader/loader.component';
import { InformesComponent } from './components/main/informes/informes.component';
import { CompTEstTReqPorPedidoComponent } from './components/main/informes/comp-test-treq-por-pedido/comp-test-treq-por-pedido.component';
import { CompTEstTReqPorArtFamComponent } from './components/main/informes/comp-test-treq-por-art-fam/comp-test-treq-por-art-fam.component';
import { TMedioFabricacionArticuloComponent } from './components/main/informes/tmedio-fabricacion-articulo/tmedio-fabricacion-articulo.component';
import { ImportePedidosClienteComponent } from './components/main/informes/importe-pedidos-cliente/importe-pedidos-cliente.component';
import { CompTEstTReqPorPedidoPeriodoDetComponent } from './components/main/informes/comp-test-treq-por-pedido-periodo-det/comp-test-treq-por-pedido-periodo-det.component';
import { ConfirmationModalComponent } from './components/main/shared/confirmation-modal/confirmation-modal.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { SeguimientosComponent } from './components/main/seguimientos/seguimientos.component';
import { OrdenesExistentesComponent } from './components/main/seguimientos/ordenes-existentes/ordenes-existentes.component';
import { InformeErroresComponent } from './components/main/seguimiento/informe-errores/informe-errores.component';
import { SeguimientoCreatingModalComponent } from './components/main/seguimientos/seguimiento-creating-modal/seguimiento-creating-modal.component';
import { LineChartComponent } from './components/main/metrics/line-chart/line-chart.component';

import { SeguimientosListComponent } from './components/main/seguimientos/seguimientos-list/seguimientos-list.component';
import { PublicSeguimientoComponent } from './components/public/public-seguimiento/public-seguimiento.component';
import { InformeJornadaComponent } from './components/main/seguimiento/informe-jornada/informe-jornada.component';
import { EditableInputComponent } from './components/main/shared/editable-input/editable-input.component';
import { GanttChartComponent } from './components/main/seguimiento/jornada-empleados/gantt-chart/gantt-chart.component';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { DateEditionModalComponent } from './components/main/seguimiento/jornada-empleados/date-edition-modal/date-edition-modal.component';

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,

        LoginComponent,
        LostPasswordComponent,

        AsideMenuComponent,
        TopNavComponent,

        MetricsViewComponent,
        LineChartComponent,

        GestionViewComponent,
        RegistrosViewComponent,
        OrdenesFabricacionComponent,
        OfPauseEditModalComponent,

        LoaderComponent,

        SeguimientoComponent,
        JornadaEmpleadosComponent,
        JornadaEmpleadosComponent,
        InformeErroresComponent,
        ParadasComponent,

        SeguimientosComponent,
        OrdenesExistentesComponent,
        SeguimientoCreatingModalComponent,
        SeguimientosListComponent,

        InformesComponent,
        CompTEstTReqPorPedidoComponent,
        CompTEstTReqPorArtFamComponent,

        UsuariosManagerComponent,
        UsusarioCreationModalComponent,
        UsuariosEditingModalComponent,

        EmpleadosManagerComponent,
        EmpleadosCreatingModalComponent,
        EmpleadosEditingModalComponent,

        PasswordChangingModalComponent,

        GeneralSettingsComponent,
        ListadoVentasComponent,
        OrdenesActivasComponent,
        OrdenesCompletadasComponent,
        PhaseContainerComponent,
        OrderDetailComponent,
        TMedioFabricacionArticuloComponent,
        ImportePedidosClienteComponent,
        CompTEstTReqPorPedidoPeriodoDetComponent,
        ConfirmationModalComponent,
        PasswordResetComponent,
        PublicSeguimientoComponent,
        InformeJornadaComponent,
        EditableInputComponent,
        GanttChartComponent,

        LocalDatePipe,
        DateEditionModalComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'es-ES',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
