<div class="card">
    <div class="card-header">
        <div class="card-title">Líneas existentes (Ventas)</div>
        <div class="card-header-actions small-card-filters">
            <input
                type="text"
                class="form-control"
                placeholder="Buscar por ID..."
                [(ngModel)]="seachFilter"
                (input)="changeTrigger()"
                (change)="refreshList()" />
            <button
                class="btn btn-refresh"
                [class.active]="updatingList"
                (click)="refreshList()">
                <i class="fa-solid fa-arrows-rotate"></i>
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="custom-table sales-table table-no-border">
            <div class="table-body" [class.table-loading]="updatingList">
                <div class="table-row table-cols">
                    <div class="cell">
                        <span>ID</span>
                    </div>
                    <div class="cell">
                        <span>Unidades</span>
                    </div>
                    <div class="cell">
                        <span>Cliente</span>
                    </div>
                    <div class="cell">
                        <span>Plazo</span>
                    </div>
                    <div class="cell"></div>
                </div>
                <div class="table-row" *ngFor="let sale of itemList">
                    <div class="cell">
                        <strong>{{ sale.id }}</strong>
                    </div>
                    <div class="cell">
                        <strong>{{ sale.ctd }}</strong>
                    </div>
                    <div class="cell">
                        <strong>{{ sale.cliente }}</strong>
                    </div>
                    <div class="cell date-container">
                        <span class="date">{{
                            sale.plazo | localDate: 'd MMM, y'
                        }}</span>
                        <!--<br>
                        <span class="hour">{{sale.plazo | localDate:'HH:mm'}}</span>-->
                    </div>
                    <div class="cell text-right">
                        <button
                            class="btn btn-success ml-auto"
                            [class.btn-loading]="
                                creatingOfSaleIDs.includes(sale.id)
                            "
                            [disabled]="creatingOfSaleIDs.includes(sale.id)"
                            (click)="createOF(sale)">
                            Crear OF
                            <i class="fa-solid fa-forward-fast"></i>
                        </button>
                    </div>
                    <div class="cell-full-wide">
                        <p>
                            <strong>{{ sale.id_articulo }}</strong> |
                            {{ sale.descrip_articulo }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
