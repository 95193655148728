<div class="auth-form-container">
    <div class="auth-form auth-form-recover">
        <img src="assets/logos/eremu-auth.png" alt="logo" class="logo" />
        <form
            id="recover-form"
            (ngSubmit)="doRecover()"
            ngNativeValidate
            *ngIf="!recoverSent">
            <h4 class="auth-title">Recuperar contraseña</h4>
            <p class="auth-sub">
                Ingrese su correo electrónico pare recibir un enlace de
                recuperación
            </p>

            <div class="form-label">
                <label for="email">Correo electrónico</label>
            </div>
            <div class="form-field-input">
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Correo electrónico"
                    [(ngModel)]="email"
                    required />
            </div>
            <button
                type="submit"
                class="btn-auth btn-auth-recover"
                [class.btn-loading]="recovering"
                [disabled]="recovering">
                Solicitar recuperación
            </button>
            <p class="divider-o">
                <span>O</span>
            </p>
            <a routerLink="/auth/login"
                ><button class="btn-auth btn-auth-login">
                    Iniciar sesión
                </button></a
            >
        </form>
        <div *ngIf="recoverSent">
            <h4 class="auth-title">Recuperar contraseña</h4>
            <p class="auth-sub">
                Si la cuenta existe, se ha enviado un correo electrónico con
                instrucciones para recuperar la contraseña.
            </p>
            <a routerLink="/auth/login"
                ><button class="btn-auth btn-auth-login">
                    Iniciar sesión
                </button></a
            >
        </div>
        <div class="alert alert-danger mt-2 mb-0" *ngIf="recoveringError">
            <i class="fa-solid fa-exclamation-triangle mr-2"></i>
            <span [innerHtml]="recoveringError"></span>
        </div>
    </div>
</div>
