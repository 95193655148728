import { Component } from '@angular/core';

@Component({
    selector: 'app-ordenes-fabricacion',
    templateUrl: './ordenes-fabricacion.component.html',
    styleUrls: ['./ordenes-fabricacion.component.scss'],
})
export class OrdenesFabricacionComponent {
    constructor() {}
}
