import { Component, ViewChild } from '@angular/core';
import RazonesParada from 'src/app/constants/RazonesParada';
import Empleado from 'src/app/models/Empleado';
import Work_Pause from 'src/app/models/Work_Pause';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';
import OFService from 'src/app/services/main/of.service';
import { OfPauseEditModalComponent } from './of-pause-modal/of-pause-edit-modal.component';

@Component({
    selector: 'app-paradas',
    templateUrl: './paradas.component.html',
    styleUrls: ['./paradas.component.scss'],
})
export class ParadasComponent {
    empleados!: Promise<Empleado[] | null>;
    empleadosList: Empleado[] = [];

    selectedWorkerUID!: string;
    selectedWorker: Empleado | undefined;
    workerSelected!: boolean;

    constructor(
        private empleadoManagerService: EmpleadoManagerService,
        private ofService: OFService
    ) {}

    pages: number[] = [];
    activePage = 1;

    ngAfterContentInit() {
        this.empleados = this.empleadoManagerService.listEmpleados();
        this.empleados.then(
            (empleados: any) => (this.empleadosList = empleados)
        );

        this.fetchParadas();
    }

    seachFilter = '';
    fetchingParadas = false;
    itemList: Work_Pause[] = [];
    fetchParadas() {
        this.fetchingParadas = true;

        if (this.fechParadasTimeout) clearTimeout(this.fechParadasTimeout);
        this.fechParadasTimeout = setTimeout(() => {
            this.fechParadasTimeout = null;

            this.ofService
                .getPauses(this.activePage, this.selectedWorkerUID || false)
                .then(([success, data, pages]) => {
                    this.fetchingParadas = false;
                    if (!success) return;

                    this.pages = Array(pages)
                        .fill(0)
                        .map((_, i) => i + 1);
                    if (this.activePage > pages) this.activePage = pages;

                    this.itemList = data as Work_Pause[];
                })
                .catch(err => {
                    this.fetchingParadas = false;
                    console.error(err);
                });
        }, 500);
    }

    fechParadasTimeout: any = null;
    onFiltersChange() {
        const selectedWorkerUID = this.selectedWorkerUID;
        this.workerSelected = selectedWorkerUID != undefined;
        this.selectedWorker = this.empleadosList.find(
            (empleado: any) => empleado.uid == selectedWorkerUID
        );

        this.fetchParadas();
    }

    setPage(e: Event, page: number) {
        e.stopPropagation();
        e.preventDefault();

        if (page < 1 || page > this.pages.length || page === this.activePage)
            return;
        this.activePage = page;
        this.fetchParadas();
    }

    @ViewChild('pauseEditModal') pauseEditModal!: OfPauseEditModalComponent;
    openPauseEditModal(pause: Work_Pause) {
        this.pauseEditModal.openModal(pause);
    }

    getReasonFromCode(reason_code: string) {
        return RazonesParada[reason_code];
    }

    onParadaUpdated() {
        this.fetchParadas();
    }
}
