<app-top-nav title="Seguimientos"></app-top-nav>

<div class="row">
    <div class="col-12">
        <app-ordenes-existentes
            (openStartSeguimientoModal)="
                openStartSeguimientoModal($event)
            "></app-ordenes-existentes>
    </div>
    <div class="col-12">
        <app-seguimientos-list></app-seguimientos-list>
    </div>
</div>

<app-seguimiento-creating-modal
    #seguimientoCreationModal></app-seguimiento-creating-modal>
