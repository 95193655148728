import { Component, OnInit, ViewChild } from '@angular/core';
import RazonesParada from 'src/app/constants/RazonesParada';
import Empleado from 'src/app/models/Empleado';
import { EmpleadoManagerService } from 'src/app/services/admin/empleado-manager.service';
import { SeguimientoService } from 'src/app/services/main/seguimiento.service';

import { prettyTimeSring } from 'src/utils';
import { PHASE_COLORS } from 'src/consts';
import { OfPauseEditModalComponent } from '../paradas/of-pause-modal/of-pause-edit-modal.component';
import Work_Pause from 'src/app/models/Work_Pause';
import { DateEditionModalComponent } from './date-edition-modal/date-edition-modal.component';

@Component({
    selector: 'app-jornada-empleados',
    templateUrl: './jornada-empleados.component.html',
    styleUrls: ['./jornada-empleados.component.scss'],
})
export class JornadaEmpleadosComponent implements OnInit {
    prettyTimeSring = prettyTimeSring;
    PHASE_COLORS = PHASE_COLORS;

    empleados!: Promise<Empleado[] | null>;
    empleadosList: Empleado[] = [];

    selectedWorkerUID!: string;
    selectedWorker: Empleado | undefined;
    workerSelected!: boolean;

    selectedFromDate: string = new Date().toISOString().split('T')[0];
    selectedToDate: string = new Date().toISOString().split('T')[0];
    isValidFromDate!: boolean;
    isValidToDate!: boolean;

    validInputs = false;

    icons: any = {
        bobinado: [
            'M19.6566 10.1085L21.0323 7.33484H9.11011L10.4857 10.1085V20.2785L9.11011 23.0521H21.0323L19.6566 20.2785V10.1085ZM17.8225 16.118H12.3199V14.2689H17.8225V16.118ZM17.8225 10.1085V12.4198H12.3199V10.1085H17.8225ZM12.3199 20.2785V17.9671H17.8225V20.2785H12.3199Z',
        ],
        montaje: [
            'M10.6455 7.48821C10.3562 7.26104 9.94512 7.28867 9.68324 7.5496L8.46524 8.77752C8.20641 9.03845 8.179 9.45287 8.40129 9.74757L10.8373 12.9401C10.9743 13.1213 11.1905 13.2287 11.4159 13.2287H13.0632L16.3823 16.5748C15.9347 17.465 16.0778 18.5824 16.8177 19.3253L20.2282 22.7634C20.6088 23.1472 21.2269 23.1472 21.6076 22.7634L23.5564 20.7988C23.937 20.4151 23.937 19.7919 23.5564 19.4082L20.1459 15.97C19.409 15.2271 18.3007 15.0798 17.4176 15.531L14.0985 12.185V10.5273C14.0985 10.2971 13.992 10.0822 13.8123 9.94403L10.6455 7.48821ZM8.85804 19.4941C8.47133 19.884 8.25208 20.4151 8.25208 20.9676C8.25208 22.1188 9.17777 23.052 10.3197 23.052C10.8678 23.052 11.3945 22.831 11.7813 22.4411L15.3683 18.8249C15.1308 18.1833 15.0942 17.4865 15.2587 16.8265L13.3799 14.9324L8.85804 19.4941ZM23.8426 11.7552C23.8426 11.4329 23.8091 11.1198 23.7452 10.8189C23.6721 10.4751 23.2549 10.3861 23.0083 10.6347L21.0625 12.5963C20.9711 12.6884 20.8463 12.7406 20.7184 12.7406H18.9706C18.7026 12.7406 18.4834 12.5196 18.4834 12.2494V10.4843C18.4834 10.3554 18.5351 10.2295 18.6265 10.1374L20.5722 8.17584C20.8189 7.92719 20.7306 7.50663 20.3895 7.43295C20.0881 7.36849 19.7775 7.33472 19.4578 7.33472C17.037 7.33472 15.0729 9.31473 15.0729 11.7552V11.7798L17.6703 14.3983C18.7665 14.1189 19.9785 14.4136 20.8372 15.2793L21.3152 15.7613C22.8073 15.0552 23.8426 13.5265 23.8426 11.7552ZM11.4189 20.5962C11.4189 21.0045 11.0931 21.3329 10.6881 21.3329C10.2831 21.3329 9.9573 21.0045 9.9573 20.5962C9.9573 20.1879 10.2831 19.8594 10.6881 19.8594C11.0931 19.8594 11.4189 20.1879 11.4189 20.5962Z',
        ],
        barnizado: [
            'M15.5036 9.82318L14.5509 7.85852H13.5981L12.6454 9.82318L11.6926 7.85852H11.2162C10.4272 7.85852 9.78711 8.51852 9.78711 9.33201V15.7172H21.2202V9.33201C21.2202 8.51852 20.58 7.85852 19.791 7.85852H16.4564L15.5036 9.82318ZM9.78711 16.6995V17.6818C9.78711 18.7654 10.6416 19.6465 11.6926 19.6465H13.5981V21.6111C13.5981 22.6948 14.4526 23.5758 15.5036 23.5758C16.5546 23.5758 17.4091 22.6948 17.4091 21.6111V19.6465H19.3146C20.3657 19.6465 21.2202 18.7654 21.2202 17.6818V16.6995H9.78711ZM15.5036 22.1023C15.2416 22.1023 15.0273 21.8813 15.0273 21.6111C15.0273 21.341 15.2416 21.12 15.5036 21.12C15.7656 21.12 15.98 21.341 15.98 21.6111C15.98 21.8813 15.7656 22.1023 15.5036 22.1023Z',
        ],
        tropicalizado: [
            'M10.1353 10.3293C10.3391 10.126 10.6687 10.126 10.8726 10.3293L13.4993 12.9745C13.7032 13.18 13.7032 13.5123 13.4993 13.7156C13.2955 13.9189 12.9659 13.9211 12.7642 13.7156L10.1353 11.0726C9.93363 10.8671 9.93363 10.5348 10.1353 10.3293ZM20.1969 10.3293C20.4008 10.1238 20.7304 10.1238 20.932 10.3293C21.1337 10.5348 21.1359 10.8671 20.932 11.0704L18.3054 13.7156C18.1015 13.9211 17.7719 13.9211 17.5703 13.7156C17.3686 13.5101 17.3664 13.1778 17.5703 12.9745L20.1969 10.3293ZM10.1353 21.216C9.93146 21.0105 9.93146 20.6782 10.1353 20.4749L12.7642 17.985C12.9681 17.7795 13.2977 17.7795 13.4993 17.985C13.701 18.1905 13.7032 18.5227 13.4993 18.7261L10.8726 21.216C10.6687 21.4215 10.3391 21.4215 10.1375 21.216H10.1353ZM20.1969 21.216L17.5703 18.7261C17.3664 18.5206 17.3664 18.1883 17.5703 17.985C17.7741 17.7817 18.1037 17.7795 18.3054 17.985L20.932 20.4749C21.1359 20.6804 21.1359 21.0127 20.932 21.216C20.7282 21.4193 20.3986 21.4215 20.1969 21.216Z',
            'M15.5352 8.0744C15.8219 8.07595 16.055 8.31091 16.0565 8.59997L16.0585 12.3429C16.0585 12.6335 15.8255 12.8685 15.5387 12.8669C15.252 12.8654 15.0174 12.632 15.019 12.3429L15.0138 8.59997C15.0154 8.31091 15.2484 8.07595 15.5352 8.0744ZM22.6498 15.2469C22.9381 15.2469 23.1711 15.4819 23.1696 15.7709C23.1681 16.06 22.9365 16.2965 22.6498 16.295L18.9371 16.2929C18.6489 16.2929 18.4158 16.058 18.4173 15.7689C18.4189 15.4799 18.6504 15.2433 18.9371 15.2449L22.6498 15.2469ZM7.89917 15.7725C7.89917 15.4819 8.13223 15.2469 8.41897 15.2485L12.0244 15.3618C12.3126 15.3618 12.5457 15.5968 12.5442 15.8859C12.5426 16.1749 12.3111 16.4114 12.0244 16.4099L8.4205 16.298C8.13223 16.298 7.89917 16.0631 7.9007 15.774L7.89917 15.7725ZM15.0138 22.945L14.9029 19.3119C14.9029 19.0213 15.136 18.7863 15.4227 18.7878C15.7095 18.7894 15.9441 19.0228 15.9425 19.3119L16.0534 22.945C16.0534 23.2356 15.8204 23.4705 15.5336 23.469C15.2469 23.4675 15.0123 23.234 15.0138 22.945Z',
        ],
        conexionado: [
            'M11.6886 7.33484C11.1377 7.33484 10.6926 7.78845 10.6926 8.34991V11.3951H12.6847V8.34991C12.6847 7.78845 12.2396 7.33484 11.6886 7.33484ZM17.665 7.33484C17.114 7.33484 16.6689 7.78845 16.6689 8.34991V11.3951H18.661V8.34991C18.661 7.78845 18.2159 7.33484 17.665 7.33484ZM9.6965 12.4102C9.14555 12.4102 8.70044 12.8638 8.70044 13.4253C8.70044 13.9867 9.14555 14.4404 9.6965 14.4404V15.4554C9.6965 17.9106 11.4085 19.9598 13.6807 20.4293V22.5609C13.6807 23.1224 14.1259 23.576 14.6768 23.576C15.2278 23.576 15.6729 23.1224 15.6729 22.5609V20.4293C17.9451 19.9598 19.6571 17.9106 19.6571 15.4554V14.4404C20.2081 14.4404 20.6532 13.9867 20.6532 13.4253C20.6532 12.8638 20.2081 12.4102 19.6571 12.4102H9.6965Z',
        ],
        ensayo: [
            'M11.7677 19.2664C11.5051 19.6921 11.3658 20.1789 11.3658 20.6791C11.0978 20.7722 10.8084 20.8254 10.5083 20.8254C9.08808 20.8254 7.93585 19.6814 7.93585 18.2714V10.6092C7.46155 10.6092 7.07837 10.2288 7.07837 9.75785C7.07837 9.28694 7.46155 8.90649 7.93585 8.90649H9.6508H11.3658H13.0807C13.555 8.90649 13.9382 9.28694 13.9382 9.75785C13.9382 10.2288 13.555 10.6092 13.0807 10.6092V17.1513L11.7677 19.269V19.2664ZM9.6508 10.6092V13.1633H11.3658V10.6092H9.6508ZM16.5106 8.90649H19.9405H20.798C21.2723 8.90649 21.6555 9.28694 21.6555 9.75785C21.6555 10.2288 21.2723 10.6092 20.798 10.6092V14.6239L23.9519 19.7133C24.1315 20.0033 24.2279 20.3386 24.2279 20.6818C24.2279 21.7007 23.3946 22.5281 22.3683 22.5281H14.0829C13.0566 22.5281 12.2232 21.7007 12.2232 20.6818C12.2232 20.3412 12.3197 20.006 12.4992 19.7133L15.6532 14.6239V10.6092C15.1789 10.6092 14.7957 10.2288 14.7957 9.75785C14.7957 9.28694 15.1789 8.90649 15.6532 8.90649H16.5106ZM17.3681 10.6092V14.866C17.3681 15.0229 17.3252 15.1772 17.2422 15.3129L15.9345 17.42H20.5167L19.209 15.3129C19.1259 15.1799 19.0831 15.0256 19.0831 14.866V10.6092H17.3681Z',
        ],
        embalaje: [
            'M7.73721 9.63195C7.82491 9.45094 8.01784 9.34708 8.2137 9.37379L15.3697 10.2818L22.5258 9.37379C22.7216 9.35005 22.9146 9.45391 23.0023 9.63195L24.2213 12.1068C24.4844 12.638 24.2037 13.2819 23.6425 13.4451L18.8659 14.8309C18.4596 14.9496 18.024 14.7746 17.8077 14.4066L15.3697 10.2818L12.9318 14.4066C12.7155 14.7746 12.2799 14.9496 11.8736 14.8309L7.09995 13.4451C6.53577 13.2819 6.25806 12.638 6.52115 12.1068L7.73721 9.63195ZM15.4019 12.181L17.0067 14.8933C17.4423 15.6292 18.3105 15.9793 19.1261 15.7419L22.8532 14.6618V19.6174C22.8532 20.2703 22.4147 20.84 21.7891 21.0003L15.8228 22.5137C15.5247 22.5908 15.2119 22.5908 14.9166 22.5137L8.95035 21.0003C8.32478 20.8371 7.8863 20.2673 7.8863 19.6145V14.6588L11.6163 15.7419C12.429 15.9793 13.3001 15.6292 13.7357 14.8933L15.3376 12.181H15.4019V12.181Z',
        ],
    };

    getPhaseIconPaths(phase_name: string): number[] {
        const name = phase_name.toLowerCase();
        if (!this.icons[name]) return [];

        return this.icons[name].map((_: any, i: any) => i);
    }

    getPhaseIcon(phase_name: string): string {
        const name = phase_name.toLowerCase();
        if (!this.icons[name]) return '';

        return this.icons[name];
    }

    getPhaseColor(phase_name: string): string {
        const name = phase_name.toLowerCase();
        if (!PHASE_COLORS[name]) return '';

        return PHASE_COLORS[name];
    }

    constructor(
        private empleadoManagerService: EmpleadoManagerService,
        private seguimientoService: SeguimientoService
    ) {}

    ngOnInit() {
        this.empleados = this.empleadoManagerService.listEmpleados();
        this.empleados.then((empleados: any) => {
            this.empleadosList = empleados;

            this.selectedWorkerUID = this.empleadosList[0].uid as string;
            this.onFiltersChange();
        });

        this.onFiltersChange();
    }

    fechJornadaTimeout: any = null;
    onFiltersChange() {
        const selectedWorkerUID = this.selectedWorkerUID;
        this.workerSelected = selectedWorkerUID != undefined;
        this.selectedWorker = this.empleadosList.find(
            (empleado: any) => empleado.uid == selectedWorkerUID
        );

        // is date valid?
        const fromDate = new Date(this.selectedFromDate);
        this.isValidFromDate = !isNaN(fromDate.getTime());

        // is date valid?
        const toDate = new Date(this.selectedToDate);
        this.isValidToDate = !isNaN(toDate.getTime()) && toDate >= fromDate;

        this.validInputs = false;

        if (!this.workerSelected) return;
        if (!this.isValidFromDate) return;
        if (!this.isValidToDate) return;

        this.validInputs = true;
        this.fetchJornada();
    }

    fetchingJornada = false;
    jornada: any = null;
    fetchJornada() {
        this.fetchingJornada = true;

        if (this.fechJornadaTimeout) clearTimeout(this.fechJornadaTimeout);
        this.fechJornadaTimeout = setTimeout(() => {
            this.fechJornadaTimeout = null;

            this.seguimientoService
                .getJornadaEmpleado(
                    this.selectedWorkerUID,
                    new Date(this.selectedFromDate).toISOString(),
                    new Date(this.selectedToDate).toISOString()
                )
                .then((jornada: any) => {
                    jornada.sessions.forEach((session: any) => {
                        session.nav_uid = Math.random()
                            .toString(36)
                            .substring(7);
                    });
                    this.jornada = jornada;

                    this.fetchingJornada = false;
                });
        }, 500);
    }

    @ViewChild('pauseEditModal') pauseEditModal!: OfPauseEditModalComponent;
    openPauseEditModal(pause: Work_Pause) {
        this.pauseEditModal.openModal(pause);
    }

    getReasonFromCode(reason_code: string) {
        return RazonesParada[reason_code];
    }

    editingSession!: any;
    @ViewChild('dateEditionModal') dateEditionModal!: DateEditionModalComponent;
    editSession(session: any) {
        this.editingSession = session;
        this.dateEditionModal.openModal(
            session.type,
            session.uid,
            session.start_date,
            session.end_date,
            (
                session_type: string,
                session_uid: string,
                start_date: Date,
                end_date: Date
            ) => {
                this.seguimientoService
                    .editSession(
                        session_type,
                        session_uid,
                        start_date,
                        end_date
                    )
                    .then(() => this.fetchJornada());
            }
        );
    }
}
