<div class="top-nav">
    <div class="nav-title">
        <a
            *ngIf="backButton"
            class="btn btn-primary back-button mr-2"
            (click)="backButtonAction()">
            <i class="fa-solid fa-arrow-left"></i>
        </a>
        <h3>{{ title }}</h3>
    </div>
    <div>
        <div class="profile-selector">
            <div class="profile">
                <span class="name">{{ full_name }}</span>
                <div class="avatar">
                    <img [src]="avatar_public_url" alt="avatar" />
                </div>
            </div>
        </div>
    </div>
</div>
