import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import AuthService from 'src/app/services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.component.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit {
    email = '';
    password = '';

    authenticating = false;
    authenticationError = '';

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    doLogin() {
        this.authenticating = true;
        this.authenticationError = '';
        this.authService
            .authenticate(this.email, this.password)
            .then(res => {
                this.authenticating = false;
                this.router.navigate(['metricas']);
            })
            .catch(err => {
                this.authenticating = false;
                this.authenticationError = err;
            });
    }

    showPassword = false;
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
