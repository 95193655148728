import { Component, OnInit } from '@angular/core';

import AuthService from 'src/app/services/auth.service';

@Component({
    selector: 'app-lost-password',
    templateUrl: './lost-password.component.html',
    styleUrls: ['../auth.component.scss', './lost-password.component.scss'],
})
export class LostPasswordComponent implements OnInit {
    email = '';

    constructor(private authService: AuthService) {}

    ngOnInit(): void {}

    recoverSent = false;

    recovering = false;
    recoveringError = '';
    doRecover() {
        this.recovering = true;
        this.recoveringError = '';
        this.authService
            .recoverPassword(this.email)
            .then((res: any) => {
                this.recovering = false;
                this.recoverSent = true;
            })
            .catch((err: any) => {
                this.recovering = false;
                this.recoveringError = err;
            });
    }
}
